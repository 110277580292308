import React, { Component } from "react";
import { Text } from "@shopify/polaris";
import { FixedSizeList, areEqual } from "react-window";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import KanbanCard from "./KanbanCard";
import NumberBadge from "../../../components/NumberBadge";

class KanbanColumn extends Component {
	constructor(props) {
		super(props);
		this.Row = React.memo(this.renderRow.bind(this), areEqual);
		this.state = {};
	}

	onClickCard(row) {
		this.props.editRow(row);
	}

	// addRow() {
	// 	if (!this.state.newTitle) {
	// 		return;
	// 	}

	// 	this.setState({});

	// 	const row = { title: this.state.newTitle, values: [{ column_id: this.props.column.id, value: this.props.statusIndex }] };

	// 	API.post(
	// 		"/" +
	// 			"api" +
	// 			"/boards/" +
	// 			this.props.board.id +
	// 			(this.props.group && this.props.group.id ? "/groups/" + this.props.group.id : "") +
	// 			"/rows.json",
	// 		row,
	// 		{ params: {} }
	// 	)
	// 		.then((result) => {
	// 			this.props.onCreateRow(result.data.row);
	// 			Toast.success(
	// 				this.props.t("board.responses.created", "Skapade ny {{title}}", {
	// 					title: this.props.board.singular || "rad",
	// 				})
	// 			);
	// 		})
	// 		.catch((error) => {
	// 			Toast.error(error);
	// 		});
	// }

	openAddRow() {
		this.props.onCreateRow(null, this.props.group.id, [{ column_id: this.props.column.id, value: this.props.statusIndex }]);
	}

	renderRow(props) {
		const { data: items, index, style } = props;
		const row = items[index];

		if (!row) {
			return null;
		}

		return (
			<Draggable draggableId={row.id + ""} index={index} key={row.id}>
				{(provided, snapshot) => {
					const newStyle = Object.assign({}, provided.draggableProps.style, style);

					return (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<div className="board-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={newStyle}>
							<KanbanCard
								index={index}
								row={row}
								group={this.props.group}
								board={this.props.board}
								isDragging={snapshot.isDragging}
								statusIndex={this.props.statusIndex}
								onClick={this.onClickCard.bind(this, row)}
								color={(this.props.status && this.props.status.color) || "transparent"}
								view={this.props.view}
							/>
						</div>
					);
				}}
			</Draggable>
		);
	}

	getColumn(columnId) {
		return this.props.board.columns.find((c) => c.id == columnId);
	}

	formatNumber(number) {
		const addSpaces = (number) => {
			number = number.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			number = number.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			number = number.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			return number;
		};

		let result = addSpaces(parseInt(number).toString());
		const dec = parseFloat(number).toString();
		if (dec.indexOf(".") >= 0) {
			const pcs = dec.split(".", 2);
			result = result + "." + pcs[1];
		}

		return result;
	}

	getValue(columnId) {
		const valueColumn = this.getColumn(columnId);

		if (!valueColumn) return null;
		const val = this.props.rows
			.map((r) => {
				const cv = r.column_values[columnId];
				return cv && cv.value;
			})
			.filter((i) => i);

		if (valueColumn.type === "number") {
			return { val: this.formatNumber(val.reduce((acc, nr) => acc + parseFloat(nr), 0).toFixed(2)) };
		}

		if (valueColumn.type === "timetrack") {
			const seconds = val.reduce((acc, i) => acc + parseFloat(i.duration || 0), 0);
			if (seconds < 60) return { val: Math.trunc(seconds), suffix: "s" };
			const min = seconds / 60;
			if (min < 60) return { val: Math.trunc(min), suffix: "min" };
			const hours = min / 60;
			return { val: hours % 1 === 0 ? hours : hours.toFixed(2), suffix: "h" };
		}

		return null;
	}

	render() {
		const totalValue = this.getValue(this.props.view.options.sumRow_columnId);
		const headerHeight = $(".board-main-header").height() || 0;

		return (
			<Droppable
				droppableId={this.props.group.id + "-" + this.props.statusIndex}
				mode="virtual"
				renderClone={(provided, snapshot, rubric) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<div className="board-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						<KanbanCard
							row={this.props.rows[rubric.source.index]}
							group={this.props.group}
							isDragging={snapshot.isDragging}
							statusIndex={this.props.statusIndex}
							onClick={() => {}}
							board={this.props.board}
							view={this.props.view}
						/>
					</div>
				)}
			>
				{(droppableProvided, snapshot) => {
					// Add an extra item to our list to make space for a dragging item
					// Usually the DroppableProvided.placeholder does this, but that won't
					// work in a virtual list
					const itemCount = snapshot.isUsingPlaceholder ? this.props.rows.length + 1 : this.props.rows.length;

					return (
						<div className="kanban-column" style={{ maxHeight: $(".board-container").height() - (headerHeight + 40) }}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									height: "100%",
								}}
							>
								{/* <div style={{ paddingBottom: 10, borderBottom: `5px solid ${this.props.status.color || "transparent"}` }}> */}
								<div className="kanban-column__header">
									<Text variant="headingMd" as="h2">
										{/* {this.props.status.label.trim() ? this.props.status.label : "<inget värde>"} /{this.props.rows.length} */}
										<span className="oneline">
											{this.props.status && this.props.status.label && this.props.status.label.trim()
												? this.props.status.label
												: this.props.t("board.terms.empty", "<inget värde>")}
										</span>
										<NumberBadge color={this.props.status && this.props.status.color} alpha={0.2}>
											{this.props.rows.length}
										</NumberBadge>
									</Text>
									<div className="kanban-column__header__values">
										{!!this.props.view.options.sumRow_columnId && (
											<span className="kanban-column__header__sum">
												{this.props.t("board.terms.total", "Totalt")}:
												{totalValue && (
													<span className="kanban-column__header__sum__value oneline">
														{totalValue && totalValue.val}
														{totalValue && totalValue.suffix}
													</span>
												)}
											</span>
										)}
									</div>
								</div>
								<FixedSizeList
									className="hideScrollbar kanban-column-scroll"
									height={$(".board-container").height() - (headerHeight + (this.props.view.options.sumRow_columnId ? 65 : 45) + 80)}
									itemCount={itemCount}
									itemSize={140 + (this.props.view.options.info_columnId ? 50 : 0)}
									outerRef={droppableProvided.innerRef}
									itemData={this.props.rows}
									overscanCount={10}
								>
									{this.Row}
								</FixedSizeList>
								<div className="bottom-scroll-shadow visible">
									<div className="new-row-button-wrapper">
										<div
											style={{ cursor: "pointer", height: 40, lineHeight: "40px", textTransform: "capitalize" }}
											onClick={this.openAddRow.bind(this)}
										>
											+ {(this.props.board && this.props.board.singular) || "rad"}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Droppable>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(KanbanColumn);
