/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React, { useEffect, useMemo } from "react";
import { Button, ButtonGroup, InlineError, Label } from "@shopify/polaris";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Moment from "moment";
import nl2br from "react-nl2br";
import { extendMoment } from "moment-range";
import Modal from "src/js/components/modal";
import API from "../../API";
// import NumberBadge from "../../components/NumberBadge";
import Colors from "../../Colors";
import { currencyFormatter } from "../../Utilities";
import useQuery from "src/js/hooks/useQuery";
import { store } from "src/js/store";
import { UseQueryResult } from "@tanstack/react-query";
import Spinner from "src/js/components/Spinner";
import Toast from "src/js/components/Toast";

const moment = extendMoment(Moment as any);

interface LicenseModalProps extends WithTranslation {
	open: boolean;
	onClose: () => void;
	onComplete?: (license?: unknown) => void;
	license?: any;
	licenseProvider?: any;
}

const LicenseModal = ({ open, onClose, onComplete, license, licenseProvider: propsLicenseProvider, t }: LicenseModalProps) => {
	const [form, setForm] = React.useState(license || { quantity: propsLicenseProvider?.quantity || 0 });
	const [licenseProvider, setLicenseProvider] = React.useState(propsLicenseProvider);
	const [saving, setSaving] = React.useState(false);

	useEffect(() => {
		if (!open) {
			setLicenseProvider(null);
			setForm({ quantity: 0 });
		}
	}, [open]);

	useEffect(() => {
		if (propsLicenseProvider) {
			setLicenseProvider(propsLicenseProvider);
		}
	}, [propsLicenseProvider]);

	const fetchFortnoxContract = async () => {
		try {
			const result = await API.get("/api/account/contract.json");

			return result?.data?.contract;
		} catch (error) {
			console.error("error:", error);
			Toast.error(error);
		}
	};
	// ;
	const fortnoxContractKey = ["fortnox_contract", store.getState().account?.id];
	const { data: contract, isFetching: loadingContract }: UseQueryResult<FortnoxContractType | null> = useQuery({
		queryKey: fortnoxContractKey,
		queryFn: fetchFortnoxContract,
	});

	const fetchFortnoxArticle = async () => {
		try {
			const result = await API.get(`/api/license_providers/${licenseProvider?.id}/article.json`);

			return result?.data?.article;
		} catch (error) {
			console.error("error:", error);
			Toast.error(error);
		}
	};
	// ;
	const artickeKey = ["license_provider", licenseProvider?.id];
	const { data: article, isFetching: loadingArticle }: UseQueryResult<any> = useQuery({
		queryKey: artickeKey,
		queryFn: fetchFortnoxArticle,
		enabled: !!licenseProvider?.id,
	});

	const handleFetchLicenseProviders = async () => {
		try {
			const result = await API.get("/api/license_providers.json");

			return result.data.license_providers;
		} catch (error) {
			console.error("error:", error);
			Toast.error(error);
		}
	};
	const licenseProvidersKey = ["license_providers"];
	const { data: licenseProvidersData, isFetching: loadingLicenseProviders } = useQuery({
		queryKey: licenseProvidersKey,
		queryFn: handleFetchLicenseProviders,
	});

	const licenseProviders = useMemo(() => {
		const articleNumbers = (contract?.InvoiceRows || [])?.reduce((acc, row: any) => {
			if (row.ArticleNumber) acc.push(row.ArticleNumber as any);

			return acc;
		}, [] as any[]);

		return licenseProvidersData?.map((licenseProvider: any) => {
			return {
				...licenseProvider,
				disabled:
					licenseProvider.parent_license_provider?.fortnox_sku && !articleNumbers.includes(licenseProvider.parent_license_provider?.fortnox_sku),
			};
		});
	}, [contract, licenseProvidersData]);

	const incrementQuantity = () => {
		setForm({ ...form, quantity: Math.max(form.quantity + (licenseProvider?.quantity || 0), licenseProvider?.quantity || 0) });
	};

	const decrementQuantity = () => {
		setForm({ ...form, quantity: Math.max(form.quantity - (licenseProvider?.quantity || 0), 0) });
	};

	const handleSave = async () => {
		try {
			form.license_provider = licenseProvider;
			setSaving(true);

			const result = await API.post("/api/licenses.json", form).then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					console.error("error:", result.data.error);
					return;
				}

				Toast.success(t("licenses.reponses.created", "{{title}} licens beställd", { title: form?.license_provider?.title }));
				onComplete?.(result.data.contract);
			});

			return result;
		} catch (error) {
			Toast.error(error);
			console.error("error:", error);
		} finally {
			setSaving(false);
		}
	};

	const selectLicenseProvider = (licenseProvider: any) => {
		if (!licenseProvider?.role) return;

		setLicenseProvider(licenseProvider);
		setForm({ ...form, quantity: licenseProvider?.quantity || 0 });
	};

	const getMonthsCount = (start: string | moment.Moment, end: string | moment.Moment) => {
		//excluding half months, only count full months (2024-01-02 - 2024-03-25 = 1 full month)
		const months = moment(end).startOf("month").diff(moment(start).add(1, "month").startOf("month"), "months");
		return Math.max(months, 0);
	};

	const invoiceData = useMemo(() => {
		if (!licenseProvider) return null;
		if (!contract) return null;
		// if (contract?.PeriodEnd && moment(contract?.PeriodEnd).isBefore(moment())) {
		// 	return null;
		// }

		// const contractRowWithArticle = contract?.InvoiceRows?.find((row) => row.ArticleNumber === article?.ArticleNumber) as FortnoxInvoiceRowType;

		// const rowPackagePrice =
		// 	(contractRowWithArticle.Price * (1 - (contractRowWithArticle.VAT || 0) / 100)) /
		// 	parseInt(contractRowWithArticle?.DeliveredQuantity || "1") /
		// 	licenseProvider.quantity;

		const firstPeriodMonthsCount = contract && getMonthsCount(moment().format("YYYY-MM-DD"), contract?.PeriodStart);
		const packagePrice = article?.SalesPrice;
		const perUserCost = packagePrice / licenseProvider.quantity;

		const licensesQuantity = form.quantity / licenseProvider.quantity;
		const licenseCost = packagePrice * licensesQuantity;

		const firstInvoiceCost = licenseCost * licensesQuantity * firstPeriodMonthsCount;

		const monthlyCost = licenseCost * licensesQuantity;
		const periodCost = monthlyCost * contract.InvoiceInterval;

		return {
			firstPeriodMonthsCount,
			licenseCost,
			firstInvoiceCost,
			periodCost,
			monthlyCost,
			monthsInPeriod: contract.InvoiceInterval,
			packagePrice,
			perUserCost,
		};
	}, [licenseProvider, contract, form, article]);

	const count = form.quantity;
	const { monthlyCost, monthsInPeriod, perUserCost } = (invoiceData || {}) as any;

	const status = (() => {
		if (!form?.id) return null;

		const date = `${moment(form.expires_at).format("YYYY-MM-DD")} (${moment(form.expires_at).fromNow()})`;

		if (moment(form.expires_at).isBefore(moment())) {
			return (
				<p>
					<Trans
						i18nKey="licenses.fields.expired_date.label"
						defaults="Denna licens <bb>{{status}}</bb> den <b>{{date}}</b>"
						components={{
							bb: <b style={{ color: Colors.red }} />,
							b: <b />,
						}}
						values={{
							status: t("licenses.status.expired", "Upphörde"),
							date,
						}}
					/>
				</p>
			);
		}

		if (form?.canceled) {
			return (
				<p>
					<Trans
						i18nKey="licenses.fields.canceled_date.label"
						defaults="Denna licens är <by>{{status}}</by> och kommer upphöra den <b>{{date}}</b>"
						components={{
							by: <b style={{ color: Colors.yellow }} />,
							b: <b />,
						}}
						values={{
							status: t("licenses.status.canceled", "Avbryten"),
							date,
						}}
					/>
				</p>
			);
		}

		return (
			<p>
				<Trans
					i18nKey="licenses.fields.renew_date.label"
					defaults="Denna licens kommer <bg>{{status}}</bg> den <b>{{date}}</b>"
					components={{
						bg: <b style={{ color: Colors.green }} />,
						b: <b />,
					}}
					values={{
						status: t("licenses.status.renews", "Förnyas"),
						date,
					}}
				/>
			</p>
		);
	})();

	const content = (() => {
		if (licenseProvider) {
			if (loadingContract) return <Spinner />;
			if (loadingArticle) return <Spinner />;
			if (!invoiceData) return null;

			return (
				<div>
					{status}
					<br />
					<DescList
						items={[
							{
								label: t("licenses.fields.invoice_cost.label1", "Kostnad för första perioden"),
								value: currencyFormatter({ value: invoiceData.firstInvoiceCost }),
								suffix: ` (${invoiceData.firstPeriodMonthsCount}) ${t("licenses.fields.months.label", "månader")}`,
							},
						]}
					/>
					<DescList
						items={[
							{
								label: t("licenses.fields.period_cost.label1", "Periodskostnad"),
								value: currencyFormatter({ value: invoiceData.periodCost }),
								prefix: ` (${invoiceData.monthsInPeriod})`,
							},
							{ label: t("licenses.fields.monthly_cost.label1", "Månadskostnad"), value: currencyFormatter({ value: monthlyCost }) },
							{ label: t("licenses.fields.month_count.label", "Antal månader"), value: monthsInPeriod },
							{ label: t("licenses.fields.user_count.label", "Antal användare"), value: count },
							{
								label: t("licenses.fields.unit_month_cost.label", "Kostnad per användare och månad"),
								value: currencyFormatter({ value: Math.round(perUserCost) }),
							},
						]}
					/>
				</div>
			);
		}

		if (loadingLicenseProviders) {
			return <Spinner />;
		}

		if (!licenseProvider) {
			return (
				<Table>
					<thead>
						<tr>
							<th>{t("licenses.column.title2", "Titel och beskrivning")}</th>
							<th>{t("licenses.column.unit_price1", "Pris per användare")}</th>
							<th>{t("licenses.column.count", "Antal per licens")}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{licenseProviders?.map((licenseProvider: any) => {
							return (
								<Tr disabled={!licenseProvider.role} key={licenseProvider.id} onClick={() => selectLicenseProvider(licenseProvider)}>
									<td>
										<b>{licenseProvider.title}</b>
										<p
											style={{
												color: "var(--textColor2)",
												marginTop: "0.15rem",
											}}
										>
											{licenseProvider.description}
										</p>
									</td>

									{!licenseProvider?.role ? (
										<td colSpan={3}>
											<InlineError message={t("licenses.fields.no_role", "Kontakta din säljare") as string} fieldID="role" />
										</td>
									) : (
										<>
											<td>
												{currencyFormatter({
													value: licenseProvider.unit_price,
												})}
											</td>
											<td>{licenseProvider.quantity}</td>
											<td>
												<div className="Polaris-Button Polaris-Button--primary">
													<span className="Polaris-Button__Content">{t("common.actions.choose", "Välj")}</span>
												</div>
												{/* <Button onClick={this.selectLicenseProviders.bind(this, licenseProvider)}></Button> */}
											</td>
										</>
									)}
								</Tr>
							);
						})}
					</tbody>
				</Table>
			);
		}

		return null;
	})();

	const primaryAction =
		(!form?.id &&
			licenseProvider && {
				content: t("licenses.actions.order", "Beställ"),
				onAction: handleSave,
				loading: saving,
				disabled: !invoiceData || !form?.quantity,
			}) ||
		undefined;
	const secondaryActions = [
		// {
		// 	content: t("licenses.actions.cancel", "Avbryt förnyelse"),
		// 	onAction: this.handleOpenCancelModal.bind(this),
		// 	destructive: true,
		// 	disabled: form.canceled,
		// 	enabled: form?.id && !moment(form.expires_at).isBefore(moment()) && !form?.canceled,
		// },
		{
			content: t("common.actions.close", "Stäng"),
			onAction: onClose,
		},
	].filter((i) => !("enabled" in i) || i.enabled);

	//Kontakta din säljare.

	return (
		<>
			<Modal
				large
				title={
					<div style={{ display: "flex", gap: "0.6250rem" }}>
						<span>{licenseProvider?.title || t("licenses.new.title1", "Välj licenstyp")}</span>
						{/* {form?.id && (
								<NumberBadge fontSize="0.6250rem" color={form.canceled ? Colors.red : Colors.green}>
									{form.canceled ? t("licenses.status.canceled", "Upphör") : t("licenses.status.renews", "Förnyas")}
								</NumberBadge>
							)} */}
					</div>
				}
				open={open}
				onClose={onClose}
				secondaryActions={secondaryActions}
				primaryAction={primaryAction}
			>
				<Modal.Section>
					{!form?.id && !licenseProvider?.id && (
						<Description>{t("licenses.new.description", "Börja med att välja vilken typ av licens")}</Description>
					)}
					{!form?.id && licenseProvider?.id && (
						<Description>{nl2br(t(`licenses.${licenseProvider.title}.description`, licenseProvider?.description) as unknown as string)}</Description>
					)}
					<Wrapper>
						{!form?.id && licenseProvider && (
							<>
								<Label id="count">{t("licenses.fields.user_count.label", "Antal användare")}</Label>
								<ButtonGroup segmented fullWidth={false}>
									<Button disabled={!licenseProvider?.role} onClick={decrementQuantity}>
										-{licenseProvider?.quantity}
									</Button>

									<Count>{form?.quantity}</Count>

									<Button disabled={!licenseProvider?.role} onClick={incrementQuantity}>
										+ {licenseProvider?.quantity}
									</Button>
								</ButtonGroup>
							</>
						)}
						{content}
					</Wrapper>
				</Modal.Section>
			</Modal>

			{/* <LicenseCancelModal
					open={this.state.cancelModalIsOpen}
					license={form}
					onComplete={(license) => {
						this.setState({ form: license, cancelModalIsOpen: false });
						this.props.onComplete?.();
					}}
					onClose={this.handleCloseCancelModal.bind(this)}
				/> */}
		</>
	);
};

export default withTranslation(["licenses", "common"], { withRef: true })(LicenseModal);

const Count = styled.div`
	font-weight: var(--p-button-font-weight);
	background: var(--main3);
	padding: 0.4375rem 1rem;
	height: 36px;
`;

const Wrapper = styled.div`
	.Polaris-ButtonGroup.Polaris-ButtonGroup--segmented {
		margin-block: 0.625rem;
		width: max-content;
	}
`;

const Table = styled.table`
	width: 100%;
	border-collapse: separate;
	border-spacing: 0em 0.625rem;

	th {
		/* font-weight: 400; */
		color: var(--textColor2);
	}

	th,
	td {
		padding: 0.2188rem 0.4375rem;
		text-align: start;

		&:first-of-type {
			text-align: start;
			padding-left: 1.25rem;
		}

		&:last-of-type {
			text-align: end;
		}
	}
`;
const Tr = styled.tr<{ disabled?: boolean }>`
	padding: 0.2188rem 0.625rem;
	margin-top: 0.625rem;
	border-radius: 6px;
	cursor: ${(props) => (props.disabled ? "defualt" : "pointer")};
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

	${(props) =>
		props.disabled &&
		css`
			td:not(:nth-of-type(2)) {
				opacity: 0.5;
			}
		`}

	td {
		transition: background-color 250ms;
		background-color: ${Colors.main2};
	}

	td:first-of-type {
		border-top-left-radius: 3.125rem;
		border-bottom-left-radius: 3.125rem;
	}

	td:last-of-type {
		border-top-right-radius: 3.125rem;
		border-bottom-right-radius: 3.125rem;
		padding-right: 0;
	}
`;

const Description = styled.p`
	margin-top: -1rem;
	margin-bottom: 1rem;
	color: var(--textColor2);
`;

const DescList = ({ items }) => {
	return (
		<StyledDescList>
			{items.map((item, index) => (
				<li key={index}>
					{item.label}
					{item.prefix}:{" "}
					<b>
						{item.value}
						{item.suffix}
					</b>
				</li>
			))}
		</StyledDescList>
	);
};

const StyledDescList = styled.ul`
	list-style-type: none;
	padding: 0;
`;
