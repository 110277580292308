/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import { Icon, Layout, Spinner, Tooltip } from "@shopify/polaris";
import React, { useState, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { RefreshMajor } from "@shopify/polaris-icons";
import ReactJson from "react-json-view";
import Truncate from "src/js/components/Truncate";
import Colors from "../../Colors";

type SectionProps = {
	title: string | ReactElement;
	enableRefresh?: boolean;
	style?: any;
	props?: any;
	children: ReactNode;
	id?: string;
	onChange?: (props: any) => void;
	extra?: ReactNode;
};

const Section = ({ title: propsTitle, extra, id, enableRefresh = false, style, props, children, onChange }: SectionProps) => {
	// eslint-disable-next-line no-unused-vars
	const [rnd, setRnd] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);

	const title = propsTitle || JSON.stringify(props, null, 2).slice(0, 200);

	return (
		<Wrapper id={id}>
			<Layout.AnnotatedSection
				title={
					<>
						{enableRefresh && (
							<Tooltip content="Force rerender">
								<RefreshButton
									onClick={() => {
										setRnd(Math.random());
										setLoading(true);
										setTimeout(() => {
											setLoading(false);
										}, 100);
									}}
								>
									{loading ? <Spinner size="small" /> : <Icon source={RefreshMajor} />}
								</RefreshButton>
							</Tooltip>
						)}
						<Truncate>{title}</Truncate>
						{extra}
					</>
				}
				description={
					<ReactJson
						name={false}
						// displayDataTypes={false}
						enableClipboard={false}
						indentWidth={2}
						src={props}
						theme="ocean"
						style={{ padding: 6, borderRadius: 6 }}
						onEdit={
							onChange
								? (v) => {
										onChange?.(v.updated_src);
								  }
								: false
						}
						collapseStringsAfterLength={20}
						shouldCollapse={(field) => {
							return JSON.stringify(field).length > 100;
						}}
					/>
				}
			>
				<div key={rnd} style={style}>
					{children}
				</div>
			</Layout.AnnotatedSection>
		</Wrapper>
	);
};
export default Section;

const Wrapper = styled.div`
	scroll-margin-top: 75px;
`;

const RefreshButton = styled.button`
	background: ${Colors.main2};
	border: none;
	padding: 0.2188rem 0.4375rem;
	border-radius: 5px;
	cursor: pointer;
	transition: background 250ms;
	margin-right: 0.625rem;

	.Polaris-Icon,
	.Polaris-Spinner--sizeSmall svg {
		width: 0.9375rem;
		height: 0.9375rem;
	}

	&:hover {
		background: ${Colors.main3};
	}
`;
