import { FormLayout } from "@shopify/polaris";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import getFiscalYearOrDefaultStartDate from "src/js/Utilities/getFiscalYearOrDefaultStartDate";
import Modal from "src/js/components/modal";

class AccountModal extends Component {
	constructor(props) {
		super(props);
		this.state = { form: this.props.form || {} };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open !== this.props.open && props.open) {
			this.setState({ form: props.form || {} });
		}
	}

	onChange(field, value) {
		this.setState((c) => ({ ...c, form: { ...c.form, [field]: value } }));
	}

	onSave() {
		this.props.onSave(this.state.form);
	}

	render() {
		const fiscalYearOrDefaultStartDate = getFiscalYearOrDefaultStartDate(this.state.form.fiscal_year_start_date);

		return (
			<Modal
				title={this.props.t("account.titles.company_info", "Företagsuppgifter")}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					onAction: this.onSave.bind(this),
					loading: this.props.loading,
				}}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							label={this.props.t("account.fields.company_name.label", "Företagsnamn")}
							value={this.state.form.company}
							onChange={this.onChange.bind(this, "company")}
						/>
						<TextField
							label={this.props.t("account.fields.phone.label", "Telefonnummer")}
							value={this.state.form.phone}
							onChange={this.onChange.bind(this, "phone")}
						/>
						<TextField
							label={this.props.t("account.fields.email.label", "E-post")}
							value={this.state.form.email}
							onChange={this.onChange.bind(this, "email")}
						/>
						<TextField
							label={this.props.t("account.fields.orgnr.label", "organisationsnummer")}
							value={this.state.form.orgnr}
							onChange={this.onChange.bind(this, "orgnr")}
						/>
						<TextField
							label={this.props.t("account.fields.street.label", "Adress")}
							value={this.state.form.street}
							onChange={this.onChange.bind(this, "street")}
						/>
						<TextField
							label={this.props.t("account.fields.zip.label", "Postnummer")}
							value={this.state.form.zip}
							onChange={this.onChange.bind(this, "zip")}
						/>
						<TextField
							label={this.props.t("account.fields.city.label", "Stad")}
							value={this.state.form.city}
							onChange={this.onChange.bind(this, "city")}
						/>
						<TextField
							label={this.props.t("account.fields.fiscal_year_start_date.label", "Räkenskapsår")}
							value={
								fiscalYearOrDefaultStartDate && moment(fiscalYearOrDefaultStartDate).isValid()
									? moment(fiscalYearOrDefaultStartDate).format("YYYY-MM-DD")
									: fiscalYearOrDefaultStartDate
							}
							onChange={this.onChange.bind(this, "fiscal_year_start_date")}
							type="date"
						/>
						<TextField
							multiline={4}
							label={this.props.t("account.fields.description.label", "Beskrivning")}
							value={this.state.form.description}
							onChange={this.onChange.bind(this, "description")}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["common", "account"], { withRef: true })(AccountModal);
