import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const CustomRedirect = ({ to, replace }) => {
	const history = useHistory();

	useEffect(() => {
		if (replace) {
			history.replace(to);
		} else {
			history.push(to);
		}
	}, [history, to, replace]);

	return null;
};
export default CustomRedirect;
