import styled, { css } from "styled-components";

const TargetHeader = styled.div.attrs({ className: "TargetHeader" })`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
const TargetTitle = styled.p.attrs({ className: "TargetTitle" })`
	font-weight: 600;
`;
const TargetValues = styled.p.attrs({ className: "TargetValues" })`
	text-align: center;
	margin-block: 5px;
	width: 100%;

	/* display: flex;
	align-items: center;
  justify-content: center; */
`;
const TargetHeaderRight = styled.div.attrs({ className: "TargetHeaderRight" })`
	display: flex;
	gap: 0.5rem;

	.Polaris-Button {
		.Polaris-Icon {
			transition: opacity 0.3s;
			opacity: 0.5;
		}

		&:hover .Polaris-Icon {
			opacity: 1;
		}
	}
`;
const TargetDates = styled.div.attrs({ className: "TargetDates" })`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 100%;

	transition: opacity 0.3s;
	opacity: 0.75;

	font-size: 0.9em;

	&:hover {
		opacity: 1;
	}
`;

const TargetComparison = styled.div.attrs({ className: "TargetComparison" })<{ color: string }>`
	.Polaris-Icon {
		margin: 0 2px;
		height: 17px;

		svg {
			fill: ${({ color }) => color || "#fcff65"};
		}
	}
`;

interface TargetProgressBox {
	Header: typeof TargetHeader;
	Title: typeof TargetTitle;
	Values: typeof TargetValues;
	Dates: typeof TargetDates;
	Comparison: typeof TargetComparison;
	HeaderRight: typeof TargetHeaderRight;
}

const TargetProgressBox: TargetProgressBox | any = styled.div.attrs({ className: "TargetProgressBox" })<{ isLoading?: boolean; haveStats?: boolean }>`
	background-color: var(--main2);
	transition: background-color 0.3s;
	min-width: 100px;
	min-height: 225px;
	border-radius: 6px;
	padding: 1rem;
	color: var(--textColor);
	box-shadow: var(--boxShadow);

	display: flex;
	flex-direction: column;
	align-items: center;

	text {
		fill: var(--textColor);
	}

	/* cursor: pointer; */
	/* &:hover {
		background-color: var(--main3-50);
	} */

	${({ isLoading }) =>
		isLoading &&
		css`
			animation: var(--pulseAnimation);
		`};

	${({ isLoading, haveStats }) =>
		isLoading &&
		!haveStats &&
		css`
			${TargetValues} {
				min-height: 20px;
				animation: var(--pulseAnimation);
				background-color: var(--main3);
				border-radius: 6px;
				width: 50%;

				* {
					display: none;
				}
			}

			${TargetDates} {
				min-height: 20px;
				animation: var(--pulseAnimation);
				background-color: var(--main3);
				border-radius: 6px;
				width: 75%;
				* {
					display: none;
				}
			}
		`};
`;
TargetProgressBox.Header = TargetHeader;
TargetProgressBox.Title = TargetTitle;
TargetProgressBox.Values = TargetValues;
TargetProgressBox.Dates = TargetDates;
TargetProgressBox.Comparison = TargetComparison;
TargetProgressBox.HeaderRight = TargetHeaderRight;

export { TargetProgressBox };
