export default {
	id: 0,
	type: "text",
	description: "",
	default_value: "",
	can_edit_when_placing_order: false,
	articles: [],
	categories: [],
	created_at: "",
	updated_at: "",
	disabled: false,
};
