import React, { useState, useEffect, useRef, useCallback } from "react";

interface AnimatedWritingTextProps {
	text: (string | React.ReactNode)[];
	speed?: number;
	charsPerTick?: number;
	animate?: boolean;
	pause?: boolean;
}

/**
 * Utility function to calculate speed and characters per tick based on message length.
 * Provides a balance between speed and characters displayed per tick.
 *
 * @param {number} length - The length of the message.
 * @returns {{ speed: number, charsPerTick: number }} The calculated speed and characters per tick.
 */
const calculateSpeedAndCharsPerTick = (length: number): { speed: number; charsPerTick: number } => {
	const maxSpeed = 75; // Slowest speed (ms per tick)
	const minSpeed = 10; // Fastest speed (ms per tick)
	const maxCharsPerTick = 5; // Maximum characters per tick
	const minCharsPerTick = 1; // Minimum characters per tick
	const levels = 40; // Number of levels to scale

	// Calculate dynamic speed
	const speedLevel = Math.min(Math.floor(length / 10), levels - 1);
	const speed = maxSpeed - (speedLevel * (maxSpeed - minSpeed)) / (levels - 1);

	// Calculate dynamic characters per tick
	const charsPerTickLevel = Math.min(Math.floor(length / 40), levels - 1);
	const charsPerTick = minCharsPerTick + (charsPerTickLevel * (maxCharsPerTick - minCharsPerTick)) / (levels - 1);

	return { speed: Math.round(speed), charsPerTick: Math.ceil(charsPerTick) };
};

/**
 * AnimatedWritingText component animates text being displayed piece by piece,
 * supporting both strings and React elements, with dynamic speed and pause/resume functionality.
 *
 * @param {AnimatedWritingTextProps} props - The props for the component.
 * @returns {JSX.Element} The animated text.
 */
const AnimatedWritingText: React.FC<AnimatedWritingTextProps> = ({ text, speed, charsPerTick = 1, animate = true, pause = false }) => {
	const [displayedContent, setDisplayedContent] = useState<React.ReactNode[]>([]);
	const indexRef = useRef<number>(0);
	const subIndexRef = useRef<number>(0);
	const animationFrameIdRef = useRef<number | null>(null);

	const { speed: resolvedSpeed, charsPerTick: resolvedCharsPerTick } =
		speed && charsPerTick ? { speed, charsPerTick } : calculateSpeedAndCharsPerTick(text.join("").length);

	const startAnimation = useCallback(() => {
		let startTime: number | null = null;

		const animateText = (currentTime: number) => {
			if (pause || !animate) {
				return; // Stop animation when paused or disabled
			}

			let elapsedTime: number;

			if (startTime === null) {
				startTime = currentTime;
				elapsedTime = 0;
			} else {
				elapsedTime = currentTime - startTime;
			}

			if (elapsedTime >= resolvedSpeed) {
				startTime = currentTime;

				if (indexRef.current < text.length) {
					const currentItem = text[indexRef.current];

					if (typeof currentItem === "string") {
						// Animate strings letter by letter
						const remainingText = currentItem.slice(subIndexRef.current);
						const nextChunk = remainingText.slice(0, resolvedCharsPerTick);

						setDisplayedContent((prev) => {
							const updated = [...prev];
							// If the last item is a string, append to it
							if (typeof updated[updated.length - 1] === "string") {
								updated[updated.length - 1] = `${updated[updated.length - 1]}${nextChunk}`;
							} else {
								// Otherwise, add a new string entry
								updated.push(nextChunk);
							}
							return updated;
						});

						subIndexRef.current += resolvedCharsPerTick;

						if (subIndexRef.current >= currentItem.length) {
							indexRef.current += 1;
							subIndexRef.current = 0;
						}
					} else {
						// Directly render React elements
						setDisplayedContent((prev) => [...prev, currentItem]);
						indexRef.current += 1;
					}
				} else {
					// Animation complete, stop
					return;
				}
			}

			animationFrameIdRef.current = requestAnimationFrame(animateText);
		};

		animationFrameIdRef.current = requestAnimationFrame(animateText);
	}, [text, resolvedSpeed, resolvedCharsPerTick, animate, pause]);

	useEffect(() => {
		if (!animate) {
			// If animation is disabled, render the entire content immediately
			setDisplayedContent(text);
			return;
		}

		if (!pause) {
			// Start or resume animation
			startAnimation();
		}

		// Cleanup function
		return () => {
			if (animationFrameIdRef.current !== null) {
				cancelAnimationFrame(animationFrameIdRef.current);
				animationFrameIdRef.current = null;
			}
		};
	}, [text, resolvedSpeed, charsPerTick, animate, pause, startAnimation]);

	return <>{displayedContent}</>;
};

export default AnimatedWritingText;
