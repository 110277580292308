import { Icon, ResourceList } from "@shopify/polaris";
import { PlusMinor, SearchMajor } from "@shopify/polaris-icons";
import React, { useMemo, useState } from "react";
import TextField from "src/js/components/TextField";
import useContact from "src/js/hooks/useContact";
import styled from "styled-components";
import Button from "src/js/components/Button";
import ContactModal, { ContactFormType } from "../ContactModal";
import { useParams } from "react-router-dom";
import CustomCard from "src/js/components/CustomCard";

const AxiconExecutiveList = () => {
	const { id }: any = useParams();
	const [search, setSearch] = useState("");
	const { contact, axiconData, t, updateContact } = useContact(id);

	const [selectedExcutive, setSelectedExcutive] = useState<ContactType | null>(null);

	const handleSearchChange = (value) => {
		setSearch(value);
	};

	const executives = useMemo(() => {
		return (
			axiconData?.executives
				?.filter((executive) => {
					if (!search) return true;
					return Object.values(executive).some((value) => {
						if (typeof value === "string") {
							return value?.toLowerCase().includes(search.toLowerCase());
						} else if (Array.isArray(value)) {
							return value.some((v) => v?.toLowerCase().includes(search.toLowerCase()));
						}

						return false;
					});
				})
				?.sort((a, b) => a.name.localeCompare(b.name))
				?.map((executive, index) => ({ ...executive, title: executive.formatted_title, id: undefined })) || []
		);
	}, [axiconData, search]);

	const renderExecutive = (executive, id) => {
		return (
			<ResourceList.Item
				id={id}
				onClick={() => {
					setSelectedExcutive({ ...executive, id: null });
				}}
			>
				<Item>
					<Title>
						{executive.name}
						<Button
							onClick={() => {
								setSelectedExcutive({ ...executive, id: null });
							}}
							icon={PlusMinor}
							plain
						/>
					</Title>
					<p style={{ color: "var(--textColor2)" }}>{executive.formatted_title}</p>
				</Item>
			</ResourceList.Item>
		);
	};

	if (!contact?.is_company) return null;

	return (
		<>
			<CustomCard title={t("contact.axicon.fields.executives.label", "Styrelse")} sectioned>
				<TextField value={search} onChange={handleSearchChange} prefix={<Icon source={SearchMajor} />} placeholder="Sök.." clearButton />
				<ResourceListWrapper>
					<ResourceList
						resourceName={{
							singular: t("contact.person.singular", "kontaktperson"),
							plural: t("contact.person.plural", "Kontaktpersoner"),
						}}
						items={executives}
						renderItem={renderExecutive}
					/>
				</ResourceListWrapper>

				<ContactModal
					company={contact}
					open={!!selectedExcutive}
					contact={selectedExcutive as ContactType}
					onClose={() => {
						setSelectedExcutive(null);
					}}
					primaryAction={{
						content: t("contacts.axicon.actions.import.label", "Importera") as string,
						onAction: async (form) => {
							const exec = form as ContactFormType;
							exec.parent_id = contact.id;

							await updateContact(exec);
							setSelectedExcutive(null);
							const customEvent = new CustomEvent("indexTable_refresh_people");
							document.dispatchEvent(customEvent);
						},
					}}
				/>
			</CustomCard>
		</>
	);
};
export default AxiconExecutiveList;

const ResourceListWrapper = styled.div`
	margin-top: var(--p-space-4);
`;
const Item = styled.div`
	padding-block: var(--p-space-2);
`;
const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
