import React, { useMemo, useState } from "react";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { Autocomplete, FormLayout, Icon } from "@shopify/polaris";
import { SearchMajor } from "@shopify/polaris-icons";
import Badge from "../Badge";
import { useQueryClient } from "@tanstack/react-query";

type ResourceOptionsProps = {
	url: string;
	resourceHandle: string;
	params?: any;
	selected: string[];
	onChange: (values: string[]) => void;
	prefix?: React.ReactNode | string;
	label?: string;
	valueKey?: string;
	labelKey?: string;
	placeholder?: string;
};

const ResourceOptions = ({
	url,
	resourceHandle,
	params: propsParams = {},
	selected,
	onChange,
	prefix,
	label,
	valueKey = "id",
	labelKey = "title",
	placeholder = "Sök..",
}: ResourceOptionsProps) => {
	const [filters, setFilters] = useState<any>({});

	const params = useMemo(() => {
		return {
			...propsParams,
			...filters,
		};
	}, [propsParams, filters]);

	const queryKey = [url, Object.keys(params).length ? params : undefined].filter(Boolean);

	const queryClient = useQueryClient();
	const extraData: any = queryClient.getQueryData(queryKey);

	const { data, isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				const res = await API.get(url, { params });

				return res.data[resourceHandle];

				return;
			} catch (error) {
				console.error("error:", error);
				throw error;
			}
		},
		initialData: [],
		fetchOnMount: false,
		staleTime: 1000 * 60 * 60,
	});

	const handleChanges = (value) => {
		setFilters({ ...filters, q: value });
	};

	const handleSelect = (value) => {
		onChange(value);
	};

	const getRemoveHandler = (value) => () => {
		const newSelected = selected.filter((v) => v !== value);
		onChange([...newSelected]);
	};

	const options = useMemo(() => {
		return (
			data?.map((item) => {
				return {
					...item,
					value: item.value || item?.[valueKey],
					label: item.label || item?.[labelKey],
				};
			}) || []
		);
	}, [data, valueKey, labelKey]);

	const activator = (
		<Autocomplete.TextField
			label={label}
			prefix={
				prefix !== undefined ? (
					React.isValidElement(prefix) || prefix === null ? (
						prefix
					) : (
						<Icon source={prefix as any} />
					)
				) : (
					<Icon source={SearchMajor} />
				)
			}
			// suffix={this.props.suffix}
			autoComplete="off"
			placeholder={placeholder}
			// onFocus={this.focus.bind(this)}
			// onBlur={this.blur.bind(this)}
			// error={this.props.error}
			type="text"
			value={filters.q || ""}
			onChange={handleChanges}
			// clearButton={!!this.props.clearOption && this.state.value}
			// onClearButtonClick={this.props.clearOption ? this.clearValue.bind(this) : undefined}
			// disabled={this.props.disabled}
			// readOnly={this.props.readOnly}
		/>
	);

	const getLabel = (value) => {
		// Function to normalize the value by removing trailing zeros
		const normalize = (str) => {
			return String(str || "").replace(/0+$/, ""); // Remove trailing zeros for integers
		};

		// Normalize the input value for comparison
		const normalizedValue = normalize(value);

		return extraData?.find((d) => normalize(String(d[valueKey])) === normalizedValue)?.[labelKey] || value;
	};

	return (
		<>
			<FormLayout>
				<Autocomplete
					// actionBefore={this.props.actionBefore}
					options={options}
					selected={selected as string[]}
					onSelect={handleSelect}
					allowMultiple
					loading={isFetching}
					// disabled={this.props.disabled}
					// emptyState={emptyState}
					textField={activator}
				/>
				<div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
					{selected?.map((value) => {
						return (
							<Badge key={value} status="info" onRemove={getRemoveHandler(value)}>
								{getLabel(value)}
							</Badge>
						);
					})}
				</div>
			</FormLayout>
		</>
	);
};
export default ResourceOptions;
