import { Button, Checkbox, FormLayout, Labelled, Layout, LegacyCard, LegacyStack, Tooltip } from "@shopify/polaris";
import TextField from "src/js/components/TextField";
import { useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ArticleContext from "./context";
import { InfoWrapper } from "../CustomFields/styles";
import { ResetMinor } from "@shopify/polaris-icons";

type ArticleInfoProps = {
	history: any;
} & WithTranslation;

const ArticleOrderSettings = ({ history, t }: ArticleInfoProps) => {
	const { form, isFetching, setForm, isSaving } = useContext(ArticleContext);

	const getChangeHandler = (field: ArticleTypeMutableKeys) => (value: string | boolean | ArticlePricelistType[] | any) => {
		setForm((c: Partial<ArticleType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	const disabled = isSaving || isFetching;

	return (
		<InfoWrapper>
			<LegacyCard sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled
								id="fieldsWhenPlacingOrder"
								label={t("articles.fields.whenPlacingOrder.label", "Vilka fält ska vara låsta vid orderläggning?")}
							>
								<LegacyStack vertical spacing="extraTight">
									<Checkbox
										checked={!!form?.disable_quantity_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.quantity", "Antal")}
										onChange={(v) => getChangeHandler("disable_quantity_when_placing_order")(v)}
									/>
									<Checkbox
										checked={!!form?.disable_unit_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.unit", "Enhet")}
										onChange={(v) => getChangeHandler("disable_unit_when_placing_order")(v)}
									/>
									<Checkbox
										checked={!!form?.disable_price_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.price", "Pris")}
										onChange={(v) => getChangeHandler("disable_price_when_placing_order")(v)}
									/>
									<Checkbox
										checked={!!form?.disable_tb1_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.tb1", "TB1")}
										onChange={(v) => getChangeHandler("disable_tb1_when_placing_order")(v)}
									/>
									<Checkbox
										checked={!!form?.disable_tb2_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.tb2", "TB2")}
										onChange={(v) => getChangeHandler("disable_tb2_when_placing_order")(v)}
									/>
									<Checkbox
										checked={!!form?.disable_discount_when_placing_order}
										label={t("articles.fields.whenPlacingOrder.discount", "Rabatt")}
										onChange={(v) => getChangeHandler("disable_discount_when_placing_order")(v)}
									/>
								</LegacyStack>
							</Labelled>
						</FormLayout>
					</Layout.Section>
					<Layout.Section oneHalf>
						<FormLayout>
							<TextField
								disabled={disabled}
								label={t("articles.fields.standardValueQuantity.label", "Standardvärde för antal")}
								value={form?.quantity_default_value}
								onChange={getChangeHandler("quantity_default_value")}
							/>
							<TextField
								disabled={disabled}
								label={t("articles.fields.standardDiscountValue.label", "Standardvärde för rabatt")}
								value={form?.discount_default_value}
								onChange={getChangeHandler("discount_default_value")}
								suffix={form?.discount_default_type === "PERCENTAGE" ? "%" : "SEK"}
								prefix={
									<Tooltip content={t("orders.row.fields.discount_type.label", "Rabatt typ")}>
										<Button
											plain
											icon={ResetMinor}
											onClick={() => {
												getChangeHandler("discount_default_type")(form?.discount_default_type === "PERCENTAGE" ? "AMOUNT" : "PERCENTAGE");
											}}
										/>
									</Tooltip>
								}
							/>
						</FormLayout>
					</Layout.Section>
				</Layout>
			</LegacyCard>
		</InfoWrapper>
	);
};
export default withTranslation(["article", "common"])(ArticleOrderSettings);
