import React from "react";
import { RobotIcon } from "src/js/icons";
import { Tooltip } from "@shopify/polaris";
import styled from "styled-components";

const ChatBotIcon = ({ size = 20 }) => {
	return (
		<Tooltip
			content={
				<div style={{ padding: "0.5rem" }}>
					<b>{"Sellfinity's Chatbot."}</b>
					<p>Hejsan, jag är här för att hjälpa dig!</p>
				</div>
			}
		>
			<Wrapper size={size}>
				<RobotIcon />
			</Wrapper>
		</Tooltip>
	);
};
export default ChatBotIcon;

const Wrapper = styled.div<{ size: number }>`
	svg {
		width: ${(props) => props.size}px;
		height: ${(props) => props.size}px;
		min-width: ${(props) => props.size}px;
		min-height: ${(props) => props.size}px;
	}
`;
