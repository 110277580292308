import React, { useEffect } from "react";
import { getLocalStorage } from "src/js/Utilities";

export const MAX_WIDTH_PERCENTAGE = 0.85;
export const MIN_WIDTH = 450;

const SheetContext = React.createContext<any | null>(null);
const SheetContextProvider = ({ children }) => {
	const [sheetSettings, setSheetSettings] = React.useState<SheetSettingsType>(
		getLocalStorage("sheetSettings") || {
			isPinned: false,
			width: 0,
			pinnedWidth: 0,
		}
	);

	useEffect(() => {
		const widthKeys = ["width", "pinnedWidth"];

		widthKeys.forEach((key) => {
			const value = sheetSettings[key];
			const cssKey = key === "pinnedWidth" ? "--sheet-width-pinned" : "--sheet-width";
			if (value) {
				const width = Math.max(Math.min(value || 0, window.innerWidth * MAX_WIDTH_PERCENTAGE), MIN_WIDTH);
				if (width) document.body.style.setProperty(cssKey, width + "px");
			}
		});
	}, [sheetSettings]);

	useEffect(() => {
		localStorage.setItem("sheetSettings", JSON.stringify(sheetSettings));
	}, [sheetSettings]);

	const value = React.useMemo(() => ({ sheetSettings, setSheetSettings }), [sheetSettings, setSheetSettings]);

	return <SheetContext value={value}>{children}</SheetContext>;
};

export { SheetContextProvider };
export default SheetContext;
