import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import defaultForm from "./Utilities/defaultForm";
import Toast from "src/js/components/Toast";

type SetFormFunction = (form: Partial<CustomFieldType> | ((prevForm: Partial<CustomFieldType>) => Partial<CustomFieldType>)) => void;

type CustomfieldContextValue = {
	form: Partial<CustomFieldType>;
	customfield: CustomFieldType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	handleSave: (customfield: CustomFieldType) => any;
} & WithTranslation;

const CustomfieldContext = React.createContext({} as CustomfieldContextValue);

export const CustomfieldContextProvider = withTranslation(["customfield", "common"])(
	({ id: propsId, match, history, children, t, defaultData = history.location.state?.data || defaultForm }) => {
		const [form, setForm] = useState<Partial<CustomFieldType>>(defaultData);
		const id = propsId || match.params.id;
		const [isSaving, setIsSaving] = useState(false);

		const queryClient = useQueryClient();

		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/custom_fields/${id}.json`);

				return res.data.custom_field;
			} catch (error) {
				Toast.error(error);
				return defaultData || null;
			}
		}, [id, defaultData]);

		const queryKey = [id && `customfield-${id}`];
		const { data: customfield = null, isFetching }: UseQueryResult<CustomFieldType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: defaultData || null,
			enabled: !!id,
		});

		useEffect(() => {
			if (customfield) setForm(customfield);
		}, [customfield]);

		const saveFunction = useCallback(
			async (customfield: CustomFieldType) => {
				const endpoint = !id ? `/api/custom_fields.json` : `/api/custom_fields/${id}.json`;

				const res = await (id ? API.put(endpoint, { ...customfield }) : API.post(endpoint, { ...customfield }));

				const successMessage = id
					? t("customfields.responses.customfield_saved", "Eget fält sparad")
					: t("customfields.responses.customfield_created", "Eget fält skapat");

				Toast.success(successMessage);

				history.replace(`/admin/customfields/${res.data.custom_field.id}`, {
					data: res.data.custom_field,
				});

				return res;
			},
			[id, t, history]
		);

		const update = async ({ customfield }) => {
			const response = await saveFunction(customfield);
			return response?.data.custom_field;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			async (customfield: CustomFieldType) => {
				try {
					if (!customfield) {
						throw new Error("No customfield");
					}

					setIsSaving(true);
					return await mutation.mutateAsync({ customfield });
				} catch (e: any) {
					if (!e?.response) throw e;
					Toast.error(e);
				} finally {
					setIsSaving(false);
				}
			},
			[mutation]
		);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				customfield,
				isSaving,
			}),
			[isFetching, t, id, form, handleSave, setForm, customfield, isSaving]
		);

		return useMemo(() => <CustomfieldContext value={value}>{children}</CustomfieldContext>, [value, children]);
	}
);
export default CustomfieldContext;
