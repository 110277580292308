import { Checkbox, FormLayout, Icon, OptionList } from "@shopify/polaris";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Button from "src/js/components/Button";
import CollapsibleSection from "src/js/components/CollapsibleSection/CollapsibleSection";
import Group from "src/js/components/Styles/Group";
import TextField from "src/js/components/TextField";
import MyActionList from "../../Workspaces/components/MyActionList";
import { CirclePlusMajor, DeleteMajor, MobileVerticalDotsMajor } from "@shopify/polaris-icons";

type SalesStatsProps = {
	board: BoardType;
	options: any;
	onChange: (options: any) => void;
} & WithTranslation;

const SalesStats = ({ board, onChange, options, t }: SalesStatsProps) => {
	const getStatusOptions = () => {
		const statuses = (board.columns?.find((column: any) => column.type === "status")?.options?.statuses || []).filter((s) => !s.removed);

		return statuses.map((status: any) => ({
			value: status.id,
			label: status.label,
		}));
	};

	const handleChange = (key: string, secondKey: string) => (values: any[]) => {
		if (!options.stats) options.stats = {};
		if (!options.stats[key]) options.stats[key] = {};

		options.stats[key][secondKey] = values;

		onChange(options);
	};

	const handleStatGroups = (index: number, field: string) => (value: any) => {
		if (!options.stats) options.stats = {};
		if (!options.stats.groups) options.stats.groups = [];

		options.stats.groups[index][field] = value;

		onChange(options);
	};

	const handleAddStatGroup = () => {
		if (!options.stats) options.stats = {};
		if (!options.stats.groups) options.stats.groups = [];

		options.stats.groups.push({
			name: `Grupp ${options.stats.groups.length + 1}`,
			column_ids: [],
			enabled: true,
		});

		onChange(options);
	};

	const formulaColumns = board.columns.filter((column: any) => column.type === "formula" && !column.removed);
	const formulaColumnOptions = formulaColumns.map((column: any) => ({
		value: column.id,
		label: column.title,
	}));

	const getColumns = (ar) => {
		if (!ar) return [];
		return Array.isArray(ar) ? ar : Object.values(ar);
	};

	return (
		<>
			<Group label={t("board.settings.stats.title", "Statistik inställningar") as string} labelBackgroundColor="var(--main3)">
				<CollapsibleSection
					title={
						<Trans
							i18nKey="board.settings.stats.won.label2"
							defaults="Vilka statusar ska räknas som <b>{{status}}</b> affärer?"
							components={{
								b: <span style={{ fontWeight: 700, color: Colors.green }} />,
							}}
							values={{
								status: "vunna",
							}}
						/>
					}
				>
					<OptionList
						options={getStatusOptions()}
						selected={options?.stats?.won?.status_values || []}
						onChange={handleChange("won", "status_values")}
						allowMultiple
					/>
				</CollapsibleSection>
				<CollapsibleSection
					title={
						<Trans
							i18nKey="board.settings.stats.lost.label3"
							defaults="Vilka statusar ska räknas som <b>{{status}}</b> affärer?"
							components={{
								b: <span style={{ fontWeight: 700, color: Colors.red }} />,
							}}
							values={{
								status: "förlorade",
							}}
						/>
					}
				>
					<OptionList
						options={getStatusOptions()}
						selected={options?.stats?.lost?.status_values || []}
						onChange={handleChange("lost", "status_values")}
						allowMultiple
					/>
				</CollapsibleSection>

				<CollapsibleSection
					title={
						<Trans
							i18nKey="board.settings.stats.oms_columns.label"
							defaults="Vilka kolumner ska räknas som <b>{{type}}</b> per person för dashboards?"
							components={{
								b: <span style={{ fontWeight: 700 }} />,
							}}
							values={{
								type: "Omsättning",
							}}
						/>
					}
				>
					<FormLayout>
						<OptionList
							options={formulaColumnOptions}
							selected={getColumns(options?.stats?.revenue?.columns) || []}
							onChange={handleChange("revenue", "columns")}
							allowMultiple
						/>
					</FormLayout>
				</CollapsibleSection>

				<CollapsibleSection
					title={
						<Trans
							i18nKey="board.settings.stats.tb_columns.label"
							defaults="Vilka kolumner ska räknas som <b>{{type}}</b> per person för dashboards?"
							components={{
								b: <span style={{ fontWeight: 700 }} />,
							}}
							values={{
								type: "TB",
							}}
						/>
					}
				>
					<FormLayout>
						<OptionList
							options={formulaColumnOptions}
							selected={getColumns(options?.stats?.tb?.columns) || []}
							onChange={handleChange("tb", "columns")}
							allowMultiple
						/>
					</FormLayout>
				</CollapsibleSection>
				<Group label={"Egna Dashboard statistik grupper"} labelBackgroundColor="var(--main3)" style={{ marginTop: "1.8750rem" }}>
					{options?.stats?.groups?.map((group: any, index: number) => (
						<CollapsibleSection
							title={
								<div style={{ display: "flex", gap: "0.3125rem", alignContent: "center" }}>
									<div onClick={(e) => e.stopPropagation()}>
										<Checkbox label="" labelHidden checked={group.enabled} onChange={(checked) => handleStatGroups(index, "enabled")(checked)} />
									</div>
									{group.name}
								</div>
							}
							key={index}
						>
							<TextField
								label="Namn"
								value={group.name}
								onChange={handleStatGroups(index, "name")}
								suffix={
									<MyActionList
										disclosure={false}
										activatorProps={{ plain: true }}
										title={<Icon source={MobileVerticalDotsMajor} />}
										items={[
											{
												content: "Ta bort",
												icon: DeleteMajor,
												destructive: true,
												onAction: () => {
													options.stats.groups.splice(index, 1);
													onChange(options);
												},
											},
										]}
									/>
								}
							/>

							<OptionList
								options={formulaColumnOptions}
								selected={group.column_ids || []}
								onChange={handleStatGroups(index, "column_ids")}
								allowMultiple
							/>
						</CollapsibleSection>
					))}

					<div style={{ marginTop: "0.6250rem" }}>
						<Button onClick={handleAddStatGroup} icon={CirclePlusMajor} primary />
					</div>
				</Group>
			</Group>
		</>
	);
};

export default withTranslation(["board", "common"])(SalesStats);
