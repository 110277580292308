import { Icon, LegacyCard, Text, type LegacyCardProps } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import Collapsible from "../Collapsible";
import { generateId } from "src/js/Utilities";
import Spinner from "../Spinner";

export type CustomCardProps = LegacyCardProps & {
	children?: React.ReactNode;
	title?: string | React.ReactNode;
	subdued?: boolean;
	open?: boolean;
	collapsible?: boolean;
};

const CustomCard = ({
	children,
	open: propsOpen = true,
	title,
	subdued: propsSubdued,
	sectioned,
	collapsible,
	actions: propsActions,
	...rest
}: CustomCardProps) => {
	const [open, setOpen] = useState(propsOpen);
	const handleToggle = useCallback(() => setOpen((c) => !c), []);
	const id = generateId();
	const subdued = propsActions?.length ? false : propsSubdued;
	const [loadingStates, setLoadingStates] = useState({});

	const content = sectioned ? (
		<CardSection open={!!open} subdued={!!subdued}>
			<Collapsible id={String(id)} open={open}>
				{children}
			</Collapsible>
		</CardSection>
	) : (
		<Collapsible id={String(id)} open={open}>
			{children}
		</Collapsible>
	);

	const titleElement = (
		<TitleWrapper onClick={collapsible ? handleToggle : undefined} open={!!open} subdued={!!subdued}>
			<Text fontWeight="bold" as="h3" variant={subdued ? "headingSm" : "headingMd"}>
				{title}
			</Text>
		</TitleWrapper>
	);

	const handleActionOnAction = async (action, index) => {
		if (action.onAction) {
			setLoadingStates((prev) => ({ ...prev, [index]: true }));
			await action.onAction();
			setLoadingStates((prev) => ({ ...prev, [index]: false }));
		}
	};

	const actions =
		collapsible && propsActions?.length
			? ([
					...(propsActions || []).map((action, index) => ({
						...action,
						content: (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "0.15rem",
								}}
							>
								{!("loading" in action) && loadingStates[index] && <Spinner size="small" />}
								{action.content}
							</div>
						) as unknown as string,
						onAction: () => handleActionOnAction(action, index),
					})),

					{
						content: (
							<IconWrapper open={open}>
								<Icon source={ChevronDownMinor} />
							</IconWrapper>
						),
						onAction: handleToggle,
					},
			  ] as LegacyCardProps["actions"])
			: propsActions;

	return (
		<LegacyCard
			title={!subdued && title ? titleElement : null}
			{...rest}
			//Maybe to replacing these actions with a custom component that rendars all the actions
			actions={actions}
		>
			{subdued && title && titleElement}
			{collapsible && !propsActions?.length && (
				<CollapsibleToggle onClick={handleToggle}>
					{
						<IconWrapper open={open}>
							<Icon source={ChevronDownMinor} />
						</IconWrapper>
					}
				</CollapsibleToggle>
			)}

			{content}
		</LegacyCard>
	);
};
export default CustomCard;

const CollapsibleToggle = styled.button`
	background-color: transparent;
	padding: var(--p-space-5);
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 0;
	right: 0;
`;
const IconWrapper = styled.div<{ open: boolean }>`
	transition: transform 0.3s ease;
	transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
`;
const CardSection = styled.div.attrs({ className: "Polaris-LegacyCard__Section" })<{ open?: boolean; subdued?: boolean }>`
	&&& {
		padding-top: 0;
		padding-bottom: ${(p) => (p.open ? "var(--p-space-5)" : "0")};

		${({ subdued }) =>
			subdued &&
			css`
				border-top: none;
			`}
	}
`;

const TitleWrapper = styled.div<{ subdued?: boolean; open?: boolean }>`
	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}

	padding-bottom: var(--p-space-5);

	.Polaris-Text--root {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	${({ subdued, open }) =>
		subdued
			? css`
					padding: var(--p-space-5);
			  `
			: css`
					padding-top: 0;
					/* padding-bottom: ${() => (open ? "var(--p-space-5)" : "0")}; */
					padding-bottom: "var(--p-space-5)";
			  `}
`;
