/* eslint-disable react/jsx-props-no-spreading */
import { Select as ShopifySelect } from "@shopify/polaris";
import type { SelectProps as ShopifySelectProps } from "@shopify/polaris";
import { DefaultTFuncReturn } from "i18next";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

interface SelectProps extends Omit<ShopifySelectProps, "label"> {
	label?: string | DefaultTFuncReturn;
	readOnly?: boolean;
	required?: boolean;

	primary?: boolean;
	width?: number;
	minWidth?: number;
}

const Select = forwardRef(({ label = "", readOnly, required, primary, width, minWidth, ...rest }: SelectProps, ref) => {
	return (
		<Wrapper readOnly={readOnly} ref={ref} required={required} haveValue={!!rest.value} primary={primary} width={width} minWidth={minWidth}>
			<ShopifySelect label={label as string} {...rest} requiredIndicator={rest.requiredIndicator || required} />
		</Wrapper>
	);
});

export default Select;

const Wrapper = styled.div<{
	readOnly?: boolean;
	ref: any;
	required?: boolean;
	haveValue?: boolean;
	primary?: boolean;
	width?: number;
	minWidth?: number;
}>`
	width: ${({ width }) => (width ? `${width}px` : "unset")};
	min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "unset")};

	${({ primary }) =>
		primary
			? css`
					.Polaris-Select {
						--bg: var(--accent);
						--color: rgb(15, 15, 15);

						.Polaris-Select__Content {
							transition: background-color 0.2s;
							background: var(--bg);
							background-color: var(--bg);
							color: rgb(15, 15, 15);
						}

						.Polaris-Select__SelectedOption {
							color: var(--color);
						}

						..Polaris-Select__Icon svg {
							fill: var(--color);
						}

						&:hover {
							--bg: var(--accent-hover);
						}
					}
			  `
			: ""}

	${({ readOnly }) =>
		readOnly
			? css`
					pointer-events: none;

					.Polaris-Select__Icon {
						display: none;
					}
			  `
			: ""}

	&&& {
		${(props) => {
			if (props.required && !props.haveValue) {
				return css`
					.Polaris-Select__Backdrop::after {
						/* border: 2px solid red !important; */
						box-shadow: 0 0 0 0.2rem #bf0711 !important;
					}
				`;
			}
		}}
	}
`;
