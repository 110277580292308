import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import getDefaultForm from "./Utilities/getDefaultForm";
import { formatOrderToRead, formatOrderToWrite } from "./Utilities/formatRow";
import { PDFContext } from "src/js/PDF/PDFContext";
import Toast from "src/js/components/Toast";
import formatOrderForPostRequest from "./Utilities/formatOrderForPostRequest";

type SetFormFunction = (form: Partial<OrderType> | ((prevForm: Partial<OrderType>) => Partial<OrderType>)) => void;

type OrderContextValue = {
	form: Partial<OrderType>;
	order: OrderType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	isLoading: boolean;
	disabled: boolean;
	handleSave: (order: OrderType) => any;

	[key: string]: any;
} & WithTranslation;

const OrderContext = React.createContext({} as OrderContextValue);

export const OrderContextProvider = withTranslation(["order", "common"])(
	({ id: propsId, match, history, children, t, defaultData = getDefaultForm(history) }) => {
		const [form, setForm] = useState<Partial<OrderType>>(defaultData);
		const id = propsId || match.params.id;
		const { handleOpen, handleClose: handleClosePDF } = useContext(PDFContext);

		const handleSendForSigningMutation = useMutation(
			async (pdfData: any) => {
				const res = await API.put(`/api/orders/${id}/send.json`, {
					...formatOrderForPostRequest(formatOrderToWrite(form)),
					pdf_data: { ...(pdfData || {}), pages: (pdfData || {})?.pages.map((page) => page.data) },
				});

				Toast.success(t("orders.responses.sent_preparing", "Förbereder order för signering"));
				return res.data.order as OrderType;
			},
			{
				onSuccess: (data) => {
					if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				},
			}
		);

		const handleSendForSigning = useCallback(
			async (data) => {
				try {
					const order = await handleSendForSigningMutation.mutateAsync(data);
					const scriveData = order.upload?.board_row_value?.value.find((v) => v.id === order?.upload?.id)?.scrive_data;
					handleClosePDF();
					if (scriveData?.id) {
						history.push(`/admin/scrive/documents/${scriveData.id}`, { document: scriveData, edit: true, tabId: "parties" });
					}
				} catch (e: any) {
					if (!e?.response) throw e;
					handleClosePDF();
					Toast.error(e);
				}
			},
			[handleSendForSigningMutation, history, handleClosePDF]
		);

		const handleOpenPDF = useCallback(
			(opt) => {
				return handleOpen({ order: form }, { saveHandler: handleSendForSigning, name: `Order #${form?.order_number}`, ...(opt || {}) });
			},
			[handleOpen, form, handleSendForSigning]
		);

		const queryClient = useQueryClient();

		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/orders/${id}.json`);

				return res.data.order;
			} catch (error) {
				Toast.error(error);
				return defaultData || null;
			}
		}, [id, defaultData]);

		const queryKey = [id && `order_${id}`].filter(Boolean);
		const { data: order = null, isFetching }: UseQueryResult<OrderType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: defaultData || null,
			enabled: !!id,
		});

		useEffect(() => {
			if (order) setForm(formatOrderToRead(order));
		}, [order]);

		const saveFunction = useCallback(
			async (order: OrderType) => {
				const endpoint = !id ? `/api/orders.json` : `/api/orders/${id}.json`;
				const data = {
					...formatOrderForPostRequest(formatOrderToWrite(order)),
				};

				const res = await (id ? API.put(endpoint, data) : API.post(endpoint, data));

				const successMessage = id ? t("orders.responses.order_saved", "Order sparad") : t("orders.responses.order_created", "Order skapad");

				Toast.success(successMessage);

				history.replace(`/admin/orders/${res.data.order.id}`, {
					data: res.data.order,
				});

				return res;
			},
			[id, t, history]
		);

		const update = async ({ order }) => {
			const response = await saveFunction(order);
			return response?.data.order;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			async (order: OrderType) => {
				try {
					if (!order) {
						throw new Error("No order");
					}

					return await mutation.mutateAsync({ order });
				} catch (e: any) {
					if (!e?.response) throw e;
					Toast.error(e);
				}
			},
			[mutation]
		);

		const handleMarkAsApprovedMutation = useMutation(
			async () => {
				const res = await API.put(`/api/orders/${id}/mark_as_signed.json`);

				Toast.success(t("orders.responses.marked_order_approved", "Order markerad som godkänd"));

				return res.data.order as OrderType;
			},
			{
				onSuccess: (data) => {
					if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				},
			}
		);

		const handleMarkAsApproved = useCallback(async () => {
			try {
				await handleMarkAsApprovedMutation.mutateAsync();
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		}, [handleMarkAsApprovedMutation]);

		const handleCancelMutation = useMutation(
			async () => {
				const res = await API.put(`/api/orders/${id}/cancel.json`);

				Toast.success(t("orders.responses.canceled", "Order avbryten"));
				return res.data.order as OrderType;
			},
			{
				onSuccess: (data) => {
					if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				},
			}
		);

		const handleCancel = useCallback(async () => {
			try {
				await handleCancelMutation.mutateAsync();
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		}, [handleCancelMutation]);

		const handleCancelSigningMutation = useMutation(
			async () => {
				const res = await API.put(`/api/orders/${id}/cancel_signing_and_unlock.json`);

				Toast.success(t("orders.responses.signing_canceled", "Order signering avbryten"));
				return res.data.order as OrderType;
			},
			{
				onSuccess: (data) => {
					if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				},
			}
		);

		const handleCancelSigning = useCallback(async () => {
			try {
				await handleCancelSigningMutation.mutateAsync();
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		}, [handleCancelSigningMutation]);

		const isSaving = mutation.isLoading;
		const isCanceling = handleCancelMutation.isLoading;
		const isCancelingSigning = handleCancelSigningMutation.isLoading;
		const isMarkingAsApproved = handleMarkAsApprovedMutation.isLoading;

		const isLoading = isSaving || isFetching || isCanceling || isCancelingSigning || isMarkingAsApproved;
		const disabled = isLoading || form.is_locked;
		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				order,
				isSaving,
				disabled,
				isLoading,
				handleOpenPDF,
				handleMarkAsApproved,
				handleCancel,
				handleCancelSigning,
				handleSendForSigning,
			}),
			[
				isFetching,
				t,
				id,
				form,
				handleSave,
				setForm,
				order,
				isSaving,
				disabled,
				isLoading,
				handleOpenPDF,
				handleMarkAsApproved,
				handleCancel,
				handleCancelSigning,
				handleSendForSigning,
			]
		);

		return useMemo(() => <OrderContext value={value}>{children}</OrderContext>, [value, children]);
	}
);
export default OrderContext;
