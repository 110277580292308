import React, { useState, useEffect, useCallback } from "react";
import { DropZone, Labelled, Icon } from "@shopify/polaris";
import { UploadMajor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import Attachment from "./Attachment.js";
import { DropZoneFileType } from "@shopify/polaris/build/ts/src/components/DropZone/DropZone.js";

type UploadProps = {
	onUpload?: (files: any) => void;
	files?: any[];
	open?: boolean;
	onClose?: () => void;
	removeFile?: (file: any) => void;
	width?: string | number;
	height?: string | number;
	allowMultiple?: boolean;
	type?: DropZoneFileType | undefined;
	showFiles?: boolean;
	children?: any;
	onChange?: (data: any) => void;
};

const Upload = ({
	onUpload,
	files: propFiles = [],
	open: openFileDialog = false,
	onClose,
	removeFile,
	width,
	height,
	allowMultiple = true,
	type = "file",
	showFiles = true,
	children,
	onChange,
	...rest
}: UploadProps) => {
	const { t } = useTranslation(["common", "upload"]);
	const [files, setFiles] = useState(propFiles);

	// Update state when props.files or props.open change
	useEffect(() => {
		setFiles(propFiles || []);
	}, [propFiles]);

	// Function to read a single file and return a promise
	const readFile = useCallback((file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				const data = event.target?.result;
				resolve({
					id: null,
					name: file.name,
					size: file.size,
					type: file.type,
					url: data,
					data,
				});
			};
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file);
		});
	}, []);

	// Handle file uploads
	const handleFiles = useCallback(
		async (acceptedFiles) => {
			try {
				const newFiles = await Promise.all(acceptedFiles.map(readFile));
				const updatedFiles = newFiles;
				setFiles(updatedFiles);
				if (onUpload) {
					if (allowMultiple) onUpload(updatedFiles);
					else onUpload(updatedFiles?.[0]);
				}
			} catch (error) {
				console.error("Error reading files:", error);
				// Optionally handle the error (e.g., show a notification)
			}
		},
		[allowMultiple, onUpload, readFile]
	);

	// Handle drop event
	const handleDrop = useCallback(
		(acceptedFiles, rejectedFiles) => {
			if (acceptedFiles.length > 0) {
				handleFiles(acceptedFiles);
			}
			// Optionally handle rejected files
			// Example:
			// if (rejectedFiles.length > 0) {
			//   // Handle rejected files (e.g., show an error message)
			// }
		},
		[handleFiles]
	);

	// Handle closing the file dialog
	const handleFileDialogClose = useCallback(() => {
		// setOpenFileDialog(false);
		if (onClose) {
			onClose();
		}
	}, [onClose]);

	// Update a specific file in the state
	const updateFile = useCallback(
		(index, updatedFile) => {
			setFiles((prevFiles) => {
				const newFiles = [...prevFiles];
				newFiles[index] = updatedFile;
				if (onUpload) {
					onUpload(newFiles);
				}

				if (onChange) {
					onChange({ files: newFiles });
				}
				return newFiles;
			});
		},
		[onUpload, onChange]
	);

	return (
		<Labelled id="upload" label="" {...rest}>
			<div className="upload_cont" style={{ width: width || "auto", height: height || "auto" }}>
				<DropZone
					allowMultiple={allowMultiple}
					outline={files.length < 1 && !!width}
					openFileDialog={openFileDialog}
					type={type}
					onDrop={handleDrop}
					onFileDialogClose={handleFileDialogClose}
				>
					{(width || showFiles) && (
						<>
							{files.length > 0 ? (
								<div style={{ width: width || "max-content" }}>
									{files.map((file, index) => (
										<Attachment key={index} file={file} onRemove={removeFile} onChange={(updatedFile) => updateFile(index, updatedFile)} />
									))}
								</div>
							) : width ? (
								<div>
									<div className="large-upload-icon">
										<Icon source={UploadMajor} />
									</div>
									<DropZone.FileUpload
										actionTitle={t("common.upload.actions.choose", "Välj fil") as string}
										actionHint={t("common.upload.actions.helptext", "Eller dra och släpp en fil") as string}
									/>
								</div>
							) : null}
						</>
					)}
					{children}
				</DropZone>
			</div>
		</Labelled>
	);
};

export default Upload;
