import { Collapsible, Icon } from "@shopify/polaris";
import { ChevronDownMinor, ChevronLeftMinor } from "@shopify/polaris-icons";
import React, { useEffect } from "react";
import styled from "styled-components";

type CollapsibleSectionProps = {
	title: string | React.ReactNode;
	open?: boolean;
	fontSize?: number | string | null;

	children: React.ReactNode;
	className?: string;
	activatorClassName?: string;
	collapsible?: boolean;
};

const CollapsibleSection = ({
	className,
	activatorClassName,
	title,
	children,
	open = true,
	fontSize = "1em",
	collapsible = true,
}: CollapsibleSectionProps) => {
	const [collapsed, setCollapsed] = React.useState(!!collapsible || !open);

	useEffect(() => {
		setCollapsed(!open);
	}, [open]);

	const toggleCollapse = () => {
		setCollapsed((c) => !c);
	};

	return (
		<CollapsibleSectionWrapper className={className}>
			<CollapsibleSectionActivator haveTitle={!!title} className={activatorClassName} onClick={collapsible ? toggleCollapse : undefined}>
				{title && <H4 fontSize={fontSize}>{title}</H4>}
				{collapsible && <Icon source={collapsed ? ChevronLeftMinor : ChevronDownMinor} />}
			</CollapsibleSectionActivator>
			<Collapsible id={title as string} open={!collapsed}>
				{children}
			</Collapsible>
		</CollapsibleSectionWrapper>
	);
};

export default CollapsibleSection;
const CollapsibleSectionWrapper = styled.div``;
const CollapsibleSectionActivator = styled.div<{ onClick?: any; haveTitle?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: ${(props) => (props.onClick ? "pointer" : "default")};
	padding: ${(props) => (props.haveTitle ? "0.625rem 0rem" : "0rem")};

	h4 {
		font-weight: 500;
	}

	.Polaris-Icon {
		margin: 0;
	}
`;

const H4 = styled.h4<{ fontSize?: number | string | null }>`
	font-size: ${(props) => (typeof props.fontSize === "number" ? `${props.fontSize}px` : props.fontSize)};
`;
