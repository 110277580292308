import React from "react";
import "./index.css";
// import ReactDOM from "react-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import styled from "styled-components";
import { Buffer } from "buffer";
import { AppProvider, Button } from "@shopify/polaris";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import BASE_URL from "./js/API/BASE_URL";
import { getMainDomain } from "./js/API/utils";
import { store } from "./js/store";

import "./css/toastr/index.scss";
import "./js/Properties";
import parentRoutes from "./js/parent_routes.js";
import "./js/i18n";
// import FrontendExceptionLogger from "./js/components/FrontendExceptionLogger";
import getTranslations from "./js/getTranslations";
import linkComponent from "./js/linkComponent";
import "moment/min/locales";
import Toast from "src/js/components/Toast";
import { SheetContextProvider } from "./js/components/sheet/SheetContext";
import { getRefreshToken } from "./js/Utilities/Utilities";
import RouteWithTitle from "./RouteWithTitle";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./js/components/ErrorBoundary";

const queryClient = new QueryClient();

window.open = (function (open) {
	return function (url, name, features) {
		if (!url) {
			Toast.error("Saknar url för att öppna fönster");
			return;
		}

		try {
			if (
				(getMainDomain(url) !== getMainDomain(window.location.href) && getMainDomain(url) !== "sellfinity") ||
				url?.startsWith("https://www.sellfinity.com/")
			) {
				return open.call(window, url, name, features);
			}

			if (!process.env.NODE_ENV || (process.env.NODE_ENV === "development" && url?.startsWith("/api"))) {
				const baseURL = BASE_URL;
				url = baseURL + url;
			}

			const newUrl = url.startsWith("http") ? new URL(url) : new URL(window.location.origin + url);
			newUrl.searchParams.append("refresh_token", getRefreshToken());

			name = name || "Sellfinity";

			if (process.env.NODE_ENV === "development" && newUrl.toString()?.startsWith("http")) {
				let url = new URL(newUrl.toString());
				url = BASE_URL + url.pathname + url.search;

				return open.call(window, url.toString(), name, features);
			}

			return open.call(window, newUrl.toString(), name, features);
		} catch (e) {
			Toast.error(e.message);
			console.error("window.open Error", e);
		}
	};
})(window.open);

global.Buffer = Buffer;
// import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<AppProvider i18n={getTranslations()} linkComponent={linkComponent}>
				<div className="maincont 999" style={{ height: "100%" }}>
					<ErrorBoundary
						fallbackRender={({ error, resetErrorBoundary }) => (
							<div
								style={{
									position: "fixed",
									display: "flex",
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									textAlign: "center",
									justifyContent: "center",
									alignItems: "center",
									color: "var(--textColor)",
								}}
							>
								<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
									<p>Något gick fel, felet rapporterades</p>

									<Button onClick={resetErrorBoundary}>Prova igen</Button>
								</div>
							</div>
						)}
					>
						<SheetContextProvider>
							<BrowserRouter>
								<Switch>
									{parentRoutes.map((prop, key) => {
										if (prop.redirect) {
											const queryParams = new URLSearchParams(window.location.search);
											return <Redirect from={prop.path} to={prop.redirect + "?" + queryParams.toString()} key={key} />;
										}

										if (prop.name) {
											return <RouteWithTitle path={prop.path} component={prop.component} key={key} exact={prop.exact} route={prop} />;
										}

										return <Route path={prop.path} component={prop.component} key={key} exact={prop.exact} />;
									})}
								</Switch>
							</BrowserRouter>
						</SheetContextProvider>
					</ErrorBoundary>
					<ToastContainer
						position="bottom-right"
						// hideProgressBar={true}
						autoClose={5000}
						newestOnTop
						stacked
						closeOnClick
						closeButton
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="colored"
						transition={Bounce}
					/>

					<ReduxToastr
						timeOut={4000}
						newestOnTop={false}
						preventDuplicates
						position="bottom-center"
						transitionIn="fadeIn"
						transitionOut="fadeOut"
						progressBar
						closeOnToastrClick
					/>
				</div>
			</AppProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</Provider>
	// {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
