import React from "react";
import { Icon, Tabs as PolarisTabs, type TabsProps as PolarisTabsProps, type TabProps } from "@shopify/polaris";
import styled from "styled-components";
import Spinner from "../Spinner";

type TabProp = TabProps & {
	loading?: boolean;
	title?: string;
	icon?: any;

	content?: React.ReactNode | string | Element;
	badge?: TabProps["badge"] | React.ReactNode | Element | null | false;
};

export type TabsProps = {
	tabs: TabProp[];
	useNative?: boolean;
	renderChildrenInside?: boolean;
	style?: React.CSSProperties;
} & PolarisTabsProps;

const Tabs = ({ children = null, tabs, selected, onSelect, useNative = true, renderChildrenInside = false, style, ...rest }: TabsProps) => {
	const formattedTabs = [...tabs]?.map((tab) => {
		if (useNative) {
			return {
				...tab,
				icon: undefined,
				content: (tab.content || tab.title) as string,
				badge: tab.loading ? (
					<Spinner size="small" />
				) : (
					tab.badge || (React.isValidElement(tab.icon) ? tab.icon : (tab.icon && <Icon source={tab.icon} />) || null)
				),
			};
		}

		//!This can make textfields etc.. in under the tabs lose focus when typing, that why useNative is true by default.
		const prefix = tab.loading ? (
			<Spinner size="small" />
		) : React.isValidElement(tab.icon) ? (
			tab.icon
		) : (
			(tab.icon && <Icon source={tab.icon} />) || null
		);

		return {
			...tab,
			icon: undefined,
			content: (
				<TabWrapper>
					{prefix && <IconWrapper>{prefix}</IconWrapper>}
					{tab.title || tab.content}
				</TabWrapper>
			) as unknown as string,
		};
	});

	return (
		<Wrapper style={style}>
			{renderChildrenInside ? (
				<PolarisTabs tabs={formattedTabs} selected={selected} onSelect={onSelect} {...rest}>
					{children}
				</PolarisTabs>
			) : (
				<>
					<PolarisTabs tabs={formattedTabs} selected={selected} onSelect={onSelect} {...rest} />
					{children}
				</>
			)}
		</Wrapper>
	);
};
export default Tabs;

const TabWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const IconWrapper = styled.div`
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: start;
`;

const Wrapper = styled.div`
	&&& {
		.Polaris-Badge {
			background: unset;
			background-color: unset;
		}

		.Polaris-Tabs__Tab:not(.Polaris-Tabs__Tab--active) .Polaris-Badge {
			color: var(--p-color-text-subdued);
		}
	}
`;
