/* eslint-disable camelcase */
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import getTargetConnectionsWithData from "../../Targets/Utilities/getTargetConnectionsWithData";
import Toast from "src/js/components/Toast";

type TargetDashboardContextValue = {
	target: TargetType | null;
	isLoading: boolean;
	id: string;
	targetConnectionsOptions: any;
	targetConnectionsIds: string[];
	targetOptions: any;
	disabled: boolean;
	setTarget: any;
	targets: TargetType[];
	setTargetConnectionsIds: any;
	data: any;
	targetConnections: any;
	setDate: any;
	onChangeTarget: any;
	compareTargetConnections: any;
	compareData: any;
	setCompareDate: any;
	compareDate: string;
	isLoadingCompare: boolean;
	history: any;
	handleGoNext: any;
	handleGoPrevious: any;
} & WithTranslation;

const TargetDashboardContext = React.createContext({} as TargetDashboardContextValue);

export const TargetDashboardContextProvider = withTranslation(["targets", "common"])(({ t, history, children }) => {
	const targets: TargetType[] = useSelector((state: any) => state.targets);
	const [target, setTarget] = useState<TargetType | null>(history?.location?.state?.target || targets?.[0] || null);
	const [date, setDate] = useState(target?.group_start_at || moment().format("YYYY-MM-DD"));
	const [compareDate, setCompareDate] = useState(target?.prev_group_start_at || moment().format("YYYY-MM-DD"));
	const params = useMemo(() => ({ date }), [date]);

	//not used for comparing, just for UI stuff
	const [previousData, setPreviousData] = useState<any>(null);

	const onChangeTarget = useCallback(
		(targetId) => {
			const target = targets.find((t) => String(t.id) === targetId);
			if (target) {
				setTarget(target);
				setDate(target.group_start_at || moment().format("YYYY-MM-DD"));
				setCompareDate(target.prev_group_start_at || moment().format("YYYY-MM-DD"));
				setTargetConnectionsIds([]);
				setPreviousData(null);
			}
		},
		[targets]
	);

	const fetch = useCallback(async () => {
		try {
			if (!target?.id) return null;
			const res = await API.get(`/api/targets/${target.id}/dashboard.json`, { params });

			const targetConnectionsWithId = getTargetConnectionsWithData(res.data.target_connections);

			return {
				...res.data,
				target_connections: targetConnectionsWithId,
			};
		} catch (error) {
			Toast.error(error);
		}
	}, [target?.id, params]);

	const queryKey = [target?.id && `target-${target?.id}`, params].filter(Boolean);
	const { data: targetData, isFetching: isLoading } = useQuery({
		queryKey,
		queryFn: fetch,
		enabled: !!target?.id,
	});

	const data = useMemo(() => {
		if (isLoading) return targetData || previousData || {};

		return targetData || {};
	}, [targetData, previousData, isLoading]);

	const handleGoNext = useCallback(() => {
		setPreviousData(targetData);
		setDate(data!.next_group_start_at);
	}, [targetData, data]);

	const handleGoPrevious = useCallback(() => {
		setPreviousData(targetData);
		setDate(data!.previous_group_start_at);
	}, [targetData, data]);

	const compareParams = useMemo(() => ({ date: compareDate, comparing: true }), [compareDate]);
	const fetchCompare = useCallback(async () => {
		try {
			if (!target?.id) return null;
			const res = await API.get(`/api/targets/${target.id}/dashboard.json`, { params: compareParams });

			const targetConnectionsWithId = getTargetConnectionsWithData(res.data.target_connections);

			return {
				...res.data,
				target_connections: targetConnectionsWithId,
			};
		} catch (error) {
			Toast.error(error);
		}
	}, [target?.id, compareParams]);

	const queryKeyCompare = [target?.id && `target-${target?.id}`, `compare_period_${compareDate}`, params].filter(Boolean);
	const { data: compareData, isFetching: isLoadingCompare } = useQuery({
		queryKey: queryKeyCompare,
		queryFn: fetchCompare,
		placeholderData: {},
		initialData: {},
		enabled: !!target?.id && !!compareDate,
	});

	const targetConnectionsOptions = data?.target_connections?.map((target_connection) => ({
		label: target_connection.label || "-",
		// label: target_connection.user ? target_connection.user.name : target_connection.user_group ? target_connection.user_group.name : "-",
		value: String(target_connection.id),
	}));
	const [targetConnectionsIds, setTargetConnectionsIds] = React.useState(data?.target_connections?.map((c) => String(c.id)) || []);
	const targetConnections = data?.target_connections?.filter((targetConnection) => targetConnectionsIds.includes(String(targetConnection.id)));
	const compareTargetConnections = compareData?.target_connections?.filter((targetConnection) =>
		targetConnectionsIds.includes(String(targetConnection.id))
	);

	useEffect(() => {
		setTargetConnectionsIds((c) => {
			if (!c.length) {
				return data?.target_connections?.map((c) => String(c.id)) || [];
			}

			return c;
		});
	}, [data?.target_connections]);

	const value: any = useMemo(
		() => ({
			target,
			data,
			isLoading,
			t,
			targetConnectionsOptions,
			targetConnectionsIds,
			disabled: isLoading || !target,
			onChangeTarget,
			targets: targets || [],
			setTargetConnectionsIds,
			targetConnections: targetConnections || [],
			setDate,
			compareData,
			isLoadingCompare,
			setCompareDate,
			compareTargetConnections,
			compareDate,
			history,
			handleGoNext,
			handleGoPrevious,
		}),
		[
			isLoading,
			t,
			data,
			target,
			targetConnectionsOptions,
			targetConnectionsIds,
			onChangeTarget,
			targets,
			setTargetConnectionsIds,
			targetConnections,
			setDate,
			compareData,
			isLoadingCompare,
			setCompareDate,
			compareTargetConnections,
			compareDate,
			history,
			handleGoNext,
			handleGoPrevious,
		]
	);

	return useMemo(() => <TargetDashboardContext value={value}>{children}</TargetDashboardContext>, [value, children]);
});

export default TargetDashboardContext;
