import { UseQueryResult } from "@tanstack/react-query";
import React, { useState } from "react";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { store } from "src/js/store";
import LicenseModal from "./LicenseModal";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import ContractRow from "./ContractRow";
import styled from "styled-components";
import { Layout } from "@shopify/polaris";
import ContractLicensUsers from "./ContractLicensUsers";
import LoadingContractRow from "./LoadingContractRow";
import Toast from "src/js/components/Toast";

const ContractLicenses = ({ t, history }) => {
	const [licensModalIsOpen, setLicenseModalIsOpen] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState<any>(null);

	const handleOpenModal = () => {
		setLicenseModalIsOpen(true);
	};

	const handleCloseModal = () => {
		setLicenseModalIsOpen(false);
	};

	const fetchFortnoxContract = async () => {
		try {
			const result = await API.get("/api/account/contract.json");

			return result?.data?.contract;
		} catch (error) {
			console.error("error:", error);
			Toast.error(error);
		}
	};
	// ;
	const fortnoxContractKey = ["fortnox_contract", store.getState().account?.id];
	const {
		data: contract,
		isFetching: loadingContract,
		refetch,
	}: UseQueryResult<FortnoxContractType | null> = useQuery({
		queryKey: fortnoxContractKey,
		queryFn: fetchFortnoxContract,
	});

	const refreshContract = () => {
		handleCloseModal();
		setTimeout(refetch, 1000);
	};

	const handleFetchLicenseProviders = async () => {
		try {
			const result = await API.get("/api/license_providers.json");

			return result.data.license_providers;
		} catch (error) {
			console.error("error:", error);
			Toast.error(error);
		}
	};
	const licenseProvidersKey = ["license_providers"];
	const { data: licenseProviders } = useQuery({
		queryKey: licenseProvidersKey,
		queryFn: handleFetchLicenseProviders,
	});

	const handleSelectProvider = (licenseProvider) => {
		if (!licenseProvider) return undefined;

		return () => {
			if (!licenseProvider?.role) setSelectedProvider(null);
			else setSelectedProvider(licenseProvider);
		};
	};

	return (
		<Page title={t("licenses.title", "Licenser")} history={history} primaryAction={{ content: "Ny licens", onAction: handleOpenModal }}>
			<Layout>
				<Layout.Section>
					<Wrapper>
						<thead>
							<tr>
								<th>{t("licenses.contract.articleb_number", "Artikelnummer")}</th>
								<th>{t("licenses.contract.description", "Beskrivning")}</th>
								<th>{t("licenses.contract.delivered_quantity", "Antal licenser")}</th>
								<th>
									{t("licenses.contract.total_price", "Total kostnad")}{" "}
									{`(${contract?.InvoiceInterval || "-"} ${t("licenses.contract.months", "månader")})`}
								</th>
							</tr>
						</thead>

						<tbody>
							{contract?.InvoiceRows?.map((row, index) => {
								const licenseProvider = licenseProviders?.find((provider) => provider.fortnox_sku == row.ArticleNumber);
								return (
									<ContractRow
										selected={licenseProvider && selectedProvider?.id == licenseProvider?.id}
										key={index}
										row={row}
										contract={contract}
										licenseProvider={licenseProvider}
										onClick={handleSelectProvider(licenseProvider)}
									/>
								);
							})}
							{loadingContract &&
								Array.from({ length: 2 }).map((_, index) => {
									return <LoadingContractRow key={index} />;
								})}
						</tbody>
					</Wrapper>
				</Layout.Section>
				{selectedProvider && (
					<Layout.Section secondary>
						<ContractLicensUsers licenseProvider={selectedProvider} onClose={handleSelectProvider(null)} />
					</Layout.Section>
				)}
			</Layout>

			<LicenseModal open={licensModalIsOpen} onClose={handleCloseModal} onComplete={refreshContract} />
		</Page>
	);
};

export default withTranslation(["licenses", "common"], { withRef: true })(ContractLicenses);
const Wrapper = styled.table`
	width: 100%;
	background-color: var(--main1);
	/* border-collapse: collapse; */
	border-spacing: 0 3px;

	th {
		padding: 0.625rem;
		text-align: left;
	}

	td {
		padding: 0.625rem;
	}
`;
