import React from "react";
import styled from "styled-components";

const RobotIcon = ({ color = "var(--textColor)" }) => (
	<SvgWrapper width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
		{/* <!-- Background (Optional) --> */}
		<rect width="200" height="200" fill="none" />

		{/* <!-- Robot Head --> */}
		<rect x="50" y="50" width="100" height="100" rx="15" ry="15" fill="rgb(20, 24, 48)" stroke="rgb(240, 240, 240)" strokeWidth="5" />

		{/* <!-- Left Antenna --> */}
		<line x1="70" y1="50" x2="60" y2="30" stroke="rgb(240, 240, 240)" strokeWidth="5" />
		<circle cx="60" cy="30" r="5" fill="#02f4c4" />

		{/* <!-- Right Antenna --> */}
		<line x1="130" y1="50" x2="140" y2="30" stroke="rgb(240, 240, 240)" strokeWidth="5" />
		<circle cx="140" cy="30" r="5" fill="#02f4c4" />

		{/* <!-- Eyes --> */}
		<rect x="75" y="80" width="15" height="15" fill="#02f4c4" />
		<rect x="110" y="80" width="15" height="15" fill="#02f4c4" />

		{/* <!-- Mouth (Speaker Grill) --> */}
		<rect x="80" y="115" width="40" height="10" fill="none" stroke="#02f4c4" strokeWidth="2" />
		<line x1="85" y1="115" x2="85" y2="125" stroke="#02f4c4" strokeWidth="2" />
		<line x1="95" y1="115" x2="95" y2="125" stroke="#02f4c4" strokeWidth="2" />
		<line x1="105" y1="115" x2="105" y2="125" stroke="#02f4c4" strokeWidth="2" />
		<line x1="115" y1="115" x2="115" y2="125" stroke="#02f4c4" strokeWidth="2" />

		{/* <!-- Side Bolts --> */}
		<circle cx="40" cy="100" r="5" fill="#02f4c4" />
		<circle cx="160" cy="100" r="5" fill="#02f4c4" />
	</SvgWrapper>
);

export default RobotIcon;

const SvgWrapper = styled.svg`
	transition: filter 300ms;
	&:hover {
		filter: drop-shadow(0 0 3px var(--textColor)) brightness(1.2);
	}
`;
