import React from "react";
import nl2br from "react-nl2br";
import ProfileAvatar from "../ProfileAvatar";
import styled from "styled-components";

type UserChatMessageProps = {
	message?: any;
	messageContentKey: string;
};

const UserChatMessage = ({ message, messageContentKey }: UserChatMessageProps) => {
	return (
		<ChatMessageWrapper>
			<ChatMessageInnerMainWrapper>
				<ChatMessageInnerWrapper>{(messageContentKey && nl2br(message?.[messageContentKey])) || ""}</ChatMessageInnerWrapper>
				<ProfileAvatar user={message.user} />
			</ChatMessageInnerMainWrapper>

			<Date>{message.created_at}</Date>
		</ChatMessageWrapper>
	);
};
export default UserChatMessage;

export const ChatMessageInnerWrapper = styled.div`
	padding: 1rem;
	width: max-content;
	border-radius: 0.65rem;

	background-color: var(--accent);
	color: var(--main1);

	max-width: 100%; /* Ensure it doesn't overflow the container */
	white-space: pre-wrap; /* Keep line breaks but wrap long content */
	word-wrap: break-word; /* Break long words if necessary */
`;
export const ChatMessageInnerMainWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	max-width: 75%;
`;
export const ChatMessageExtraWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;
export const Date = styled.div`
	color: var(--textColor2);
	opacity: 0.85;
	font-size: 0.7rem;
`;
export const ChatMessageCreatorWrapper = styled.div``;

export const ChatWrapper = styled.div``;
export const ChatMessageWrapper = styled.div`
	margin-block: 1rem;
	width: 100%;
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
	flex-direction: column;
`;
