import React, { useMemo } from "react";
import UserChatMessage from "./UserChatMessage";
import AssistantChatMessage from "./AssistantChatMessage";
import styled from "styled-components";

type ChatMessageProps = {
	message?: any;
	messageContentKey: string;
	onData?: onData;
};

const ChatMessage = React.memo(({ message, messageContentKey, onData }: ChatMessageProps) => {
	const content = useMemo(() => {
		switch (message.role) {
			case "user":
				return <UserChatMessage message={message} messageContentKey={messageContentKey} />;
			case "assistant":
				return <AssistantChatMessage message={message} messageContentKey={messageContentKey} onData={onData} />;
			case "system":
			default:
				return (messageContentKey && message?.[messageContentKey]) || "-";
		}
	}, [message, messageContentKey, onData]);

	return <Wrapper>{content}</Wrapper>;
});
export default ChatMessage;

const Wrapper = styled.div`
	width: inherit;
	max-width: inherit;

	padding-inline: var(--p-space-5);
`;
