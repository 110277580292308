/* eslint-disable */
import React, { Component } from "react";
import { fabric } from "fabric";
import CanvasButtonSidebar from "./CanvasButtonSidebar";
import { sleep } from "src/js/Utilities/Utilities";
import Colors, { colorToRgba } from "src/js/Colors";
import ErrorBoundary, { useErrorBoundary } from "src/js/components/ErrorBoundary";
import Button from "src/js/components/Button";
import defaultFallbackRender from "src/js/components/ErrorBoundary/defaultFallbackRender";

class Canvas extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
		this.rnd = Math.random();
	}

	componentDidMount() {
		const height = this.props.height;
		const width = this.props.width;

		this.canvas = new fabric.Canvas(`c${this.props.index}_${this.rnd}`, {
			height,
			width,
			backgroundColor: "#ffffff",
			preserveObjectStacking: true,
		});

		if (this.props.page && this.props.page.raw_data) {
			// Try to load the canvas from JSON data. If it fails, try again after a short delay.
			(async () => {
				const attempts = 3;
				for (let index = 0; index < attempts; index++) {
					try {
						await new Promise((resolve, reject) => {
							this.canvas.loadFromJSON(this.props.page.raw_data, () => {
								try {
									this.canvas.renderAll();
								} catch (error) {
									reject(error);
								}
								setTimeout(() => {
									this.updateCanvasLockedState();
									resolve();
								}, 0);
							});
						});
						return; // Exit after successful canvas load
					} catch (error) {
						console.error("Error loading canvas from JSON", error);

						//if last attempt, throw error
						if (index === attempts - 1) {
							this.props.showBoundary(error);
							return;
						}

						await sleep(1000); // Retry after delay
					}
				}
			})();

			// fabric.loadSVGFromString(String(this.props.page.rawData), (objects, options) => {
			// 	let obj = fabric.util.groupSVGElements(objects, options);
			// 	this.canvas.add(obj).centerObject(obj).renderAll();

			// 	obj.setCoords();
		}

		this.canvas.on("selection:created", () => {
			this.props.setActiveObject(this.canvas.getActiveObjects());
		});
		this.canvas.on("selection:updated", () => {
			this.props.setActiveObject(this.canvas.getActiveObjects());
		});
		this.canvas.on("selection:cleared", () => {
			this.props.setActiveObject(null);
		});

		this.initCenteringGuidelines(this.canvas);
		this.initAligningGuidelines(this.canvas);

		setTimeout(() => {
			if (this.ref.current) {
				this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
					root: document,
					threshold: 0.51,
				});

				this.observer.observe(this.ref.current);
			}

			// Update canvas interactive state based on props.disabled
			this.updateCanvasLockedState();
		}, 0);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.locked !== this.props.locked) {
			this.updateCanvasLockedState();
		}
	}

	updateCanvasLockedState() {
		if (this.props.locked) {
			this.canvas.selection = false;
			this.canvas.defaultCursor = "default";
			this.canvas.forEachObject((obj) => {
				obj.selectable = false;
				obj.evented = false;
				obj.hoverCursor = "default";
			});
		} else {
			this.canvas.selection = true;
			this.canvas.defaultCursor = "default";
			this.canvas.forEachObject((obj) => {
				obj.selectable = true;
				obj.evented = true;
				obj.hoverCursor = "move";
			});
		}
		this.canvas.renderAll();
	}

	componentWillUnmount() {
		if (this.observer) this.observer.disconnect();
	}

	handleIntersection(entries) {
		if (entries && entries[0].isIntersecting) {
			this.props.setActivePage();
		} else {
			// const actives = this.props.activeObjects?.filter((obj) => obj.canvas === this.canvas);
			this.props.setActiveObject(null);
			this.canvas.discardActiveObject().renderAll();
		}
	}

	// ORIGINAL:
	// https://github.com/fabricjs/fabric.js/blob/master/lib/centering_guidelines.js

	/**
	 * Augments canvas by assigning to `onObjectMove` and `onAfterRender`.
	 * This kind of sucks because other code using those methods will stop functioning.
	 * Need to fix it by replacing callbacks with pub/sub kind of subscription model.
	 * (or maybe use existing fabric.util.fire/observe (if it won't be too slow))
	 */
	initCenteringGuidelines(canvas) {
		const canvasWidth = canvas.getWidth();
		const canvasHeight = canvas.getHeight();
		const canvasWidthCenter = canvasWidth / 2;
		const canvasHeightCenter = canvasHeight / 2;
		const canvasWidthCenterMap = {};
		const canvasHeightCenterMap = {};
		const centerLineMargin = 4;
		const centerLineColor = "purple";
		const centerLineWidth = 2;
		const ctx = canvas.getSelectionContext();
		let viewportTransform;

		for (let i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
			canvasWidthCenterMap[Math.round(i)] = true;
		}
		for (let i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
			canvasHeightCenterMap[Math.round(i)] = true;
		}

		function showVerticalCenterLine() {
			showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
		}

		function showHorizontalCenterLine() {
			showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
		}

		function showCenterLine(x1, y1, x2, y2) {
			let originXY = fabric.util.transformPoint(new fabric.Point(x1, y1), canvas.viewportTransform);
			let dimmensions = fabric.util.transformPoint(new fabric.Point(x2, y2), canvas.viewportTransform);
			ctx.save();
			ctx.strokeStyle = centerLineColor;
			ctx.lineWidth = centerLineWidth;
			ctx.beginPath();

			ctx.moveTo(originXY.x, originXY.y);

			ctx.lineTo(dimmensions.x, dimmensions.y);
			ctx.stroke();
			ctx.restore();
		}

		const afterRenderActions = [];
		let isInVerticalCenter;
		let isInHorizontalCenter;

		canvas.on("mouse:down", () => {
			isInVerticalCenter = isInHorizontalCenter = null;
			this.centerLine_horizontal = "";
			this.centerLine_vertical = "";

			viewportTransform = canvas.viewportTransform;
		});

		canvas.on("object:moving", (e) => {
			const object = e.target;
			const objectCenter = object.getCenterPoint();
			const transform = canvas._currentTransform;

			if (!transform) return;

			(isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap),
				(isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap);

			if (isInHorizontalCenter || isInVerticalCenter) {
				object.setPositionByOrigin(
					new fabric.Point(isInVerticalCenter ? canvasWidthCenter : objectCenter.x, isInHorizontalCenter ? canvasHeightCenter : objectCenter.y),
					"center",
					"center"
				);
			}
		});

		canvas.on("before:render", () => {
			canvas.clearContext(canvas.contextTop);
		});

		canvas.on("after:render", () => {
			if (isInVerticalCenter) {
				showVerticalCenterLine();
				this.centerLine_horizontal = "";
				this.centerLine_vertical = canvasWidthCenter + 0.5 + ", " + 0 + ", " + (canvasWidthCenter + 0.5) + ", " + canvasHeight;
			}

			if (isInHorizontalCenter) {
				showHorizontalCenterLine();
				this.centerLine_horizontal = canvasWidthCenter + 0.5 + ", " + 0 + ", " + (canvasWidthCenter + 0.5) + ", " + canvasHeight;
				this.centerLine_vertical = "";
			}
		});

		canvas.on("mouse:up", () => {
			// clear these values, to stop drawing guidelines once mouse is up
			canvas.renderAll();
		});
	}

	// ORIGINAL:
	// https://github.com/fabricjs/fabric.js/blob/master/lib/aligning_guidelines.js

	// Original author:
	/**
	 * Should objects be aligned by a bounding box?
	 * [Bug] Scaled objects sometimes can not be aligned by edges
	 *
	 */
	initAligningGuidelines(canvas) {
		const ctx = canvas.getSelectionContext();
		const aligningLineOffset = 5;
		const aligningLineMargin = 4;
		const aligningLineWidth = 2;
		const aligningLineColor = "lightBlue";
		let viewportTransform;
		let zoom = null;
		const verticalLines = [];
		const horizontalLines = [];
		// const canvasContainer = document.getElementById(`c${this.props.index}`);
		// const containerWidth = canvasContainer.offsetWidth;
		// const containerHeight = canvasContainer.offsetHeight;

		function drawVerticalLine(coords) {
			drawLine(coords.x + 0.5, coords.y1 > coords.y2 ? coords.y2 : coords.y1, coords.x + 0.5, coords.y2 > coords.y1 ? coords.y2 : coords.y1);
		}

		function drawHorizontalLine(coords) {
			drawLine(coords.x1 > coords.x2 ? coords.x2 : coords.x1, coords.y + 0.5, coords.x2 > coords.x1 ? coords.x2 : coords.x1, coords.y + 0.5);
		}

		function drawLine(x1, y1, x2, y2) {
			let originXY = fabric.util.transformPoint(new fabric.Point(x1, y1), canvas.viewportTransform);
			let dimmensions = fabric.util.transformPoint(new fabric.Point(x2, y2), canvas.viewportTransform);
			ctx.save();
			ctx.lineWidth = aligningLineWidth;
			ctx.strokeStyle = aligningLineColor;
			ctx.beginPath();

			ctx.moveTo(originXY.x, originXY.y);

			ctx.lineTo(dimmensions.x, dimmensions.y);
			ctx.stroke();
			ctx.restore();
		}

		function isInRange(value1, value2) {
			value1 = Math.round(value1);
			value2 = Math.round(value2);
			for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
				if (i === value2) {
					return true;
				}
			}
			return false;
		}

		canvas.on("mouse:down", () => {
			verticalLines.length = horizontalLines.length = 0;
			viewportTransform = canvas.viewportTransform;
			zoom = canvas.getZoom();
		});

		canvas.on("object:moving", (e) => {
			verticalLines.length = horizontalLines.length = 0;

			const activeObject = e.target;
			const canvasObjects = canvas.getObjects();
			const activeObjectCenter = activeObject.getCenterPoint();
			const activeObjectLeft = activeObjectCenter.x;
			const activeObjectTop = activeObjectCenter.y;
			const activeObjectBoundingRect = activeObject.getBoundingRect();
			const activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3];
			const activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0];
			let horizontalInTheRange = false;
			let verticalInTheRange = false;
			const transform = canvas._currentTransform;

			if (!transform) return;

			// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
			// but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

			for (let i = canvasObjects.length; i--; ) {
				if (canvasObjects[i] === activeObject) continue;
				if (!canvasObjects[i]) continue;
				if (!activeObject) continue;

				const objectCenter = canvasObjects[i].getCenterPoint();
				const objectLeft = objectCenter.x;
				const objectTop = objectCenter.y;
				const objectBoundingRect = canvasObjects[i].getBoundingRect();
				const objectHeight = objectBoundingRect.height / viewportTransform[3];
				const objectWidth = objectBoundingRect.width / viewportTransform[0];

				// snap by the horizontal center line
				if (isInRange(objectLeft, activeObjectLeft)) {
					verticalInTheRange = true;
					verticalLines.push({
						x: objectLeft,
						y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
						y2:
							activeObjectTop > objectTop
								? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
								: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), "center", "center");
				}

				// snap by the left edge
				if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
					verticalInTheRange = true;
					verticalLines.push({
						x: objectLeft - objectWidth / 2,
						y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
						y2:
							activeObjectTop > objectTop
								? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
								: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(
						new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop),
						"center",
						"center"
					);
				}

				// snap by the right edge
				if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
					verticalInTheRange = true;
					verticalLines.push({
						x: objectLeft + objectWidth / 2,
						y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
						y2:
							activeObjectTop > objectTop
								? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
								: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(
						new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop),
						"center",
						"center"
					);
				}

				// snap by the vertical center line
				if (isInRange(objectTop, activeObjectTop)) {
					horizontalInTheRange = true;
					horizontalLines.push({
						y: objectTop,
						x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
						x2:
							activeObjectLeft > objectLeft
								? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
								: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), "center", "center");
				}

				// snap by the top edge
				if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
					horizontalInTheRange = true;
					horizontalLines.push({
						y: objectTop - objectHeight / 2,
						x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
						x2:
							activeObjectLeft > objectLeft
								? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
								: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(
						new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2),
						"center",
						"center"
					);
				}

				// snap by the bottom edge
				if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
					horizontalInTheRange = true;
					horizontalLines.push({
						y: objectTop + objectHeight / 2,
						x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
						x2:
							activeObjectLeft > objectLeft
								? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
								: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
					});
					activeObject.setPositionByOrigin(
						new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2),
						"center",
						"center"
					);
				}
			}

			if (!horizontalInTheRange) {
				horizontalLines.length = 0;
			}

			if (!verticalInTheRange) {
				verticalLines.length = 0;
			}
		});

		canvas.on("mouse:wheel", (opt) => {
			verticalLines.length = horizontalLines.length = 0;
		});

		canvas.on("before:render", () => {
			canvas.clearContext(canvas.contextTop);
		});

		canvas.on("after:render", () => {
			for (let i = verticalLines.length; i--; ) {
				drawVerticalLine(verticalLines[i]);
			}
			for (let i = horizontalLines.length; i--; ) {
				drawHorizontalLine(horizontalLines[i]);
			}

			this.alignmentLines_horizontal = JSON.stringify(horizontalLines, null, 4);
			this.alignmentLines_vertical = JSON.stringify(verticalLines, null, 4);

			canvas.calcOffset();
		});

		canvas.on("mouse:up", () => {
			canvas.renderAll();
		});
	}

	render() {
		// clearTimeout(this.timeer);

		// this.timeer = setTimeout(() => {
		// 	this.setState({ showError: true });
		// }, 2500);

		// if (this.state.showError) {
		// 	throw new Error("Test error");
		// }

		return (
			<div ref={this.ref}>
				<span>Sida: {this.props.index + 1}</span>
				<div className="canvas-container-wrapper">
					<canvas id={`c${this.props.index}_${this.rnd}`} />
				</div>
				<CanvasButtonSidebar
					removePage={this.props.removePage}
					clearCanvas={this.props.clearCanvas}
					duplicatePage={this.props.duplicatePage}
					movePageUp={this.props.movePageUp}
					movePageDown={this.props.movePageDown}
				/>
			</div>
		);
	}
}

const CanvasWrapper = (props) => {
	const { showBoundary } = useErrorBoundary();
	return <Canvas {...props} showBoundary={showBoundary} />;
};

export default (props) => {
	return (
		<div className="canvas-wrapper" id={`cw${props.index}`} onClick={props.setActivePage}>
			<ErrorBoundary
				fallbackRender={(fallbackRenderProps) => {
					return defaultFallbackRender({
						...fallbackRenderProps,
						style: {
							height: props.height,
							width: props.width,
						},
					});
				}}
			>
				<CanvasWrapper {...props} />
			</ErrorBoundary>
		</div>
	);
};
