import React from "react";
import { Wrapper } from "./styles";
import { Collapsible, EmptyState } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import InfoView from "src/js/components/InfoView";
import Button from "src/js/components/Button";
import styled from "styled-components";
import DOMPurify from "dompurify";

const MissingPermission = ({ t, history, location, ...rest }: any) => {
	const [open, setOpen] = React.useState(false);

	const searchParams = new URLSearchParams(window.location.search);
	const data = location?.state?.data;

	const error = data?.data;
	const message = searchParams.get("message") || data?.message || error?.message;

	const { url, method, params } = data?.config || {};

	return (
		<Page>
			<Wrapper>
				<EmptyState heading={t("routes.permissions.missing.title", "Åtkomst nekad")} image="/assets/emptystate-files.png">
					{/* <p>
						{message
							? nl2br(message)
							: t(
									"routes.permissions.missing.description",
									"Du saknar behörighet för att visa denna sida. Vänligen kontakta administratören för att ändra dina behörigheter."
							  )}
					</p> */}
					<p>
						{/* render the message that contains html */}
						{message ? (
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
						) : (
							t(
								"routes.permissions.missing.description",
								"Du saknar behörighet för att visa denna sida. Vänligen kontakta administratören för att ändra dina behörigheter."
							)
						)}
					</p>

					<ButtonWrapper>
						<Button plain onClick={() => setOpen(!open)}>
							{t("routes.permissions.missing.show_more", "Visa mer information")}
						</Button>
					</ButtonWrapper>

					<Collapsible id="403" open={open}>
						<InfoView
							style={{ marginTop: "1rem" }}
							details={[
								{
									label: t("routes.403.info.url.label", "URL"),
									value: url,
								},
								{
									label: t("routes.403.info.method.label", "Metod"),
									value: method,
								},
								{
									label: t("routes.403.info.params.label", "Parametrar"),
									value: (
										<pre
											style={{
												display: "flex",
												margin: 0,
												padding: "2px",
												background: "var(--main3)",
												borderRadius: "6px",
												fontSize: "12px",
											}}
										>
											{JSON.stringify(params, null, 2)}
										</pre>
									),
								},
							]}
						/>
					</Collapsible>
				</EmptyState>
			</Wrapper>
		</Page>
	);
};
export default withTranslation(["routes", "common"])(MissingPermission);

const ButtonWrapper = styled.div`
	margin-top: 1rem;
	opacity: 0.75;

	.Polaris-Button__Content {
		font-size: 12px;
	}
`;
