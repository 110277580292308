import React from "react";
import useContact from "src/js/hooks/useContact";
import Detailsview from "src/js/components/Detailsview";
import { LegacyCard } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import CustomCard from "src/js/components/CustomCard";

type AxiconInfoProps = {
	contact?: ContactType | Partial<ContactType>;
};

const AxiconInfo = ({ contact: propsContact }: AxiconInfoProps) => {
	const { id }: any = useParams();
	const { contact, axiconData, t } = useContact(id, propsContact);
	if (!contact?.is_company) return null;

	return (
		<>
			<CustomCard title={t("contact.axicon.fields.legal_text.label", "Firmatecknare")} sectioned>
				<LegacyCard.Section>{axiconData?.legal_text}</LegacyCard.Section>
			</CustomCard>

			<CustomCard collapsible title={t("contact.axicon.title", "Övrig info")} sectioned>
				<LegacyCard.Section>
					<Detailsview
						items={[
							{
								label: t("contact.axicon.fields.established.label", "Skapades"),
								value: axiconData?.established,
							},

							{
								label: t("contact.axicon.fields.type.label", "Typ"),
								value: axiconData?.type,
							},
						]}
					/>
				</LegacyCard.Section>

				<LegacyCard.Section>
					<Detailsview
						items={[
							{
								label: t("contact.axicon.fields.taxes.vat.label", "Momsregistrerad"),
								value: axiconData?.taxes?.vat ? t("common.terms.yes", "Ja") : t("common.terms.no", "Nej"),
							},
							{
								label: t("contact.axicon.fields.taxes.ftax.label", "F-skatt"),
								value: axiconData?.taxes?.ftax ? t("common.terms.yes", "Ja") : t("common.terms.no", "Nej"),
							},
							{
								label: t("contact.axicon.fields.taxes.vat_number.label", "Momsnummer"),
								value: axiconData?.taxes?.vat_number,
							},
						]}
					/>
				</LegacyCard.Section>

				<LegacyCard.Section title={t("contact.axicon.fields.pohone_numbers.label", "Telefonnummer")}>
					{axiconData?.phone_numbers?.length ? (
						<Detailsview
							items={axiconData?.phone_numbers.map((phone) => ({
								label: "",
								value: phone.phone_number,
								type: "phone",
							}))}
						/>
					) : (
						t("contact.axicon.fields.pohone_numbers.error", "Inga telefonnummer")
					)}
				</LegacyCard.Section>

				{/* <LegacyCard.Section title={t("contact.axicon.fields.links.label", "Länkar")}>
					{axiconData?.links?.length ? (
						<Detailsview
							items={axiconData?.links.map((phone) => ({
								label: "",
								value: phone.phone_number,
								type: "phone",
							}))}
						/>
					) : (
						t("contact.axicon.fields.pohone_numbers.error", "Inga Länkar")
					)}
				</LegacyCard.Section> */}

				<LegacyCard.Section title={t("contact.axicon.fields.links.label", "Länkar")}>
					<Detailsview
						items={axiconData?.links?.map((link) => ({
							label: link.label,
							value: link.url,
						}))}
					/>
				</LegacyCard.Section>

				<LegacyCard.Section title={t("contact.axicon.fields.sni_codes.label", "Sni kod")}>
					<Detailsview
						items={axiconData?.sni_codes.map((c) => ({
							label: "",
							value: `${c.title}(${c.code})`,
						}))}
					/>
				</LegacyCard.Section>
			</CustomCard>
		</>
	);
};
export default AxiconInfo;
