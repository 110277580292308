import React, { useState, useRef } from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { Link } from "react-router-dom";
import HomeIcon from "../icons/Home";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import IntersectionObserverElement from "./IntersectionObserverElement";
import Spinner from "./Spinner";
import CollapsibleSection from "./CollapsibleSection/CollapsibleSection";
import { checkUserRoles } from "../Utilities";

const MainMenuSection = (props) => {
	const {
		items,
		sections,
		title,
		actions,
		children,
		id,
		secondaryItemsStyle,
		loading,
		className,
		url,
		icon,
		onClick,
		toggleMenu,
		history,
		location,
	} = props;

	const [search, setSearch] = useState("");
	const ref = useRef<any>(null);
	const secondaryRef = useRef(null);

	const getTriggerIcon = () => {
		if (!icon) return <HomeIcon />;
		if (React.isValidElement(icon)) return icon;
		return <Icon source={icon} />;
	};

	const getIcon = (iconProp) => {
		if (!iconProp) return null;
		if (React.isValidElement(iconProp)) return iconProp;
		return <Icon source={iconProp} />;
	};

	const handleClick = (itemUrl, itemOnClick, closeOnClick) => {
		if (itemOnClick) itemOnClick();
		if (closeOnClick && toggleMenu) {
			toggleMenu();
		}
		if (itemUrl) history.push(itemUrl);
	};

	const onSearchChange = (value) => {
		setSearch(value);
	};

	const renderItem = (item, index) => {
		const { active, label, icon: itemIcon, url: itemUrl, activeUrl, onClick: itemOnClick, closeOnClick, description, tooltip = description } = item;
		const isActive = active || ((activeUrl || itemUrl) && location && location.pathname.includes(activeUrl || itemUrl)) ? "active" : "";

		return (
			<div className={`mainmenu-item__secondary__item ${isActive}`} onClick={() => handleClick(itemUrl, itemOnClick, closeOnClick)} key={index}>
				{tooltip ? (
					<Tooltip content={tooltip}>
						<div className="mainmenu-item__secondary__item_inner">
							{getIcon(itemIcon)}
							{label}
						</div>
					</Tooltip>
				) : (
					<div className="mainmenu-item__secondary__item_inner">
						{getIcon(itemIcon)}
						{label}
					</div>
				)}
			</div>
		);
	};

	const renderSection = (section, index) => {
		const { title, items } = section;
		const { collapsible = true, icon, haveDivider = true } = section;

		return (
			<CollapsibleSection
				collapsible={collapsible}
				key={index}
				className={`mainmenu-item__secondary__section ${haveDivider ? "haveDivider" : ""}`}
				activatorClassName={`mainmenu-item__secondary__section_title`}
				title={
					(icon || title) && (
						<div style={{ display: "flex", gap: "0.65rem" }}>
							{getIcon(icon)}
							{title}
						</div>
					)
				}
				open
			>
				{items.map(renderItem)}
			</CollapsibleSection>
		);
	};

	const filteredItems = items?.filter((item) => item.label?.toLowerCase()?.includes((search || "").toLowerCase())) || [];
	const filteredSections =
		sections?.reduce((acc, section) => {
			const searchRegex = new RegExp(search || "", "i"); // Create case-insensitive regex

			const items =
				section.items?.filter((item) => searchRegex.test(item.label || "") && (!("enabled" in item) || item.enabled) && checkUserRoles(item.roles)) ||
				[];

			if (items.length || (search && searchRegex.test(section.title || ""))) {
				acc.push({
					...section,
					items,
				});
			}

			return acc;
		}, []) || [];

	return (
		<div ref={ref} id={id} className={`mainmenu-item ${className || ""}`}>
			{url ? (
				<Link to={url} className="mainmenu-item-trigger" data-content={title} onClick={onClick} style={{}}>
					{getTriggerIcon()}
					<span className="mainmenu-item-trigger_title">{title}</span>
				</Link>
			) : (
				<span className="mainmenu-item-trigger" data-content={title} onClick={onClick} style={{}}>
					{getTriggerIcon()}
					<span className="mainmenu-item-trigger_title">{title}</span>
				</span>
			)}
			<div ref={secondaryRef} className="mainmenu-item__secondary noscroll">
				<div className="mainmenu-item__secondary__header">
					<span className="mainmenu-item__secondary__header__title">
						{title} {loading && <Spinner size="small" />}
					</span>
					<div className="mainmenu-item__secondary__actions" />
				</div>
				{(items || sections || (actions && actions.length > 0)) && (
					<MainMenuSectionSearch onChange={onSearchChange} value={search} actions={actions} />
				)}
				<div className="mainmenu-item__secondary__items" style={secondaryItemsStyle}>
					{children}

					{filteredSections.map(renderSection)}
					{filteredItems.map(renderItem)}
					<IntersectionObserverElement
						parent={secondaryRef.current}
						hideShadow
						onChange={(intersecting) => {
							const shadow = document.querySelector(`#${id}-shadow`);
							if (shadow) {
								if (intersecting) {
									shadow.classList.remove("visible");
									shadow.classList.add("invisible");
								} else if (ref.current!.classList.contains("active")) {
									shadow.classList.remove("invisible");
									shadow.classList.add("visible");
								}
							}
						}}
						shadowStyle={{ width: ref.current?.clientWidth }}
					/>
				</div>
			</div>
			<div id={`${id}-shadow`} className="bottom-scroll-shadow" />
		</div>
	);
};

export default MainMenuSection;
