import {
	IndexTable as PolarisIndexTable,
	LegacyCard,
	useIndexResourceState,
	Pagination,
	Spinner,
	EmptyState,
	HorizontalStack,
	useEventListener,
} from "@shopify/polaris";
import type { ComplexAction, PageProps, IndexFiltersProps as PolarisIndexFiltersProps } from "@shopify/polaris";
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import httpBuildQuery from "http-build-query";
import { debounce, uniqBy } from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";
import { colorToRgba } from "src/js/Colors";
import { store } from "../../store";
import API from "../../API";
import IndexTableRow from "./IndexTableRow";
import IndexFilters from "./IndexFilters";
import { columnsToHeadings, columnsToSortOptions, generateTotalRow, getDirectionFromSort, getHandleFromSort, getSearchParams } from "./Utilities";

import type { AppliedFilters, BulkActions, Filters, IndexTableColumn, PromotedBulkActions, SavedSearch, SortValue } from "./types";
import StatsBoxWrapper from "../statsBoxes";
import IconWithBackround from "../IconWithBackround";
import LimitField from "./LimitField";
import Page from "../page";
import useQuery from "src/js/hooks/useQuery";
import getDefaultFilters from "src/js/components/IndexTable/getDefaultFilters";
import { ItemCardWrapper } from "../ItemCard/styles";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import Toast from "src/js/components/Toast";
import { StatsBoxItem } from "../statsBoxes/types";

type IndexTableProps = {
	columns: IndexTableColumn[];
	resourceUrl: string;
	resourceHandle: string;
	filterKey?: string;
	refreshKey?: string;
	params?: any;
	resourceName: { singular: string; plural: string };
	renderCell?: (row: any, column: any) => any;
	onUpdateParams?: (params: any) => void;
	onItemsFetched?: (items: any[]) => void;
	onResultFetched?: (items: any[]) => void;
	onClickRow?: (row: any) => void;
	onClick?: (row: any) => void;
	filters?: Filters;
	noHeader?: boolean;
	selector?: any;
	savedSearchHandle?: string;
	defaultSavedSearches?: SavedSearch[];
	limit?: number;
	noPagination?: boolean;
	history?: any; // DEPRECATED IN FAVOR OF USEHISTORY HOOK, BUT keeping this so i dont have to change all other files.
	backAction?: PageProps["backAction"];
	title?: PageProps["title"];
	primaryAction?: PageProps["primaryAction"];
	inlinePrimaryAction?: PageProps["primaryAction"];
	secondaryActions?: PageProps["secondaryActions"]; // MenuActionDescriptor[] | React.ReactNode;
	actionGroups?: PageProps["actionGroups"];
	noTopPagination?: boolean;
	extraHeader?: any;
	footer?: any;
	extraFooter?: any;
	onFiltersChange?: (filters: any[]) => void;
	defaultSort?: string;
	emptyState?: any;
	promotedBulkActions?: PromotedBulkActions;
	bulkActions?: BulkActions;
	defaultFilters?: any[];
	additionalMetadata?: PageProps["additionalMetadata"];
	titleMetadata?: PageProps["titleMetadata"];
	subtitle?: PageProps["subtitle"];
	onSelectionChange?: (selection: any[]) => void;
	onSelectionChangeInclRow?: (selection: any[]) => void;
	items?: any[];
	setRefreshHandler?: (refresh: any) => any;
	showTotal?: boolean;
	image?: string;
	loading?: boolean;
	stats?: StatsBoxItem[];
	titleHidden?: boolean;
	selectOnRow?: boolean;
	selectable?: boolean;
	idHandle?: string;

	itemPadding?: string | number;
	additionalAction?: any;

	urlParamsEnabled?: boolean;
	tabsEnabled?: boolean;
	searchFiltersEnabled?: boolean;

	filterPrimaryAction?: PolarisIndexFiltersProps["primaryAction"];
	inline?: boolean;
	enabled?: boolean;
	defaultSelectedTab?: string;

	sortOptions?: {
		label: string;
		value: `${string}_${"ASC" | "DESC"}`;
		directionLabel: string;
	}[];

	ref?: any;
};

const IndexTable = ({
	columns,
	resourceUrl,
	onItemsFetched,
	onResultFetched,
	resourceHandle,
	filterKey = resourceHandle,
	refreshKey = filterKey,
	params: propsParams = {},
	resourceName,
	renderCell,
	onUpdateParams,
	promotedBulkActions = [],
	bulkActions = [],
	emptyState,
	onClickRow,
	onClick,
	filters = [],
	noHeader,
	savedSearchHandle = resourceHandle,
	defaultSavedSearches,
	limit: propsLimit = 50,
	noPagination,
	backAction,
	title,
	primaryAction,
	secondaryActions,
	actionGroups,
	noTopPagination,
	extraHeader,
	footer,
	extraFooter,
	onFiltersChange,
	defaultSort: propsDefaultSort,
	defaultFilters,
	additionalMetadata,
	titleMetadata,
	subtitle,
	onSelectionChange,
	onSelectionChangeInclRow,
	items: propsItems,
	setRefreshHandler,
	loading: propsLoading,
	stats,
	// extra componen to ther right of the filters/search
	selector,
	// show a bottom row with the totals of each column (only works with number) EXPERIMENTAL
	showTotal,
	// image for the empty state
	image = "/assets/images/empty_state/NoResults.png",
	selectOnRow, // if true, you can select a row by clicking on it
	selectable = !!(promotedBulkActions?.length || bulkActions?.length || onSelectionChange || onSelectionChangeInclRow || selectOnRow),
	idHandle: propsIdHandle = "id",
	itemPadding,
	filterPrimaryAction,

	urlParamsEnabled = true,
	tabsEnabled = true,
	searchFiltersEnabled = true,
	enabled,

	inline = false,
	inlinePrimaryAction,
	// inlinePrimaryAction = inline ? primaryAction : undefined,
	titleHidden = !!inline,
	// additionalAction: propsAdditionalAction,
	additionalAction: propsAdditionalAction = inline ? primaryAction : undefined,
	defaultSelectedTab = "all",
	sortOptions,
	ref,
}: IndexTableProps) => {
	const getDefaultSortValue = (): string => {
		if (propsDefaultSort) return propsDefaultSort;

		return `${String(columns.find((column) => column.sortable)?.handle || "ID")}_${"DESC"}`;
	};

	const defaultSortValue: string = getDefaultSortValue();
	const history: any = useHistory();
	const params = getSearchParams(history.location.search, { defaultSortValue, urlParamsEnabled });
	// const [items, setItems] = useState<any[]>(propsItems || []);
	// const [count, setCount] = useState<number>(0);
	const [limit, setLimit] = useState<number>(propsLimit);
	// const [loading, setLoading] = useState<boolean>(Boolean(resourceUrl));
	const [searchValue, setSearchValue] = useState<string>(params?.searchValue || "");

	const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>(
		(() => {
			if (params?.appliedFilters?.length) return params?.appliedFilters;
			if (defaultSelectedTab && defaultSavedSearches?.find((s, index) => s.id === defaultSelectedTab))
				return defaultSavedSearches?.find((s, index) => s.id === defaultSelectedTab)?.filters;

			return defaultFilters;
		})() || []
	);
	const [sortValue, setSortValue] = useState<SortValue>(params?.sortValue || "");
	const [offset, setOffset] = useState<number>(params?.offset || 0);
	const [count, setCount] = useState<number>(0);
	const controller = useRef(new AbortController());
	const indexFiltersRef = useRef<any>(null);
	const idHandle = propsIdHandle || "id";
	const onClickFunc = onClick || onClickRow;
	const [promotedBulkActionsLoadingState, setPromotedBulkActionsLoadingState] = useState<any>({});
	const [bulkActionsLoadingState, setbulkActionsLoadingState] = useState<any>({});

	const handlePromotedBulkAction = (index: number, isLoading) => {
		setPromotedBulkActionsLoadingState((prev) => ({
			...prev,
			[index]: isLoading,
		}));
	};

	const handleBulkAction = (index: number, isLoading) => {
		setbulkActionsLoadingState((prev) => ({
			...prev,
			[index]: isLoading,
		}));
	};

	// useDeepCompareEffect(() => {
	// 	if (propsItems) setItems(propsItems || []);

	// 	return () => {};
	// }, [propsItems]);

	// const updateFromUrlParams = useCallback(() => {
	// 	const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(history.location.search, { defaultSortValue });

	// 	setOffset(offset);
	// 	setSortValue(sortValue);
	// 	setSearchValue(searchValue);
	// 	setAppliedFilters(appliedFilters);
	// }, []);

	// useEffect(() => {
	// 	updateFromUrlParams();
	// }, [updateFromUrlParams]);

	const fetchParams = useMemo(
		() => ({
			...(propsParams || {}),
			...(appliedFilters?.reduce((acc: any, { key, value }) => ({ ...acc, [key]: value }), {}) || {}),
			limit,
			q: searchValue,
			offset,
			sort: sortValue,
		}),
		[offset, appliedFilters, searchValue, sortValue, limit, propsParams]
	);

	const handleFetch = useCallback(async () => {
		controller.current?.abort();
		controller.current = new AbortController();

		if (onUpdateParams) onUpdateParams(Object.assign({}, fetchParams));

		if (!resourceUrl) return;

		// setLoading(true);

		return await API.get(resourceUrl, { params: fetchParams, signal: controller.current.signal })
			.then((result) => {
				if (onItemsFetched) {
					onItemsFetched(result.data[resourceHandle]);
				}
				if (onResultFetched) {
					onResultFetched(result.data);
				}

				return result.data;
			})
			.catch((error) => {
				Toast.error(error);
			});
	}, [resourceUrl, resourceHandle, onUpdateParams, onItemsFetched, onResultFetched, fetchParams]);

	const queryKeyParams = { ...fetchParams };
	// delete queryKeyParams.limit; //Why did we do this? If we do this the query will not refetch when the limit changes, example when we change the limit in the limit field in the footer
	const queryKey = [resourceUrl, queryKeyParams];
	const {
		data,
		isFetching: loading,
		refetch,
	} = useQuery({
		queryKey,
		queryFn: handleFetch,
		enabled: !propsItems && enabled,
		initialData: propsItems || null,
		placeholderData: (previousData) => previousData, // This is used for so when changing filter or serach, the list doesnt get emppty before thr new results. Especially when changing limit
		onSuccess: (data) => {
			const items = data?.[resourceHandle] || [];
			setCount(data?.count || items?.length || 0);
		},
	});

	useEventListener(`indexTable_refresh_${refreshKey}` as any, refetch);

	const items =
		propsItems?.filter((i) => {
			const search = new RegExp(searchValue, "i");
			return search.test(i.title) || search.test(i.content) || search.test(i.name);
		}) ||
		data?.[resourceHandle] ||
		[];

	const onSortChange = (sort: SortValue) => {
		setSortValue(sort);
		setOffset(0);
		updateSearch(appliedFilters, searchValue, sort, 0);
	};

	const changeTab = (tab: any) => {
		setAppliedFilters(tab.filters);
		setSearchValue(tab.search);
		setOffset(0);
		clearSelection();

		// this.setState({ appliedFilters: tab.filters, searchValue: tab.search, offset: 0 });
		updateSearch(tab.filters, tab.search, sortValue, 0);
	};

	const updateSearch = useCallback(
		(filters: AppliedFilters, search: string, sorting: SortValue, offset: number) => {
			const params = {
				offset,
				...(filters?.reduce((acc: any, { key, value }) => ({ ...acc, [key]: value }), {}) || {}),
				q: search,
				sort: sorting,
			};

			if (onUpdateParams) onUpdateParams(Object.assign({}, params));

			if (urlParamsEnabled) history.replace(history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
			else {
				const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(httpBuildQuery(params).replace(/\+/g, "%20"), {
					defaultSortValue,
				});
				setSearchValue(searchValue);
				setAppliedFilters(appliedFilters);
				setSortValue(sortValue);
				setOffset(offset);
			}
		},
		[history, onUpdateParams, urlParamsEnabled, defaultSortValue]
	);

	const handleSearch = useMemo(
		() =>
			debounce(
				(searchValue) => {
					// Clear filters and select first tab ("alla") to search through all tabs
					// setAppliedFilters([]); //Not sure if we want this?

					setOffset(0);
					// indexFiltersRef.current?.setSelectedTabIndex(0);

					updateSearch([], searchValue, sortValue, 0);
				},
				100,
				{ maxWait: 1000, leading: false, trailing: true }
			),
		[sortValue, updateSearch]
	);

	const handleSearchChange = (searchValue: string) => {
		setSearchValue(searchValue);
		setOffset(0);
		handleSearch(searchValue);
	};

	const onChangeFilters = (appliedFilters: AppliedFilters) => {
		setAppliedFilters(appliedFilters);
		setOffset(0);

		if (onFiltersChange) {
			onFiltersChange(appliedFilters);
		}
		updateSearch(appliedFilters, searchValue, sortValue, 0);
		clearSelection();
	};

	const handleFiltersClearAll = () => {
		setAppliedFilters([]);
		setOffset(0);
		setSearchValue("");

		if (onFiltersChange) {
			onFiltersChange(appliedFilters);
		}

		updateSearch([], "", sortValue, 0);
	};

	const goPrevious = () => {
		const nextOffset = offset - limit;

		if (offset > 0) {
			setOffset(nextOffset);
			updateSearch(appliedFilters, searchValue, sortValue, nextOffset);
		}
	};

	const goNext = () => {
		const nextOffset = offset + limit;
		if (nextOffset < count) {
			setOffset(nextOffset);

			updateSearch(appliedFilters, searchValue, sortValue, nextOffset);
		}
	};

	const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items);

	useDeepCompareEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selectedResources);
		}
		if (onSelectionChangeInclRow) {
			onSelectionChangeInclRow(items.filter((item) => selectedResources.includes(item?.[idHandle])));
		}
	}, [selectedResources]);

	useDeepCompareEffect(() => {
		// const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(history.location.search, { defaultSortValue });
		if (setRefreshHandler) setRefreshHandler(handleFetch);

		// handleFetch(appliedFilters, searchValue, sortValue, offset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resourceUrl, history.location.search, limit]);

	useImperativeHandle(ref, () => ({
		getItems: () => {
			return items;
		},
		// setItems: (items: any[]) => {
		// 	return setItems(items);
		// },
		clearSelection: () => {
			clearSelection();
		},
		getSelection: () => {
			return selectedResources;
		},
		getSelectedItems: () => {
			return selectedResources;
		},
		getParams: () => {
			return fetchParams;
		},
		getCount: () => {
			return count;
		},
		refetch: (resetOffset = true) => {
			if (resetOffset && fetchParams.offset !== 0) setOffset(0);
			else refetch();
		},
		reload: (resetOffset = true) => {
			if (resetOffset && fetchParams.offset !== 0) setOffset(0);
			else refetch();
		},
		refresh: (resetOffset = true) => {
			if (resetOffset && fetchParams.offset !== 0) setOffset(0);
			else refetch();
		},
		// handleFetch: ({ appliedFilters = [], searchValue = "", sortValue = "", offset = 0 }) =>
		// 	handleFetch(appliedFilters, searchValue, sortValue, offset),
		onChangeFilters: (appliedFilters = []) => onChangeFilters(appliedFilters),
	}));

	const handleOnClick = (item: any) => {
		const onClickFunc = onClick || onClickRow;
		if (onClickFunc && !selectOnRow) {
			onClickFunc(item);
			return;
		}
		handleSelectionChange("single" as any, !selectedResources?.includes(item?.[idHandle]), item?.[idHandle]);
	};

	// const handleManuallyToggleSelect = (item) => {
	// 	console.log("item.id:", item.id);
	// 	console.log("selectedResources:", selectedResources);
	// 	console.log("!selectedResources?.includes(item.id):", !selectedResources?.includes(item.id));
	// 	handleSelectionChange("single" as any, !selectedResources?.includes(item.id), item.id);
	// };

	const rowMarkup = items?.map((item, index) => {
		const id = item?.[idHandle];
		return (
			<IndexTableRow
				key={id}
				selectedResources={selectedResources}
				// toggleSelect={handleManuallyToggleSelect}
				columns={columns}
				item={item}
				renderCell={renderCell}
				index={index}
				id={id}
				onClick={handleOnClick}
			/>
		);
	});

	const hasPrevious = offset > 0;
	const hasNext = offset + limit < count;

	const allFilters = uniqBy([...(filters || []), ...getDefaultFilters(filterKey || "")], "key");

	const filterComponent = !noHeader && (
		<IndexFilters
			ref={indexFiltersRef}
			sortOptions={sortOptions || columnsToSortOptions(columns, propsDefaultSort)}
			resource={savedSearchHandle}
			resourceName={resourceName}
			defaultSavedSearches={defaultSavedSearches}
			onChange={changeTab}
			filters={allFilters?.map((filter) => ({
				...filter,
				disabled: Object.entries(propsParams)?.find(([key, value]) => key === filter.key && value),
			}))}
			appliedFilters={appliedFilters}
			onChangeFilters={onChangeFilters}
			search={searchValue}
			onSearchChange={handleSearchChange}
			loading={loading}
			onSortChange={onSortChange}
			sortValue={sortValue}
			defaultFilters={defaultFilters}
			handleFiltersClearAll={handleFiltersClearAll}
			selected={history?.location?.state?.tab}
			selectedId={defaultSelectedTab}
			tabsEnabled={tabsEnabled}
			searchFiltersEnabled={searchFiltersEnabled}
			primaryAction={filterPrimaryAction}
		/>
	);

	const extendedPromotedBulkActions = promotedBulkActions.map((action, index) => {
		return {
			...action,
			content:
				action.loading || promotedBulkActionsLoadingState[index] ? (
					<HorizontalStack gap="1" align="center">
						{action.content} <Spinner size="small" />
					</HorizontalStack>
				) : (
					action.content
				),
			onAction: action.onAction
				? async () => {
						handlePromotedBulkAction(index, true);
						await action?.onAction?.(selectedResources);
						handlePromotedBulkAction(index, false);
				  }
				: null,
		};
	});

	const extendedBulkActions = bulkActions.map((action, index) => {
		return {
			...action,
			content:
				action.loading || bulkActionsLoadingState[index] ? (
					<HorizontalStack gap="1" align="center">
						{action.content} <Spinner size="small" />
					</HorizontalStack>
				) : (
					action.content
				),
			onAction: action.onAction
				? async () => {
						handleBulkAction(index, true);

						await action.onAction?.(selectedResources);
						handleBulkAction(index, false);
				  }
				: null,
		};
	});

	const statsHeader = stats && (
		<StatsBoxWrapper
			style={{ justifyContent: "flex-start" }}
			perRow="auto-fit"
			items={stats?.map((stat) => ({
				title: stat.title,
				loading: stat.loading,
				text: stat.text,
				helpText: stat.helpText,
				icon: stat.icon && (
					<IconWithBackround icon={React.cloneElement(stat.icon as any, { color: stat.color })} backgroundColor={colorToRgba(stat.color, 0.2)} />
				),
			}))}
		/>
	);

	const additionalAction = (() => {
		if (!propsAdditionalAction) return null;
		if (typeof propsAdditionalAction === "function") return propsAdditionalAction();
		if (React.isValidElement(propsAdditionalAction)) return propsAdditionalAction;

		const { content, onAction, styles, ...rest } = propsAdditionalAction || {};

		return (
			<div style={{ paddingInline: "8px", ...(styles || {}) }}>
				<Button {...rest} onClick={onAction}>
					{content}
				</Button>
			</div>
		);
	})();

	return (
		<Wrapper
			data-loading={loading}
			data-clickable={!!onClickFunc}
			data-selectable={!!selectable}
			showTablewHeader={columns?.some((c) => c.label) || selectable}
			itemPadding={itemPadding}
			titleHidden={titleHidden}
			inline={inline}
		>
			<Page
				titleHidden={titleHidden}
				fullWidth
				backAction={backAction}
				title={title}
				primaryAction={primaryAction}
				inlinePrimaryAction={inlinePrimaryAction}
				secondaryActions={secondaryActions as any}
				actionGroups={actionGroups}
				additionalMetadata={additionalMetadata}
				titleMetadata={titleMetadata}
				subtitle={subtitle}
				pagination={
					!noPagination && !noTopPagination
						? {
								hasPrevious,
								onPrevious: goPrevious,
								hasNext,
								onNext: goNext,
						  }
						: undefined
				}
			>
				{statsHeader || null}
				{extraHeader || null}
				<LegacyCard>
					{selector || additionalAction ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ flex: 1 }}>{filterComponent}</div>
							{selector}
							{additionalAction}
						</div>
					) : (
						filterComponent
					)}
					<PolarisIndexTable
						//! when this is false, you can't even click the row to trigger the onClickRow
						// selectable={selectable}
						resourceName={resourceName}
						itemCount={items.length}
						selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
						onSelectionChange={handleSelectionChange}
						headings={columnsToHeadings(columns)}
						promotedBulkActions={extendedPromotedBulkActions as PromotedBulkActions}
						bulkActions={extendedBulkActions as BulkActions}
						emptyState={
							loading || propsLoading ? (
								<EmptyState heading={`Hämtar ${resourceName.plural}`} image={image}>
									<Spinner size="small" />
								</EmptyState>
							) : (
								emptyState || (
									<EmptyState
										heading={`Inga ${resourceName.plural} hittades`}
										action={primaryAction as ComplexAction}
										secondaryAction={
											(!noHeader && {
												content: "Rensa alla filters",
												onAction: handleFiltersClearAll,
											}) ||
											undefined
										}
										image={image}
									>
										{!noHeader && <p>Prova att ändra filter eller sökning</p>}
									</EmptyState>
								)
							)
						}
						loading={loading}
						hasMoreItems={store.getState().user.roles.indexOf("ROLE_SUPER_ADMIN") >= 0 && hasNext}
						sortable={columns?.map((column) => column.sortable || false)}
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						onSort={(headingIndex, dir) => {
							const direction = getDirectionFromSort(sortValue) === "ASC" ? "ascending" : "descending";

							// flip direction
							onSortChange(`${columns[headingIndex].handle.toUpperCase()}_${direction === "ascending" ? "DESC" : "ASC"}`);
						}}
						sortDirection={getDirectionFromSort(sortValue) === "ASC" ? "ascending" : "descending"}
						sortColumnIndex={columns.findIndex((column) => column.handle === getHandleFromSort(sortValue))}
						hasZebraStriping
					>
						{rowMarkup}
						{showTotal && <IndexTableRow key="total" total columns={columns} item={generateTotalRow(items)} renderCell={renderCell} />}
					</PolarisIndexTable>
				</LegacyCard>

				{(!noPagination || footer) && (
					<Footer>
						<LimitField
							value={limit}
							onChange={(v: number) => {
								setLimit(v);
							}}
						/>
						{footer || (
							<>
								{"Visar " +
									(offset + 1) +
									"-" +
									(offset + items.length) +
									" av " +
									count +
									" " +
									(count == 1 ? resourceName.singular : resourceName.plural)}
								<Pagination hasPrevious={hasPrevious} onPrevious={goPrevious} hasNext={hasNext} onNext={goNext} />{" "}
							</>
						)}
					</Footer>
				)}
				{extraFooter}
			</Page>
		</Wrapper>
	);
};

export default IndexTable;

// class="Polaris-IndexTable__TableCell Polaris-IndexTable__TableCell--first"

export const Wrapper = styled.div<{ showTablewHeader?: boolean; itemPadding?: string | number; titleHidden?: boolean; inline?: boolean }>`
	//make the columns abit wider to make it less cramped
	/* .Polaris-IndexTable__TableHeading:not(.Polaris-IndexTable__TableHeading--first) {
		min-width: 225px;
	} */

	th,
	td.Polaris-IndexTable__TableCell {
		padding: 1.6rem;
		white-space: nowrap;
		text-align: left;
		transition: background-color 0.2s ease-in-out;
	}

	.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions > .Polaris-IndexTable__StickyTable {
		position: absolute;
		white-space: nowrap;
	}

	.Polaris-IndexTable__SelectAllActionsWrapper {
		background: transparent;
	}

	.Polaris-IndexTable-Checkbox--expanded {
		height: unset;
	}

	//Make all columns except the title a bit smaller
	/* td.Polaris-IndexTable__TableCell:not(.Polaris-IndexTable__TableCell--first):not(:nth-child(2)) {
		&,
		* {
			font-size: 12px;
		}
	} */

	${(props) => {
		if (!props["data-selectable"]) {
			return css`
				.Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first,
				.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
					display: none;
				}

				//get the second column to be a bit more to the left
				.Polaris-IndexTable__TableRow td:nth-child(2):not(.Polaris-IndexTable__TableCell--first) {
					padding-left: 1.6rem;
				}

				.Polaris-IndexTable__TableHeading td:nth-child(2):not(.Polaris-IndexTable__TableHeading--first) {
					padding-left: 1.6rem;
				}

				.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second {
					padding-left: 1.6rem;
				}
			`;

			// return css`
			// 	.Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first > *,
			// 	.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first > * {
			// 		display: none;
			// 	}
			// `;
		} else {
			return css`
				.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions
					.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second {
					visibility: hidden;
				}
			`;
		}
	}};

	${(props) => {
		if (!props.showTablewHeader) {
			return css`
				table thead tr th {
					padding: 0;

					* {
						display: none;
					}
				}
			`;
		}
	}};

	${(props) => {
		if (props.itemPadding) {
			return css`
				&&& {
					.Polaris-IndexTable__TableCell {
						padding: ${typeof props.itemPadding === "number" ? `${props.itemPadding}px` : props.itemPadding};
					}
				}
			`;
		}
	}};

	${(props) => {
		if (props.titleHidden) {
			return css`
				&&& {
					margin-top: 1rem;
				}
			`;
		}
	}};

	${(props) => {
		if (props.inline) {
			return css`
				&&& .Polaris-Page {
					padding: 0;
				}
			`;
		}
	}};

	${ItemCardWrapper} {
		background-color: unset;
		padding: unset;

		&:hover {
			filter: unset;
		}
	}

	.Polaris-IndexTable__TableCell {
		/* &,
		* {
			transition: background-color 100ms, background 100ms, color 100ms, all 100ms;
		} */
		b {
			font-weight: 600;
		}

		a {
			text-decoration: underline;
		}
	}

	th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
		width: 80px;

		.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
			padding-left: 1.6rem;
		}
	}
	.Polaris-BulkActions__ButtonGroupWrapper {
		padding-left: 1.6rem;
		background: var(--main1);
	}

	.Polaris-IndexTable__SelectAllActionsWrapper {
		padding-left: 2.6rem;
		padding-top: 1.1rem;

		.Polaris-CheckableButton__Label {
			margin-left: 24px;
		}
	}

	.Polaris-AlphaFilters__Container {
		.Polaris-Button.Polaris-Button--primary.Polaris-Button--sizeMicro {
			padding: 0.35rem 0.75rem;
		}
	}

	.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--subdued.Polaris-IndexTable__TableRow--disabled {
		background: var(--primary);

		td.Polaris-IndexTable__TableCell {
			padding-block: 0.5rem;

			.Polaris-IndexTable-Checkbox__TableCellContentContainer {
				display: none;
			}
		}
	}

	.Polaris-IndexTable__EmptySearchResultWrapper {
		img[src="/assets/images/empty_state/NoResults.png"] {
			/* height: 250px; */
			height: 25vh;
		}
	}

	.Polaris-IndexTable__StickyTableHeader--isSticky {
		padding-top: 56px;

		.Polaris-IndexTable__TableHeading {
			padding-left: 1.6rem;
		}

		.Polaris-IndexTable__TableHeading:first-child {
			padding-left: 2.6rem;

			.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
				padding-left: 6px;
			}
		}
	}

	tbody {
		transition: opacity 0.2s ease-in-out;
	}

	/* .Polaris-IndexTable__TableRow__child {
		padding-left: 2.6rem;
	} */

	${(props) => {
		if (props["data-loading"] === true || props["data-loading"] === "true") {
			return css`
				.Polaris-IndexTable__EmptySearchResultWrapper {
					padding-top: 10rem;
				}

				tbody {
					opacity: 0.6;
				}
			`;
		}
	}}

	${(props) => {
		if (props["data-clickable"] === true || props["data-clickable"] === "true") {
			return css`
				.Polaris-IndexTable__TableRow--unclickable {
					cursor: pointer;
					pointer-events: all;
				}
			`;
		}
	}}


	.Polaris-Filters__AddFilter {
		background: var(--tertiary);

		svg {
			height: 20px;
			width: 20px;

			path {
				fill: var(--accent);
			}
		}
	}
`;

const Footer = styled.div`
	min-height: 100px;
	padding-top: 20px;
	display: flex;
	color: "#ffffff80";
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;
