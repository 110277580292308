import React, { useEffect } from "react";
import { Wrapper } from "./styles";
import { EmptyState } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import Page from "src/js/components/page";
import Prospect from "./views/ProspectLicense";
import Toast from "src/js/components/Toast";

const MissingLicense = ({ history, location, ...rest }: any) => {
	const { t } = useTranslation(["routes", "common"]);
	const data = location?.state?.data;

	const licenseProvider = data?.data?.message ? JSON.parse(data?.data?.message || "") : null;

	useEffect(() => {
		Toast.info(
			<>
				<p>{`Du saknar licens för "${licenseProvider?.title || "-"}"`}</p>
				<p>Kontakta din säljare</p>
			</>,
			{ autoClose: false }
		);
	}, [licenseProvider?.title]);

	const content = (() => {
		switch (licenseProvider?.fortnox_sku) {
			case "54185":
			case "54189": {
				return <Prospect licenseProvider={licenseProvider} />;
			}
			default: {
				return (
					<Page>
						<EmptyState
							heading={t("routes.missingLicense.title", `Du saknar licensen "${licenseProvider?.title || "-"}"`) as string}
							image="/assets/emptystate-files.png"
							action={{
								content: t("routes.missingLicense.action", "Kom igång nu") as string,
								onAction: () => {
									history.push("/admin/licenses");
								},
							}}
						/>
					</Page>
				);
			}
		}
	})();

	return <Wrapper>{content}</Wrapper>;
};
export default MissingLicense;
