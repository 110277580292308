import React, { Component } from "react";
import { Avatar, Icon, LegacyStack, Button, FormLayout, TextField, Popover, ActionList, Text } from "@shopify/polaris";
import { CirclePlusMinor, MobileAcceptMajor, MobileCancelMajor, SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import MemberAvatar from "./components/MemberAvatar";
import MyPopover from "../../components/Popover";
import { store } from "../../store";
import Toast from "src/js/components/Toast";

class CellPerson extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.value || [], allUsers: [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value || [] });
		}

		// this.fetchAndAddAllAccountUsers(props.board.members);

		if (boardState?.baseRoute !== "guest") {
			this.fetchAndAddAllAccountUsers(props.board.members);
		} else {
			this.setState({ allUsers: props.board.members });
		}
	}

	componentDidMount() {
		if (boardState?.baseRoute !== "guest") {
			this.fetchAndAddAllAccountUsers(this.props.board.members);
		} else {
			this.setState({ allUsers: this.props.board.members });
		}
	}

	addMember(member) {
		if (this.state.value.indexOf(member.user_id) < 0) {
			if (this.props.column.options.single) {
				this.state.value = [member.user_id];
			} else {
				this.state.value.push(member.user_id);
			}
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		} else {
			this.removeMember(member);
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	removeMember(member) {
		const index = this.state.value.indexOf(member.user_id);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		}
	}

	inviteMember() {
		this.setState({ saving: true });
		API.post("/api/boards/" + this.props.column.board_id + "/members.json", { title: this.state.title, email: this.state.email }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });
				this.addMember(result.data.member);
				this.props.onUpdateBoard(result.data.board);
				Toast.success(this.props.t("cell.person.responses.invited_new", "Bjöd in ny medlem"));
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	fetchAndAddAllAccountUsers(current = []) {
		const transformedStoreUsers = store.getState().users.map((u) =>
			Object.assign(u, {
				user_id: parseInt(u.id),
				enabled: u.enabled,
				user: Object.keys(u).reduce((acc, key) => {
					acc[key] = u[key];
					return acc;
				}, {}),
				title: u.name,
			})
		);

		const finalData = []
			.concat(transformedStoreUsers)
			.concat(
				current.map((u) => {
					u.enabled = true;
					return u;
				})
			)
			.filter((user, index, self) => index === self.findIndex((t) => t.user_id === user.user_id));

		this.setState({ allUsers: finalData });
		return finalData;
	}

	render() {
		if (this.state.value && !Array.isArray(this.state.value)) {
			this.state.value = [this.state.value];
		}

		const users =
			this.state.allUsers && this.state.allUsers.length
				? this.state.allUsers.filter((user) => {
						const usr = user.user || user;
						return usr.enabled || this.state.value?.includes(usr.id);
				  })
				: [];

		const usersAdded = users.filter((c) => this.state.value.indexOf(c.user_id) >= 0) || [];
		const usersNotAdded = users.filter((c) => this.state.value.indexOf(c.user_id) < 0) || [];
		const added = usersAdded && usersAdded.length ? usersAdded.sort((a = {}, b = {}) => a.title && a.title.localeCompare(b.title)) : [];
		const notAdded = usersNotAdded && usersNotAdded.length ? usersNotAdded.sort((a = {}, b = {}) => a.title && a.title.localeCompare(b.title)) : [];

		const options = []
			.concat(added, notAdded)
			.filter(
				({ name = "", email = "" }) =>
					!this.state.query || name.toLowerCase().includes(this.state.query) || email.toLowerCase().includes(this.state.query)
			)
			.map((member) => ({
				onAction: this.addMember.bind(this, member),
				disabled: this.props.disabled,
				content: (
					<div className="personItem" key={`opt-${member.id || member.user_id}`}>
						<LegacyStack>
							<MemberAvatar member={member} />
							<LegacyStack vertical spacing="none">
								<div>{member.title}</div>
								<Text variation="subdued">{member.email}</Text>
							</LegacyStack>
							{this.state.value && this.state.value.indexOf(member.user_id) >= 0 ? (
								<div className="removePersonIndicator">
									<div className="remove">
										<Icon source={MobileCancelMajor} color="red" />
									</div>
									<div className="added">
										<Icon source={MobileAcceptMajor} color="green" />
									</div>
								</div>
							) : null}
						</LegacyStack>
					</div>
				),
			}));

		options.push({
			content: (
				<LegacyStack key="add">
					<Avatar customer size="small" />
					<LegacyStack vertical spacing="none">
						<div>{this.props.t("cell.person.actions.invite", "Bjud in medlem")}</div>
						<Text variation="subdued">{this.props.t("cell.person.actions.add_new", "Lägg till ny medlem")}</Text>
					</LegacyStack>
				</LegacyStack>
			),
			onAction: () => {
				this.setState({ invite: true });
			},
		});

		const members = [];
		for (let i = 0; i < this.state.value.length; i++) {
			const member = users.find((u) => u.user_id == this.state.value[i]);
			const user = (member && member.user) || {};
			if (user) {
				members.push({ user_id: user.id, user });
			}
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						style={{
							height: "100%",
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState(
									{
										active: true,
										invite: false,
										title: "",
										email: "",
									},
									() => {
										setTimeout(() => {
											this.input.focus();
										}, 200);
										this.props.onFocus();
									}
								);
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<div className="smallerAvatars">
							<div className="add-btn">
								<Icon source={CirclePlusMinor} color="blue" />
							</div>
							{members.length > 0 ? (
								members.map((member) => <MemberAvatar key={`${member.id || member.user_id}`} member={member} tooltip />)
							) : (
								<Avatar key="empty" size="small" customer />
							)}
						</div>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				{this.state.invite ? (
					<Popover.Section title={this.props.t("cell.person.actions.invite_short", "Bjud in")}>
						<FormLayout>
							<TextField
								label={this.props.t("cell.person.fields.title.label", "För- och efternamn")}
								value={this.state.title}
								onChange={(val) => {
									this.setState({ title: val });
								}}
							/>
							<TextField
								label={this.props.t("cell.person.fields.email.label", "E-post")}
								type="email"
								value={this.state.email}
								onChange={(val) => {
									this.setState({ email: val });
								}}
							/>
							<span
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Button
									disabled={!this.state.title || !this.state.email}
									loading={this.state.saving}
									fullWidth
									primary
									onClick={this.inviteMember.bind(this)}
								>
									{this.props.t("cell.person.actions.invite_short", "Bjud in")}
								</Button>
							</span>
						</FormLayout>
					</Popover.Section>
				) : null}
				<span style={{ position: "absolute", left: 7, top: 10 }}>
					<Icon source={SearchMinor} color="inkLighter" />
				</span>
				<input
					ref={(input) => {
						this.input = input;
					}}
					onKeyDown={(event) => {
						if (event.key === "Escape") {
							this.setState({ active: false, query: "" }, this.props.onBlur);
						}
					}}
					className="bgInput"
					style={{ paddingLeft: 30 }}
					placeholder={this.props.t("cell.person.placeholder", "Sök person...")}
					type="text"
					value={this.state.query}
					onChange={(event) => {
						this.setState({ query: event.target.value.toLowerCase() });
					}}
				/>
				{!this.state.invite ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<ActionList items={options} />
					</div>
				) : null}
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellPerson);
