import { colorToRgba } from "src/js/Colors";
import styled, { css } from "styled-components";

export const ChatMessageCreatorWrapper = styled.div`
	padding-inline: var(--p-space-5);
	z-index: 2;
	position: relative;
`;

export const ChatMessagesListWrapper = styled.div`
	position: relative;
	overflow-y: auto;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
`;

export const ChatWrapper = styled.div`
	grid-area: chat;
	flex: 1;
	height: 100%;
	position: relative;

	display: grid;
	grid-template-rows: 1fr auto;
	//make the scrollbar futher to the left
	padding-block: var(--p-space-5);
	transition: width 500ms;

	.Polaris-LegacyCard,
	.Polaris-LegacyCard__Section {
		height: 100%;
	}
`;

export const IntroMessageWrapper = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;

	/* background-color: ${colorToRgba("rgb(0, 0, 0)", 0.3)}; */

	h3 {
		font-size: 1.5em;
		margin-bottom: 1em;
	}
`;

export const PredefinedMessagesWrapper = styled.div`
	display: flex;
	gap: 1em;
	flex-wrap: wrap;
	margin-top: 1em;
	justify-content: center;
`;

export const ScrollToBottomButtonWrapper = styled.div<{ isAtBottom?: boolean }>`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	z-index: 20;
	transform: translateY(-50%);
	opacity: 0;
	pointer-events: none;
	padding: 1rem;
	transition: transform 500ms, opacity 250ms;

	${({ isAtBottom }) =>
		!isAtBottom &&
		css`
			transform: translateY(-100%);
			opacity: 1;
			pointer-events: all;
		`}
`;
