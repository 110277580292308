import React from "react";
import nl2br from "react-nl2br";
import styled, { css } from "styled-components";
import Button from "../Button";
import { BehaviorMajor } from "@shopify/polaris-icons";
import { Tooltip } from "@shopify/polaris";
import AnimatedWritingText from "../AnimatedWritingText";
import { marked, Renderer } from "marked";
import DOMPurify from "dompurify";
import hljs from "highlight.js"; // Highlight.js for syntax highlighting
// import "highlight.js/styles/github.css"; // Import a Highlight.js theme
import "highlight.js/styles/atom-one-dark.css";
import ChatBotIcon from "./ChatBotIcon";
// import "highlight.js/styles/monokai-sublime.css";

type ChatMessageProps = {
	message?: any;
	messageContentKey: string;
	onData?: any;
};

// Create a custom renderer for syntax highlighting
const renderer = new Renderer();
renderer.code = ({ text, lang }: { text: string; lang?: string }) => {
	const validLanguage = lang && hljs.getLanguage(lang);
	const highlighted = validLanguage ? hljs.highlight(text, { language: lang }).value : hljs.highlightAuto(text).value;

	const uniqueId = `code-block-${Math.random().toString(36).substr(2, 9)}`;
	// <button
	// 	onclick="navigator.clipboard.writeText(document.getElementById('${uniqueId}').innerText)"
	// 	style="
	// 				position: absolute;
	// 				right: 0px;
	// 				background: #46494f;
	//         color: #fff;
	//         border: none;
	//         padding: 4px 8px;
	//         font-size: 12px;
	//         border-radius: 0.65rem;
	//         cursor: pointer;
	//         z-index: 1;
	// 				text-align: center;
	// 				opacity: 0.75;"
	// >
	// 	Copy
	// </button>;

	return `<div style="position: relative;"><pre style="padding:0;background:transparent;"><code id="${uniqueId}" class="hljs">${highlighted}</code></pre></div>`;
};

// Configure `marked` to use the custom renderer
marked.setOptions({ renderer });

const AssistantChatMessage = ({ message, messageContentKey, onData }: ChatMessageProps) => {
	const onDataButton = onData && message?.[onData.key] && (
		<Button icon={onData.icon || BehaviorMajor} plain onClick={() => onData.handler(message?.[onData.key])}>
			{onData.content}
		</Button>
	);

	const messageContent = message?.[messageContentKey];

	return (
		<ChatMessageWrapper>
			<ChatMessageInnerMainWrapper>
				<ChatBotIcon size={40} />
				<ChatMessageInnerWrapper isError={message?.is_error}>
					{typeof messageContent === "string" ? (
						/```|[*_~`]/.test(messageContent) ? (
							<div
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(marked(messageContent)),
								}}
							/>
						) : (
							<AnimatedWritingText animate={message.animate || false} text={nl2br(messageContent)} charsPerTick={1} />
						)
					) : (
						messageContent
					)}
				</ChatMessageInnerWrapper>
			</ChatMessageInnerMainWrapper>
			<ChatMessageExtraWrapper>
				<Date>{message.created_at}</Date>
				{onDataButton && onData && (onData.tooltip ? <Tooltip content={onData.tooltip}>{onDataButton}</Tooltip> : onDataButton)}
			</ChatMessageExtraWrapper>
		</ChatMessageWrapper>
	);
};

export default AssistantChatMessage;

// Styled components
export const ChatMessageWrapper = styled.div`
	margin-block: 1rem;
	width: 100%;
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	flex-direction: column;
`;

export const ChatMessageInnerMainWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	max-width: 75%;

	.Polaris-Icon {
		margin: 0;
		/* width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px; */
	}
`;

export const ChatMessageInnerWrapper = styled.div<{ isError?: boolean }>`
	padding: 1rem;
	width: max-content;
	border-radius: 0.65rem;
	white-space: pre-wrap;

	background-color: var(--main3);
	color: var(--textColor);

	max-width: 100%; /* Ensure it doesn't overflow the container */
	white-space: pre-wrap; /* Keep line breaks but wrap long content */
	word-wrap: break-word; /* Break long words if necessary */

	pre {
		background: #f6f8fa; // Background color for code blocks
		padding: 1rem;
		border-radius: 0.5rem;
		overflow-x: auto;
	}

	code {
		font-family: "Courier New", Courier, monospace;
		font-size: 0.9rem;
	}

	${({ isError }) =>
		isError &&
		css`
			background-color: var(--red);
			color: var(--errorText);
		`}
`;

export const ChatMessageExtraWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const Date = styled.div`
	color: var(--textColor2);
	opacity: 0.85;
	font-size: 0.7rem;
`;

export const ChatMessageCreatorWrapper = styled.div``;
export const ChatWrapper = styled.div``;
