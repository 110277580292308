import styled from "styled-components";

export const Wrapper = styled.div`
	/* font-size: 12px; */
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 1em;
	grid-template-areas: "chat filters";
	position: relative;

	/* * {
		font-size: 1em;
	} */
`;

export const RightWrapper = styled.div`
	grid-area: filters;

	.Polaris-FormLayout--condensed {
		.Polaris-FormLayout__Items {
			.Polaris-FormLayout__Item:first-child {
				margin-left: 0 !important;
			}
		}
	}
`;
