import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { Tooltip } from "@shopify/polaris";
import ApiUserTokenModal from "./ApiUserTokenModal";
import IndexTable from "src/js/components/IndexTable";

const ApiUserTokens = ({ history, t }) => {
	const [apiUser, setApiUser] = useState<ApiUserTokenType | null>(null);
	const [open, setOpen] = useState(false);

	const refresh = useRef(undefined);

	const columns = [
		{
			handle: "TITLE",
			label: t("api_user_tokens.column.title", "Nyckel namn"),
			sortable: true,
			render: (item) => item.title,
		},
		{
			handle: "USER_NAME",
			label: t("api_user_tokens.column.name", "Användare"),
			sortable: true,
			render: (item) => {
				return (
					<div style={{ display: "flex", gap: "0.6250rem", alignItems: "center" }}>
						<ProfileAvatar user={item.user} />
						{item?.user?.name}
					</div>
				);
			},
		},
		{
			handle: "STATUS",
			label: t("api_user_tokens.column.status", "Status"),
			sortable: true,
			render: (item) => {
				if (item.revoked)
					return (
						<Tooltip
							content={
								<span>
									Inaktiverad den {item.revoked_at} av <b>{item.revoked_by_user?.name || "-"}</b>
								</span>
							}
						>
							<Badge status="critical">{t("api_user_tokens.revoked", "Inaktiverad")}</Badge>
						</Tooltip>
					);
				return <Badge status="success">{t("api_user_tokens.active", "Aktiv")}</Badge>;
			},
		},
		{
			handle: "CREATED_AT",
			label: t("api_user_tokens.column.created", "Skapad"),
			sortable: true,
			render: (item) => item.created_at && new Date(item.created_at).toLocaleDateString(),
		},
	];

	const filters = [
		{
			key: "user_id",
			label: t("api_user_tokens.filters.user.label", "Användare"),
			type: "user",
			shortcut: true,
		},
		{
			key: "revoked",
			label: t("api_user_tokens.filters.revoked.label", "Inaktiverad"),
			type: "select",
			options: [
				{ label: t("common.terms.yes", "Ja"), value: 1 },
				{ label: t("common.terms.no", "Nej"), value: 0 },
			],
			shortcut: true,
		},
	];

	const documentationLink = `https://www.sellfinity.com/api/doc`;

	return (
		<>
			<IndexTable
				setRefreshHandler={(refreshHandler) => {
					refresh.current = refreshHandler;
				}}
				history={history}
				title={t("api_user_tokens.title", "API användar nycklar")}
				titleMetadata={
					<a
						style={{ fontWeight: "bold", color: Colors.blue, textDecoration: "underline" }}
						href={documentationLink}
						target="_blank"
						rel="noreferrer"
					>
						{t("api_user_tokens.documentation.label", "Dokumentation")}
					</a>
				}
				primaryAction={{
					content: t("api_user_tokens.actions.new", "Ny API användar nyckel"),
					onAction: () => {
						setOpen(true);
					},
				}}
				onClickRow={(item: ApiUserTokenType) => {
					setApiUser(item);
					setOpen(true);
				}}
				savedSearchHandle="api_user_tokens"
				resourceUrl="/api/api_user_tokens.json"
				resourceHandle="api_user_tokens"
				// onSelectionChange={this.onSelectionChange.bind(this)}
				resourceName={{
					singular: t("api_user_tokens.singular", "API användar nyckel"),
					plural: t("api_user_tokens.plural", "API användar nycklar"),
				}}
				columns={columns}
				filters={filters}
			/>
			<ApiUserTokenModal
				open={open}
				apiUser={apiUser}
				history={history}
				onClose={() => {
					setOpen(false);
					setApiUser(null);
				}}
				onSuccess={(apiUser) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					refresh?.current?.();
				}}
			/>
		</>
	);
};
export default withTranslation(["api_user_tokens", "common"])(ApiUserTokens);
