import React, { useState } from "react";
import { useProspectContext } from "../../../../Context/ProspectContext";
import { Icon, LegacyCard, ResourceList, Text } from "@shopify/polaris";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import { SearchMajor } from "@shopify/polaris-icons";
import TextField from "src/js/components/TextField";
import CustomCard from "src/js/components/CustomCard";

const ExecutivesList = () => {
	const { company, isLoadingProspect, t } = useProspectContext();
	const [search, setSearch] = useState("");

	const renderContact = (item) => {
		return (
			<ResourceList.Item id={item.id} onClick={() => {}}>
				<div style={{ display: "flex", gap: "0.6250rem", alignItems: "center" }}>
					<ProfileAvatar user={item} size={35} />

					<div>
						<Text as="h3">{item.name}</Text>
						<Text variant="bodySm" as="p" color="subdued">
							{item.formatted_title}
						</Text>
					</div>
				</div>
			</ResourceList.Item>
		);
	};

	const items = company?.executives || [];

	return (
		// <LegacyCard actions={contactActions}>
		<CustomCard title={t("prospects.subview.ExecutivesList.title", "Styrelse och ledning")} subdued collapsible sectioned>
			<div className="contacts-list__wrapper">
				{(!items || !items.length) && !isLoadingProspect ? (
					<LegacyCard.Section>
						<Text as="p" color="subdued">
							{t("prospects.fields.executuves.empty", "Det finns inga kontaktpersoner")}
						</Text>
					</LegacyCard.Section>
				) : items && items.length ? (
					<div className="people">
						<div style={{ marginBottom: "0.3125rem" }}>
							<TextField
								prefix={<Icon source={SearchMajor} />}
								placeholder="Sök.."
								value={search}
								onChange={(search) => {
									setSearch(search);
								}}
								clearButton
								onClearButtonClick={() => setSearch("")}
							/>
						</div>
						<ResourceList
							resourceName={{
								singular: t("prospects.fields.executuves.singular", "kontaktperson"),
								plural: t("prospects.fields.executuves.plural", "Kontaktpersoner"),
							}}
							// selectable={!!this.props.handleContactChange}
							items={items
								.filter((executive) => {
									if (!search) return true;
									const nameMatches = ["name", "formatted_title"].some((key) => executive?.[key]?.toLowerCase()?.includes(search.toLowerCase()));

									return nameMatches;
								})
								?.sort((a, b) => a.name.localeCompare(b.name))}
							// onSelectionChange={(selection) => {
							// 	this.setState({ selectedContacts: selection });
							// 	if (this.props.handleContactChange) this.props.handleContactChange(selection);
							// }}
							resolveItemId={(item) => item.id}
							// selectedItems={this.state.selectedContacts}
							renderItem={renderContact}
						/>
					</div>
				) : null}
			</div>
		</CustomCard>
	);
};
export default ExecutivesList;
