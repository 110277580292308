import React, { useContext, useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, useLayoutEffect, memo } from "react";
import { ChatMessagesListWrapper, ChatWrapper, IntroMessageWrapper, PredefinedMessagesWrapper, ScrollToBottomButtonWrapper } from "./styles";
import ChatMessage from "./ChatMessage";
import ChatMessageCreator from "./ChatMessageCreator";
import ChatBotContext, { ChatBotContextProvider } from "./ChatBotContext";
import Button from "../Button";
import { ChevronDownMinor } from "@shopify/polaris-icons";

type ChatProps = {
	messageContentKey: string;
	chatId?: string | number;
	onData?: onData;
	introMessage?: any;
	predefinedMessages?: any[];

	ref?: any;
};

// Define methods or properties to expose via the external ref
export interface ChatHandles {
	scrollToBottom: () => void;
	getChat: () => void;
}

const SCROLL_THRESHOLD = 100; // Pixels from the bottom to consider auto-scrolling

const Chat = forwardRef<ChatHandles, ChatProps>(({ messageContentKey, chatId, onData, introMessage, predefinedMessages }: ChatProps, externalRef) => {
	const { createMessage, messages, chat, isCreatingMessage, isLoadingMessages } = useContext(ChatBotContext);
	const messagesWithIdsCount = useRef(0);

	const internalRef = useRef<HTMLDivElement>(null); // Renamed internal ref
	const scrollRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const [isAtBottom, setIsAtBottom] = useState(true);
	const [width, setWidth] = useState(0);
	const [showMessages, setShowMessages] = useState(false);
	const previousParentContentRect = useRef<DOMRectReadOnly>(undefined);

	const scrollToBottom = useCallback((smooth = true, top = scrollRef.current?.scrollHeight) => {
		if (scrollRef.current) {
			if (smooth) {
				scrollRef.current.scrollTo({
					top,
					behavior: "smooth",
				});
			} else if (typeof top === "number") {
				scrollRef.current.scrollTop = top;
			}
		}
	}, []);

	useEffect(() => {
		const parent = internalRef.current?.parentElement;
		let timer: any = null;

		if (parent && scrollRef.current) {
			const scrollElement = scrollRef.current;

			const resizeObserver = new ResizeObserver((event) => {
				const entry = event[0];
				const contentRect = entry.contentRect;

				if (previousParentContentRect.current && previousParentContentRect.current.width === contentRect.width) return;

				clearTimeout(timer);
				timer = setTimeout(() => {
					const prevScrollTop = scrollElement.scrollTop;
					const prevScrollHeight = scrollElement.scrollHeight;

					setWidth(0);

					setTimeout(() => {
						requestAnimationFrame(() => {
							if (internalRef.current) {
								setWidth(internalRef.current.clientWidth);
							}

							setTimeout(() => {
								const newScrollHeight = scrollElement.scrollHeight;
								const deltaScrollHeight = newScrollHeight - prevScrollHeight;

								const newScrollTop = prevScrollTop + deltaScrollHeight;
								// scrollRef.current!.classList.add("hidden");
								scrollElement.scrollTop = newScrollTop;
							}, 0);
						});
					}, 0);
				}, 25);

				previousParentContentRect.current = contentRect;
			});

			resizeObserver.observe(parent);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [internalRef]);

	const onMessagesSend = async (input) => {
		await createMessage(input);
	};

	const handleScroll = () => {
		if (!scrollRef.current) return;

		const isNearBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight <= SCROLL_THRESHOLD;

		setIsAtBottom(isNearBottom);
	};

	useEffect(() => {
		if (internalRef.current && !width) {
			setWidth(internalRef.current.clientWidth);
		}
	}, [width]);

	useLayoutEffect(() => {
		if (!width) {
			setShowMessages(!!width);
		}
	}, [width]);

	useEffect(() => {
		if (width) {
			setShowMessages(!!width);
		}
	}, [width]);

	useEffect(() => {
		if (!contentRef.current) return;

		const resizeObserver = new ResizeObserver(() => {
			if (isAtBottom) scrollToBottom();
		});

		resizeObserver.observe(contentRef.current);

		return () => resizeObserver.disconnect();
	}, [isAtBottom, scrollToBottom]);

	// Automatically scroll to bottom when new messages are added
	useEffect(() => {
		if (messagesWithIdsCount.current !== messages.length) {
			scrollToBottom();
			setTimeout(scrollToBottom, 250);

			messagesWithIdsCount.current = messages?.filter((message) => message.id).length;
		}
	}, [messages, scrollToBottom]);

	// Automatically scroll to bottom when chat is opened
	useEffect(() => {
		setTimeout(scrollToBottom, 250);
	}, [scrollToBottom]);

	const topBarHeight = document.querySelector("#AppFrameTopBar")?.clientHeight || 0;
	const pageHeader = (document.querySelector(".Polaris-Page")?.firstChild as any)?.clientHeight || 0;

	// Expose methods to the external ref
	useImperativeHandle(externalRef, () => ({
		scrollToBottom,
		getChat: () => {
			return chat;
		},
	}));

	const isLoading = chatId && isLoadingMessages;

	return (
		<ChatWrapper
			ref={internalRef}
			className="Polaris-LegacyCard"
			style={{
				height: `calc(98vh - ${topBarHeight}px - ${pageHeader}px)`,
				width: width || "unset",
			}}
		>
			{!isLoading && (introMessage || predefinedMessages?.length) && !messages?.length && (
				<IntroMessageWrapper>
					{introMessage}
					{predefinedMessages?.length && (
						<PredefinedMessagesWrapper>
							{predefinedMessages?.map((message, index) => (
								<Button key={index} primary onClick={() => onMessagesSend(message.message)}>
									{message.label || message.message}
								</Button>
							))}
						</PredefinedMessagesWrapper>
					)}
				</IntroMessageWrapper>
			)}
			<ChatMessagesListWrapper
				ref={scrollRef}
				onScroll={handleScroll}
				style={{
					visibility: showMessages ? "visible" : "hidden",
				}}
			>
				{!!width && (
					<div ref={contentRef}>
						{messages.map((message) => (
							<ChatMessage key={message.id || message.ref} message={message} messageContentKey={messageContentKey} onData={onData} />
						))}
					</div>
				)}
			</ChatMessagesListWrapper>
			<ChatMessageCreator onMessagesSend={onMessagesSend} messageContentKey={messageContentKey} isCreatingMessage={isCreatingMessage}>
				<ScrollToBottomButton onClick={scrollToBottom} isAtBottom={isAtBottom} />
			</ChatMessageCreator>
		</ChatWrapper>
	);
});

export default memo(
	forwardRef<ChatHandles, ChatProps>(({ messageContentKey, chatId, onData, introMessage, predefinedMessages }: ChatProps, ref) => {
		return (
			<ChatBotContextProvider onData={onData} chatId={chatId} messageContentKey={messageContentKey} introMessage={introMessage}>
				<Chat
					ref={ref}
					messageContentKey={messageContentKey}
					chatId={chatId}
					onData={onData}
					introMessage={introMessage}
					predefinedMessages={predefinedMessages}
				/>
			</ChatBotContextProvider>
		);
	})
);

const ScrollToBottomButton = ({ onClick, isAtBottom }) => {
	return (
		<ScrollToBottomButtonWrapper isAtBottom={isAtBottom}>
			<Button onClick={onClick} icon={ChevronDownMinor}>
				Scroll to bottom
			</Button>
		</ScrollToBottomButtonWrapper>
	);
};
