import { Button, InlineError } from "@shopify/polaris";
import React from "react";
import Colors, { colorToRgba } from "src/js/Colors";

const defaultFallbackRender = ({ error, resetErrorBoundary, style = {} }: any) => {
	return (
		<div
			style={{
				color: "red",
				borderStyle: "solid",
				borderWidth: 1,
				// borderColor: "rgb(170, 16, 16)",
				borderColor: "var(--main3)",
				padding: "10px",
				margin: "10px",
				// backgroundColor: "var(--main2)",
				backgroundColor: colorToRgba(Colors.red, 0.05),
				borderRadius: 5,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "1.15rem",
				fontWeight: 500,
				// position: "absolute",
				// inset: 0,
				minWidth: 300,

				...(style || {}),
			}}
		>
			<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
				<InlineError message={`Något gick fel, felet rapporterades`} fieldID="error" />
				<Button onClick={resetErrorBoundary}>Prova igen</Button>
				<Button
					onClick={() => {
						resetErrorBoundary();
						window.location.reload();
					}}
				>
					Ladda om sidan
				</Button>
			</div>
		</div>
	);
};
export default defaultFallbackRender;
