import { useHistory } from "react-router-dom";
import useQuery from "./useQuery";
import API from "../API";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEventListener } from "@shopify/polaris";
import { ContactFormType } from "../views/Contacts/ContactModal";
import Toast from "src/js/components/Toast";

type useContactProps = {
	onUpdate?: (contact: ContactFormType) => void;
	onCreate?: (contact: ContactFormType) => void;
	onClose?: () => void;
	onRemove?: (contact: ContactFormType) => void;
	refetchOnMount?: boolean;
};

const useContact = (
	id: number | string | null,
	propsContact?: ContactType | Partial<ContactType>,
	{ onUpdate, onCreate, onClose, onRemove, refetchOnMount = false }: useContactProps = {}
) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { t } = useTranslation(["contact", "common"]);
	const boardContacts = useSelector((state: any) => state.board_contacts);
	const history = useHistory();

	const contactQueryKey = useMemo(
		() => [id && `contact_${id}`, !id && propsContact?.orgnr && `contact_orgnr_${propsContact?.orgnr}`],
		[id, propsContact?.orgnr]
	);

	const {
		data,
		isInitialLoading,
		isRefetching: isContactRefetching,
		refetch: refreshContact,
	} = useQuery({
		queryKey: contactQueryKey,
		queryFn: async () => {
			try {
				const response = await API.get("/api/contacts/" + id + ".json");
				if (!response.data.contact) return null;

				dispatch({
					type: "SET_BOARD_CONTACTS",
					contacts: [response.data.contact],
				});

				return response.data.contact;
			} catch (error) {
				console.error("error:", error);
			}
		},
		enabled: !!id,
		refetchOnMount,
	});

	const contact = useMemo(() => {
		if (propsContact?.id && data?.id && propsContact?.id == data?.id) return data;
		if (propsContact) return propsContact;
		if (data?.id) return data;
		return propsContact;
	}, [data, propsContact]);

	const queryKey = contact?.orgnr ? ["contact", "axicon", contact?.orgnr] : [];
	const {
		data: axiconData,
		isRefetching: axiconIsLoading,
		isInitialLoading: axiconIsInitialLoading,
		error: axiconError,
	}: UseQueryResult<AxionCompanyType> = useQuery({
		queryKey,
		queryFn: async () => {
			const isInvalid = !/^(?:19|20)?\d{6}-?\d{4}$/.test(contact?.orgnr);
			if (isInvalid) {
				throw new Error("Ogiltigt organisationsnummer");
			}

			const res = await API.get(`/api/company_registry/companies/${contact?.orgnr}.json`);
			return res?.data?.company;
		},
		enabled: !!contact?.orgnr,

		refetchOnMount,
		retry: 0,
	});

	const {
		data: customer,
		refetch: refreshCustomer,
		isRefetching: isLoadingCustomer,
	} = useQuery({
		queryKey: [id && `fortnox_customer_${id}`],
		queryFn: async () => {
			try {
				if (!contact?.fortnox_customer_id) return;

				const response = await API.get(`/api/fortnox/customer/${contact.fortnox_customer_id}.json`);

				return response?.data?.customer;
			} catch (error) {
				console.error("error:", error);
			}
		},
		enabled: !!(contact?.id && contact?.fortnox_integration && !contact?.fortnox_integration?.removed && contact?.fortnox_customer_id),
	});

	const updateCachedContact = useCallback(
		async (updatedContact) => {
			try {
				const key = [`contact_${updatedContact.id}`, !updatedContact?.id && updatedContact?.orgnr && `contact_orgnr_${updatedContact?.orgnr}`];

				if (key?.length) queryClient.setQueryData(key, updatedContact);

				if (updatedContact.fortnox_integration && !updatedContact.fortnox_integration?.removed) refreshCustomer();
			} catch (error) {
				console.error("error:", error);
			}
		},
		[queryClient, refreshCustomer]
	);

	const mutation = useMutation(
		async ({ form, id }: { form: Partial<ContactFormType>; id?: number | string | null }) => {
			if (form?.id && id && form.id != id) throw new Error("Id mismatch");
			const contactId = id;

			// if (contactId && form.id && contactId != form.id) throw new Error("Id mismatch");

			// return;

			const result = contactId ? await API.put("/api/contacts/" + contactId + ".json", form) : await API.post("/api/contacts.json", form);

			if (result.data.error) {
				Toast.error(result.data.error);
				return;
			}

			if (result.data.contact.is_company) {
				Toast.success(
					contactId ? t("contact.responses.company.updated", "Företag uppdaterat") : t("contact.responses.company.created", "Företag skapat")
				);
			} else if (!result.data.contact.is_company) {
				Toast.success(
					contactId
						? t("contact.responses.contact_person.updated", "Kontaktperson uppdaterad")
						: t("contact.responses.contact_person.created", "Kontaktperson skapad")
				);
			}

			if (!contactId) {
				if (onCreate) onCreate(result.data.contact);
			}

			return result.data.contact;
		},
		{
			onSuccess: (data) => {
				if (!data) return;
				const key = [`contact_${data.id}`, !data?.id && data?.orgnr && `contact_orgnr_${data?.orgnr}`];
				if (key?.length) queryClient.setQueryData(key, data);
				if (contactQueryKey?.length && data?.id == id) refreshContact();

				dispatch({ type: "UPDATE_BOARD_CONTACT", contact: data || {} });

				if (data.is_company && boardContacts) {
					Object.values(boardContacts).forEach((c: any) => {
						if (!c.is_company && c.parent && c.parent.id == data.id) {
							c.parent = data;
							dispatch({ type: "UPDATE_BOARD_CONTACT", contact: c });
						}
					});
				}

				if (onUpdate && data) onUpdate(data);
			},
		}
	);

	const updateContact = useCallback(
		async (form: Partial<ContactFormType>, id: number | string | null = form?.id || null, throwError = false) => {
			try {
				return await mutation.mutateAsync({ form, id });
			} catch (error) {
				Toast.error(error);
				if (throwError) throw error;
			}
		},
		[mutation]
	);

	// remove fortnox integration connection
	const removeFortnoxIntegration = useCallback(
		async (id = contact.id) => {
			try {
				const response = await API.delete(`/api/fortnox/contacts/${id}/disconnect.json`);
				Toast.success(t("contact.responses.fortnox.removed", "Fortnox koppling borttagen"));

				if (response.data.contact) updateCachedContact(response.data.contact);
			} catch (error) {
				console.error("error:", error);
				Toast.error(error);
			}
		},
		[contact?.id, t, updateCachedContact]
	);

	const {
		data: documentGroups,
		refetch: refetchDocumentGroups,
		isInitialLoading: isLoadingDocumentGroups,
	} = useQuery({
		queryKey: ["contact_resources", id],
		queryFn: async () => {
			const result = await API.get(`/api/documents/${id}/count.json`);
			return result.data.document_groups.map((gr) => {
				const board = result.data.boards.find((board) => board.id === gr.board_id);
				const column = board.columns.find((column) => column.id === gr.column_id);

				return Object.assign(gr, { board, column });
			});
		},
		enabled: !!id,
		refetchOnMount,
	});

	const {
		data: resources,
		refetch: refetchResources,
		isInitialLoading: isLoadingResources,
	} = useQuery({
		queryKey: ["document_groups", id],
		queryFn: async () => {
			const result = await API.get(`/api/contacts/${id}/resources.json`);

			// 	if (this.props.setCounts) {
			// 	const counts = result.data.boards.reduce((acc, board) => Object.assign(acc, { [board.id]: board.count }), {});
			// 	this.props.setCounts(counts);
			// }

			return result.data;
		},
		enabled: !!id,
		refetchOnMount,
	});

	const {
		data: dataCounts = {},
		isFetching: isLoadingCounts,
		refetch: refreshCounts,
	} = useQuery({
		queryKey: [contact?.id && `contact_${contact?.id}_counts`],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/contacts/${contact?.id}/counts.json`);
				const counts = res.data?.counts || {};

				return counts;
			} catch (e) {
				console.error("Error", e);
			}
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		refetchOnMount,
	});

	const counts = useMemo(() => {
		return {
			...(resources?.boards.reduce((acc, board) => Object.assign(acc, { [board.id]: parseInt(board.count || 0) }), {}) || {}),
			...(documentGroups?.reduce(
				(acc, group) => Object.assign(acc, { [`${group.board_id}_${group.column_title.toLowerCase()}`]: parseInt(group.count || 0) }),
				{}
			) || {}),
			...dataCounts,
		};
	}, [dataCounts, resources?.boards, documentGroups]);

	const syncToFortnox = useCallback(
		async (CustomerNumber: string | number | null = null) => {
			try {
				if (!id) return;
				const result = await API.post(`/api/fortnox/contacts/${id}/customer.json`, { CustomerNumber });

				if (result.data?.customer) {
					Toast.success(t("common.related_resources.sync_fortnox.success", "Kunden synkades till Fortnox"));

					if (refreshContact) {
						refreshContact();
					} else {
						window.location.reload();
					}
				}
			} catch (e) {
				console.error("Error", e);
			}
		},
		[id, refreshContact, t]
	);

	const removeContact = useCallback(
		async (contactId = id) => {
			try {
				const result = await API.delete(`/api/contacts/${contactId}.json`);
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				updateCachedContact(result.data.contact);

				if (result.data.contact?.is_company) {
					Toast.success(t("contact.responses.company.removed", "Företaget borttaget"));
				} else {
					Toast.success(t("contact.responses.contact_person.removed", "Kontaktpersonen borttagen"));
				}

				onClose?.();

				if (onRemove) {
					onRemove?.(result.data.contact);
				} else {
					if (result.data.contact?.is_company) {
						history.push("/admin/contacts/companies");
					} else {
						history.push("/admin/contacts/people");
					}
				}

				return result.data.contact;
			} catch (error) {
				console.error("error:", error);
			}
		},
		[id, t, history, updateCachedContact, onRemove, onClose]
	);

	useEventListener("refreshContactResources" as any, refetchDocumentGroups);

	const results = useMemo(() => {
		return {
			id,
			contact,
			refreshContact,
			isInitialLoading,
			axiconData,
			axiconIsLoading,
			axiconIsInitialLoading,
			axiconError,
			customer,
			removeFortnoxIntegration,
			refreshCustomer,
			updateContact,
			t,
			documentGroups,
			resources,
			refetchDocumentGroups,
			refetchResources,
			isLoadingDocumentGroups,
			isLoadingResources,
			syncToFortnox,
			isContactRefetching,
			counts,
			isLoadingCounts,
			refreshCounts,
			removeContact,
			isLoadingCustomer,
		};
	}, [
		axiconData,
		axiconIsLoading,
		contact,
		id,
		isInitialLoading,
		refreshContact,
		axiconIsInitialLoading,
		axiconError,
		customer,
		removeFortnoxIntegration,
		refreshCustomer,
		t,
		documentGroups,
		resources,
		refetchDocumentGroups,
		refetchResources,
		isLoadingDocumentGroups,
		isLoadingResources,
		syncToFortnox,
		isContactRefetching,
		updateContact,
		counts,
		isLoadingCounts,
		refreshCounts,
		removeContact,
		isLoadingCustomer,
	]);

	return results;
};

export default useContact;
