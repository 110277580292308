import { EmptyState } from "@shopify/polaris";
import React, { useState } from "react";
import { useProspectContext } from "../../../Context/ProspectContext";

const ProspectCompanyEmptyScreen = () => {
	const { handleLoadMoreProspects, t } = useProspectContext();
	const [isLoading, setIsLoading] = useState(false);

	const loadMoreProspects = async () => {
		try {
			setIsLoading(true);
			await handleLoadMoreProspects();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<EmptyState
			image="/assets/emptystate-files.png"
			action={{
				content: t("prospects.view.prospect_company.empty_state.action", "Ladda in fler prospekts"),
				onAction: loadMoreProspects,
				loading: isLoading,
			}}
			heading={t("prospects.view.prospect_company.empty_state.title", "Inga fler prospekts i listan")}
		>
			{t(
				"prospects.view.prospect_company.empty_state.description",
				"Det finns inga fler prospekts i listan. Du kan hämta fler prospekts genom att klicka på knappen nedan."
			)}
		</EmptyState>
	);
};
export default ProspectCompanyEmptyScreen;
