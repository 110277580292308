import React, { useMemo } from "react";
import { useProspectContext } from "../../../../Context/ProspectContext";
import styled from "styled-components";
import CustomCard from "src/js/components/CustomCard";
import Details from "src/js/components/Details";

const ProspectContactAddressCard = () => {
	const { company, t } = useProspectContext();

	const addressString = useMemo(() => {
		if (!company?.address) return "";
		return `${company.address}, ${company.postal_code} ${company.city}`;
	}, [company]);

	const iframeUrl = useMemo(() => {
		if (!addressString) return "";
		const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

		const encodedAddress = encodeURIComponent(addressString);
		return `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodedAddress}`;
	}, [addressString]);

	return (
		<CustomCard title={t("prospects.subview.ProspectContactAddressCard.title", "Adressuppgifter")} subdued collapsible sectioned>
			<Wrapper>
				<Details
					gap={{
						horizontal: "3rem",
						vertical: "0.5rem",
					}}
					items={[
						{
							label: t("prospects.subview.ProspectContactAddressCard.label.address", "Adress"),
							value: addressString,
						},
					]}
				/>
				{iframeUrl && (
					<iframe
						style={{ marginTop: "1rem" }}
						title={company?.address}
						width="100%"
						height={Math.min(Math.max((window.innerHeight - 100) / 2, 200), 350)}
						loading="lazy"
						allowFullScreen
						src={iframeUrl}
						referrerPolicy="no-referrer-when-downgrade"
					/>
				)}
			</Wrapper>
		</CustomCard>
	);
};
export default ProspectContactAddressCard;

const Wrapper = styled.div``;
