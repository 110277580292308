import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import OrderContext, { OrderContextProvider } from "../OrderContext";
import Page from "src/js/components/page";
import OrderHeader from "./Header";
import OrderOverview from "./Overview";
import DeliveryOverview from "./DeliveryOverview";
import CustomerOverview from "./CustomerOverview";
import copyOrder from "../Utilities/copyOrder";
import { CircleCancelMajor, DuplicateMinor, MobileAcceptMajor, SendMajor, ViewMajor } from "@shopify/polaris-icons";
import Button from "src/js/components/Button";
import { ButtonGroup, FormLayout } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { checkUserRoles } from "src/js/Utilities";
import OrderNotes from "./OrderNotes";
import OrderLogs from "./OrderLogs";
import Toast from "src/js/components/Toast";

const EditOrder = ({ history }) => {
	const { form, order, id, handleSave, isLoading, t, isSaving, handleOpenPDF, handleMarkAsApproved, handleCancel, handleCancelSigning } =
		useContext(OrderContext);

	const storeUser = useSelector((state: any) => state.user);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "overview",
					content: t("tagets.tabs.overview", "Grunduppgifter"),
					render: <OrderOverview />,
				},
				{
					id: "customer",
					content: t("tagets.tabs.customer", "Kunduppgifter"),
					render: <CustomerOverview />,
				},
				{
					id: "delivery",
					content: t("tagets.tabs.delivery", "Leveransuppgifter"),
					render: <DeliveryOverview />,
				},
				{
					id: "notes",
					content: t("tagets.tabs.notes", "Noteringar"),
					render: <OrderNotes />,
				},
				{
					id: "logs",
					content: t("tagets.tabs.logs", "Loggar"),
					render: <OrderLogs />,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[t]
	);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as OrderType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "overview";
	}, [tabs]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};
	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());

	const tab = tabs.find((tab) => tab.id === tabID);
	const title = id
		? t("orders.edit.title", "Redigera order: #{{order}}", { order: order?.order_number })
		: t("orders.create.title", "Skapa ny order");

	const previewAction = {
		//! Ska vi ha något som säger att denna förhandsgranskar EJ sparad data? Eller ska vi kanske bara förhandsgrandska den sparade datan?
		content: t("orders.actions.preview.label", "Förhandsgranska"),
		onAction: () => {
			handleOpenPDF({
				saveHandler: null,
				additionalProps: {
					save: false,
					locked: !storeUser.roles?.includes("ROLE_ORDER_PDF_EDIT"),
				},
			});
		},
		icon: ViewMajor,
		disabled: isLoading,
		enabled: !form?.cancelled_at,
		tooltip: t("orders.actions.preview.tooltip", "Förhandsgranska order som PDF (inkl. Ej sparade ändringar)"),
	};

	const copyAction = {
		content: t("orders.actions.copy.label", "Kopiera order") as string,
		onAction: () => {
			history.push("/admin/orders/create", { data: copyOrder(form) });
			Toast.info(t("orders.actions.copy.message", "Order kopierad. Kom ihåg att spara."));
		},
		icon: DuplicateMinor,
		disabled: isLoading,
		enabled: !!form?.id,
	};

	const markAsApprovedAction = {
		content: t("orders.actions.marked_as_signed.label", "Markera som klar/godkänd"),
		onAction: handleMarkAsApproved,
		icon: MobileAcceptMajor,
		confirmation: {
			title: t("order.actions.marked_as_signed.title", "Markera som klar/godkänd"),
			content: t("order.actions.marked_as_signed.confirmation", "Är du säker på att du vill markera ordern som klar/godkänd utan kundens signering?"),
			confirm: t("order.actions.marked_as_signed.confirm", "Markera som klar/godkänd"),
			cancel: t("common.actions.cancel", "Avbryt"),
		},
		disabled: isLoading,
		enabled: !!(form?.id && !form?.cancelled_at && !form?.prepared_for_signing_at && !form?.signed_at && !form?.marked_as_signed_at),
		roles: ["ROLE_ORDER_MARK_AS_APPROVED"],
	};

	const sendForSigningAction = {
		content: t("order.actions.send_for_signing", "Förbered för signering"),
		onAction: () => {
			handleOpenPDF({
				additionalProps: {
					save: true,
					requireName: false,
					name: `Order #${form.order_number}`,
					locked: !storeUser.roles?.includes("ROLE_ORDER_PDF_EDIT"),
				},
			});
		},
		icon: SendMajor,
		confirmation: {
			title: t("order.actions.scrive.title", "Förbered för signering"),
			content: <FormLayout>{t("order.actions.scrive.confirmation", "Är du säker på att du vill förbereda ordern för signering?")}</FormLayout>,

			confirm: t("order.actions.scrive.confirm", "Fortsätt"),
			cancel: t("common.actions.cancel", "Avbryt"),
		},
		disabled: !form?.id || isLoading || form.prepared_for_signing_at || form.signed_at || form.marked_as_signed_at,
		enabled: !!(form?.id && !form?.cancelled_at) && checkUserRoles(["ROLE_ORDER_SEND"]) && !form.is_locked,
		roles: ["ROLE_ORDER_SEND"],
	};

	const cancelAction = {
		content: t("order.actions.cancel.title", "Makulera order"),
		onAction: handleCancel,
		icon: CircleCancelMajor,
		confirmation: {
			title: t("order.actions.cancel.title", "Makulera order"),
			content: <div>{t("order.actions.cancel.confirmation", "Är du säker på att du vill makulera ordern?")}</div>,
			confirm: t("order.actions.cancel.confirm2", "Makulera order"),
			cancel: t("common.actions.cancel", "Avbryt"),
		},
		disabled: isLoading,
		destructive: true,
		enabled: !!(form?.id && !form?.cancelled_at && (!form.prepared_for_signing_at || form.signed_at)),
		roles: ["ROLE_ORDER_CANCEL"],
	};

	const cancelSigningAction = {
		content: t("order.actions.cancel_signing.title", "Avbryt signeringen"),
		onAction: handleCancelSigning,
		// icon: <CancelDocumentIcon />,
		confirmation: {
			title: t("order.actions.cancel_signing.title", "Avbryt signeringen"),
			content: <div>{t("order.actions.cancel_signing.confirmation", "Är du säker på att du vill avbryta signeringen för ordern?")}</div>,
			confirm: t("order.actions.cancel_signing.confirm2", "Avbryt signeringen"),
			cancel: t("common.actions.cancel", "Avbryt"),
		},
		disabled: isLoading,
		destructive: true,
		enabled: !!(form?.id && !form?.cancelled_at && form?.prepared_for_signing_at && !form?.signed_at && !form?.marked_as_signed_at),
	};

	const primaryAction = (
		<ButtonGroup>
			{sendForSigningAction.enabled && (
				<Button
					primary
					onClick={sendForSigningAction.onAction}
					confirmation={sendForSigningAction.confirmation}
					icon={sendForSigningAction.icon}
					disabled={sendForSigningAction.disabled}
				>
					{sendForSigningAction.content}
				</Button>
			)}

			<Button primary disabled={isLoading} loading={isSaving} onClick={handleSaveForm}>
				{id ? t("common.save", "Spara") : t("common.create", "Skapa")}
			</Button>
		</ButtonGroup>
	);

	const haveAtleastOneCustomFieldThatCanBeEditedAfterOrderLocked = form.order_rows?.some((row) =>
		row.custom_fields?.some((field) => field.custom_field?.can_edit_when_order_is_locked)
	);

	return (
		<Page
			title={title}
			loading={isLoading}
			history={history}
			primaryAction={(!form.is_locked || haveAtleastOneCustomFieldThatCanBeEditedAfterOrderLocked) && primaryAction}
			secondaryActions={[previewAction, markAsApprovedAction, copyAction, cancelSigningAction, cancelAction].filter(
				(a) => !("enabled" in a) || a.enabled
			)}
		>
			<OrderHeader tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} order={form} history={history} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["order", "common"])((props) => {
	return (
		<OrderContextProvider {...props}>
			<EditOrder {...props} />
		</OrderContextProvider>
	);
});
