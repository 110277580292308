import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import getMutableContract from "../Utilities/getMutableContract";
import useQuery from "src/js/hooks/useQuery";
import { formatRowToRead, formatRowToWrite } from "../Utilities/formatRows";
import Toast from "src/js/components/Toast";

type SetFormFunction = (
	form: Partial<FortnoxContractType> | null | ((prevForm: Partial<FortnoxContractType>) => Partial<FortnoxContractType>)
) => void;

type FortnoxContractContextValue = {
	contract: FortnoxContractType | null;
	form: Partial<FortnoxContractType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isFetchingPDF: boolean;
	handleSave: (contract: FortnoxContractType) => any;
	contact: ContactType | null;
	customer: FortnoxCustomerType | null;
	handleCreateInvoiceFromContract: () => any;
	handleFinishContract: () => void;
	isSaving: boolean;
	handleCreatePdfUpload: (invoice: FortnoxContractType, pdfData: any) => any;
	pdfUploads: any;
	isFetchingPDFUploads: boolean;
} & WithTranslation;

const FortnoxContractContext = React.createContext({} as FortnoxContractContextValue);

export const FortnoxContractContextProvider = withTranslation(["fortnox", "common"])(
	({ id: propsId, match, history, children, t, defaultData = null, location }) => {
		const defaultForm = useMemo(() => history?.location?.state?.contract || defaultData || null, [defaultData, history?.location?.state?.contract]);
		const [form, setForm] = useState<Partial<FortnoxContractType> | null>(defaultForm);
		const id = propsId || match.params.id;
		const contactId = match.params.contactId;
		const [isSaving, setIsSaving] = useState(false);

		// Contract
		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/contracts/${id}.json`);

				return res.data;
			} catch (error) {
				Toast.error(error);
				return null;
			}
		}, [id]);
		const queryKey = [id && `fortnox_contract-${id}`].filter(Boolean);
		const enabled = !!(id && queryKey?.length);

		const {
			data: contract = null,
			isFetching,
			refetch: refreshContract,
		}: UseQueryResult<FortnoxContractType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: (enabled && history?.location?.state?.contract) || null,
			enabled,
		});

		//SELLFINITY PDF
		const fetchSellfinityPDFs = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/contracts/${id}/uploads.json`);

				return res.data;
			} catch (error) {
				Toast.error(error);
				console.error("error:", error);

				return null;
			}
		}, [id]);

		const pdfUploadsQueryKey = [id && `fortnox_contract-${id}_pdf_uploads`].filter(Boolean);

		const { data: pdfUploads = null, isFetching: isFetchingPDFUploads }: UseQueryResult<any> = useQuery({
			queryKey: pdfUploadsQueryKey,
			queryFn: fetchSellfinityPDFs,
			refetchOnWindowFocus: false,
			enabled: !!(contract?.DocumentNumber && id),
		});

		const createCRMPdfUpload = useCallback(
			async (contract, pdfData: any = {}) => {
				const contractId = contract.DocumentNumber || id;
				if (contractId) contract.DocumentNumber = contractId;

				const { pages, template, variables, name = `Contract ${contract.DocumentNumber}` } = pdfData;

				const res = await API.post(
					`/api/fortnox/contracts/${contractId}/pdf_template/${template.id}.json`,
					{
						pages: pages.map((page) => page.data),
						name,
					},
					{
						params: variables,
					}
				);

				Toast.success(t("fortnox.responses.pdf_created", "PDF skapad"));

				return res;
			},
			[id, t]
		);

		const createPDF = async ({ contract, pdfData = {} }) => {
			const response = await createCRMPdfUpload(contract, pdfData);
			return response?.data.upload;
		};

		const mutationPdfUploads = useMutation(createPDF);

		const handleCreatePdfUpload = useCallback(
			async (contract: FortnoxContractType, pdfData = {}) => {
				try {
					if (!contract) {
						throw new Error("no contract");
					}

					return await mutationPdfUploads.mutateAsync({ contract, pdfData });
				} catch (e: any) {
					if (!e?.response) throw e;
					Toast.error(e);
				}
			},
			[mutationPdfUploads]
		);

		//----------

		// Contact
		const fetchContact = useCallback(async () => {
			try {
				if (contactId) {
					const res = await API.get(`/api/contacts/${contactId}.json`);
					return res.data.contact;
				} else if (contract?.CustomerNumber) {
					const res = await API.get(`/api/fortnox/customer/${contract?.CustomerNumber}/contact.json`);
					return res.data.contact;
				}

				return null;
			} catch (error) {
				Toast.error(error);
				console.error("error:", error);

				return null;
			}
		}, [contactId, contract?.CustomerNumber]);
		const { data: contact = null }: UseQueryResult<ContactType | null> = useQuery({
			queryKey: [contactId && `contact_${contactId}`, contract?.CustomerNumber && `fortnox_customer_${contract?.CustomerNumber}_contact`].filter(
				Boolean
			),
			queryFn: fetchContact,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.contact || null,
			enabled: !!(contactId || contract?.CustomerNumber),
		});

		// custoemr
		const customerID = contract?.CustomerNumber || contact?.fortnox_customer_id;
		const fetchCustomer = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/customer/${customerID}.json`);

				return res.data?.customer || null;
			} catch (error) {
				Toast.error(error);
				console.error("error:", error);

				return null;
			}
		}, [customerID]);
		const { data: customer = null }: UseQueryResult<FortnoxCustomerType | null> = useQuery({
			queryKey: [customerID && `fortnox_customer_${customerID}`].filter(Boolean),
			queryFn: fetchCustomer,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.customer || null,
			enabled: !!customerID,
		});

		const handleFormatRowsToRead = useCallback((contract: FortnoxContractType) => {
			const rows = contract.InvoiceRows?.map((row) => ({ ...formatRowToRead(row), originalPrice: row.Price }));

			return {
				...contract,
				InvoiceRows: rows,
			};
		}, []);

		const handleFormatRowsToWrite = useCallback((contract: FortnoxContractType) => {
			const rows = contract.InvoiceRows?.map((row) => formatRowToWrite(row));

			return {
				...contract,
				InvoiceRows: rows,
			};
		}, []);

		useEffect(() => {
			if (contract) setForm(handleFormatRowsToRead({ ...defaultForm, ...contract }));
		}, [contract, defaultForm, handleFormatRowsToRead]);

		const saveFunction = useCallback(
			async (contract) => {
				const contractId = contract.DocumentNumber || id;
				if (contractId) {
					contract.DocumentNumber = contractId;
				}
				const mutableContract = getMutableContract(contract);

				const endpoint = contractId ? `/api/fortnox/contracts/${contractId}.json` : `/api/fortnox/contacts/${contactId}/contracts.json`;

				const res = await (contractId ? API.put(endpoint, mutableContract) : API.post(endpoint, mutableContract));

				const successMessage = contractId
					? t("fortnox.responses.contract_saved", "Avtal uppdaterad")
					: t("fortnox.responses.contract_created", "Avtal skapad");

				Toast.success(successMessage);

				if (!contractId) {
					history.replace(`/admin/fortnox/contracts/${res.data.DocumentNumber}`, {
						contract: res.data,
					});
				}

				return res;
			},
			[id, t, history, contactId]
		);
		const queryClient = useQueryClient();

		const update = async ({ contract }) => {
			const response = await saveFunction(contract);
			return response?.data;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			async (contract: FortnoxContractType) => {
				try {
					setIsSaving(true);

					if (!contract) {
						Toast.error("no contract");
						return;
					}

					return await mutation.mutateAsync({ contract: handleFormatRowsToWrite(contract) });
				} catch (error) {
					console.error("handleSave Error", error);
					return contract;
				} finally {
					setIsSaving(false);
				}
			},
			[mutation, handleFormatRowsToWrite]
		);

		const finish = async ({ contract }) => {
			const res = await API.put(`/api/fortnox/contracts/${contract.DocumentNumber}/finish.json`);
			Toast.success(t("fortnox.responses.contract_finised", "Avtal avslutat"));
			return res.data;
		};
		const mutationFinishContract = useMutation(finish, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleFinishContract = useCallback(async () => {
			try {
				return await mutationFinishContract.mutateAsync({ contract });
			} catch (error) {
				console.error("handleFinishContract Error", error);
				return contract;
			}
		}, [contract, mutationFinishContract]);

		const handleCreateInvoiceFromContract = useCallback(async () => {
			try {
				if (!contract?.DocumentNumber) {
					Toast.error("no contract");
					return;
				}
				const res = await API.put(`/api/fortnox/contracts/${contract.DocumentNumber}/invoice.json`); // This is the created invoice, hence why we need to call the refreshContract() function to get the updated contract
				refreshContract();

				Toast.success(t("fortnox.responses.invoice_created", "Fakturan skapad"));
				history.push(`/admin/fortnox/invoices/${res.data.DocumentNumber}`);

				return res;
			} catch (error) {
				Toast.error(error);
				console.error("error:", error);

				return null;
			}
		}, [contract?.DocumentNumber, history, t, refreshContract]);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				contact,
				customer,
				handleCreateInvoiceFromContract,
				contract,
				handleFinishContract,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			}),
			[
				isFetching,
				t,
				id,
				form,
				handleSave,
				setForm,
				contact,
				customer,
				handleCreateInvoiceFromContract,
				contract,
				handleFinishContract,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			]
		);

		return useMemo(() => <FortnoxContractContext value={value}>{children}</FortnoxContractContext>, [value, children]);
	}
);
export default FortnoxContractContext;
