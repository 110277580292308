import React, { Component } from "react";
import { Text, Icon } from "@shopify/polaris";
import { AttachmentMajor } from "@shopify/polaris-icons";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Truncate from "src/js/components/Truncate";
import Badge from "src/js/components/Badge";
import { withRouter } from "react-router-dom";
import MemberAvatar from "./MemberAvatar";
import { store } from "../../../store/index";
import RemoveCircleButton from "../../../components/RemoveCircleButton";
import BoardHelper from "../BoardHelper";
import BoardUploadScriveIntegration from "../../Scrive/BoardUploadScriveIntegration";
import Toast from "src/js/components/Toast";
import Spinner from "src/js/components/Spinner";

export const FIRST_COLUMN_WIDTH = "50px";

class BoardUploadSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate() {
		$(".editor-sheet-close-btn").closest("[data-portal-id]").addClass("sheet-top");
	}

	getContact() {
		const contactColumns = this.props.board && this.props.board.columns && this.props.board.columns.filter((column) => column.type === "contact");

		const contactValues =
			contactColumns &&
			contactColumns.reduce((acc, col) => {
				if (this.props.row && col.id in this.props.row.column_values) {
					for (let i = 0; i < this.props.row.column_values[col.id].value.length; i++) {
						const contactId = this.props.row.column_values[col.id].value[i];
						if (!acc.includes(contactId)) {
							acc.push(contactId);
						}
					}
				}
				return acc;
			}, []);

		const contact = contactValues.length > 0 && BoardHelper.getContact(contactValues[0]) && BoardHelper.getContact(contactValues[0]);

		return contact;
	}

	removeFile(file) {
		const files = this.props.values.filter((f) => f.id !== file.id);
		this.props.onChange(files, file);
		Toast.success(this.props.t("board.upload.responses.removed", "Fil borttagen"));
	}

	updateFile(file, push = true) {
		const foundIndex = this.props.values.findIndex((f) => f.id === file.id);
		this.props.values[foundIndex] = file;
		if (push && this.props.onChange) this.props.onChange(this.props.values, file);
	}

	render() {
		const contact = this.getContact();
		const { file } = this.props;

		return (
			<StyledDocumentItem
				onClick={() => {
					if (file.fortnox_invoice_id) this.props.history.push(`/admin/fortnox/invoices/${file.fortnox_invoice_id}`);
					else if (file.fortnox_order_id) this.props.history.push(`/admin/fortnox/orders/${file.fortnox_order_id}`);
					else if (file.fortnox_offer_id) this.props.history.push(`/admin/fortnox/offers/${file.fortnox_offer_id}`);
				}}
			>
				{this.props.onChange && (
					<RemoveCircleButton
						title={`${this.props.t("board.upload.actions.remove", "Ta bort fil")} ${file.filename || file.name}?`}
						onRemove={this.removeFile.bind(this, file)}
					/>
				)}

				<DocumentInfo>
					<MemberAvatar
						style={{
							alignSelf: "center",
						}}
						user={store.getState().users.find((user) => user.id == file.user_id)}
					/>

					<div style={{ display: "flex", flexDirection: "column" }}>
						<div
							style={{
								display: "flex",
								gap: "0.35rem",
								alignItems: "center",
							}}
						>
							{!file.id && !file.data?.startsWith("http") && <Spinner size="small" />}
							<DocumentTitle>
								<Truncate numberOfLines={1}>{file.name || file.filename}</Truncate>
							</DocumentTitle>
							<Text variant="bodySm">
								{file.size > 1048576 ? (
									<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{(file.size / 1048576).toFixed(1) || "-"} MB</span>
								) : (
									<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{Math.ceil(file.size / 1024) || "-"} kB</span>
								)}
							</Text>
							{file?.fortnox_invoice_data?.Cancelled && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/invoices/${file.fortnox_invoice_id}`)} status="warning">
									{this.props.t("board.upload.fortnox.invoice.cancelled", "Faktura avbruten")}
								</Badge>
							)}

							{file?.fortnox_order_data?.Cancelled && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/orders/${file.fortnox_order_id}`)} status="warning">
									{this.props.t("board.upload.fortnox.order.cancelled", "Order avbruten")}
								</Badge>
							)}
							{file?.fortnox_order_data?.InvoiceReference && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/invoices/${file?.fortnox_order_data?.InvoiceReference}`)} status="info">
									{this.props.t("board.upload.fortnox.invoice.created", "Faktura skapad")}
								</Badge>
							)}

							{file?.fortnox_offer_data?.Cancelled && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/offers/${file.fortnox_offer_id}`)} status="warning">
									{this.props.t("board.upload.fortnox.offer.cancelled", "Offert avbruten")}
								</Badge>
							)}

							{file?.fortnox_offer_data?.InvoiceReference && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/invoices/${file?.fortnox_offer_data?.InvoiceReference}`)} status="info">
									{this.props.t("board.upload.fortnox.invoice.created", "Faktura skapad")}
								</Badge>
							)}

							{file?.fortnox_offer_data?.OrderReference && (
								<Badge onClick={() => this.props.history.push(`/admin/fortnox/orders/${file?.fortnox_offer_data?.OrderReference}`)} status="warning">
									{this.props.t("board.upload.fortnox.offer.cancelled", "Order skapad")}
								</Badge>
							)}
						</div>

						<a
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								window.open(file.url, file.id, "noopener noreferrer");
							}}
							className="img_link"
						>
							<Icon source={AttachmentMajor} /> {this.props.t("board.upload.actions.show_fil", "Visa fil")}
						</a>

						{contact && (
							<StyledExtraRow style={{}}>
								<span>{this.props.t("board.upload.terms.contact", "Kontakt")}: </span>
								{contact.fullname}
							</StyledExtraRow>
						)}
						{this.props.row && this.props.showRow && (
							<StyledExtraRow>
								<span>{this.props.t("board.upload.terms.row_name", "Radnamn")}: </span> {this.props.row.title}
							</StyledExtraRow>
						)}
					</div>

					<div style={{ display: "flex", flexDirection: "column" }}>
						{file.created_at && (
							<StyledExtraRow style={{}}>
								<span>{this.props.t("board.upload.terms.created", "Skapad")}: </span>
								{moment(file.created_at).format("YYYY-MM-DD")}
							</StyledExtraRow>
						)}
					</div>
				</DocumentInfo>
				<IntegrationsWrapper>
					<BoardUploadScriveIntegration
						row={this.props.row}
						column={this.props.column}
						file={file}
						values={this.props.values}
						contact={contact}
						history={this.props.history}
						updateFile={this.updateFile.bind(this)}
					/>
				</IntegrationsWrapper>
			</StyledDocumentItem>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(withRouter(BoardUploadSheet));

const pulseFilterBrightness = keyframes`
  0% {filter: brightness(1)}
  40% {filter: brightness(1.25)}
  100% {filter: brightness(1)}
`;

export const StyledDocumentItem = styled.div.attrs({
	className: "styled_document_item",
})`
	padding: 0.9375rem 1.25rem;
	position: relative;
	width: calc(100% - 25px);
	background-color: var(--main1);
	border-radius: 6px;
	transition: filter 250ms;
	cursor: pointer;
	margin: 0.3125rem 0;

	&:hover {
		filter: brightness(1.2);
	}

	&:empty {
		animation: ${pulseFilterBrightness} 2s linear infinite;
		cursor: wait;
	}

	.img_link {
		opacity: 0.7;
		font-size: 12px;
		display: flex;
		gap: 4px;
		align-items: center;
		width: max-content;

		.Polaris-Icon {
			height: 14px;
			width: 14px;
		}
	}
`;
const DocumentInfo = styled.div`
	display: grid;
	grid-template-columns: 50px minmax(10%, 100%) max-content;
	gap: 1.25rem;

	&& {
		.Polaris-Avatar {
			width: 50px !important;
		}
	}
`;

const DocumentTitle = styled.p`
	font-weight: 600;
	overflow: hidden;
`;

export const StyledExtraRow = styled.div`
	font-size: 12px;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;

	span:first-of-type {
		color: rgb(150, 150, 150);
		margin-right: 1ch;
	}
`;

const IntegrationsWrapper = styled.div`
	display: flex;
	gap: 0.625rem;
	flex-direction: column;
	padding: 0.625rem;
`;
