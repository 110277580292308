import React, { useState, createContext, useMemo, useCallback, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import { store } from "src/js/store";
import API from "src/js/API";
import { formatDeadlineRows, groupDeadlineRowsByDateAndStatus } from "../Workspaces/boardutils/BoardUtils";
import BoardHelper from "../Workspaces/BoardHelper";
import RowModal from "../Workspaces/components/RowModal";
import TaskSheet from "./TaskSheet";
import Toast from "src/js/components/Toast";
import { useEventListener } from "@shopify/polaris";

const getPreviousWeekYear = (week: number, year: number) => {
	const previousWeek = moment().isoWeek(week).set("year", year).subtract(1, "week").isoWeek();
	const previousYear = moment().isoWeek(week).set("year", year).subtract(1, "week").year();
	return { week: previousWeek, year: previousYear };
};
const getNextWeekYear = (week: number, year: number) => {
	const nextWeek = moment().isoWeek(week).set("year", year).add(1, "week").isoWeek();
	const nextYear = moment().isoWeek(week).set("year", year).add(1, "week").year();
	return { week: nextWeek, year: nextYear };
};

type WeeklyTasksContextType = {
	currentWeek: any;
	loading: boolean;
	next: () => void;
	prev: () => void;
	week: number;
	year: number;
	selection: string[];
	setSelection: (selection: string[] | any) => void;
	refresh: () => void;
	history: any;
	onClose: () => void;
	onOpen: (row?: BoardRowType | null, initialTabIndex?: number) => void;
	calendar: any;
	totalLateTasksCount: number;
};

export const WeeklyTasksContext = createContext({} as unknown as WeeklyTasksContextType);

export const WeeklyTasksProvider = ({ children, history, calendar }) => {
	const [week, setWeek] = useState(moment().isoWeek());
	const [year, setYear] = useState(moment().year());
	const controller = useRef(new AbortController());
	const [loading, setLoading] = useState(false);
	const [currentWeek, setCurrentWeek] = useState<any>({ rows: [], boards: [] });
	const [selection, setSelection] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [row, setRow] = useState<BoardRowType | null>(null);
	const [initialTabIndex, setInitialTabIndex] = useState<number | null>(null);
	const [totalLateTasksCount, setTotalLateTasksCount] = useState(0);

	const fetchTotalLateTasksCount = useCallback(async () => {
		const params = {
			assigned_user_id: store.getState().user.id,
			not_board_id: store.getState().user.calendar_board?.id || null,
			not_board_ids: store.getState().user.excluded_boards,
			really_return_count: 1,
			// return_count: true,
			deadline_statuses: 0,
			dates_from: moment(-1).format("YYYY-MM-DD"),
			dates_to: moment().add(-1, "days").format("YYYY-MM-DD"),
		};

		return API.get("/api/board_rows/search.json", {
			params,
		})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				setTotalLateTasksCount(result.data);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				Toast.error(error);
			});
	}, []);

	const fetchWeek = useCallback(async ({ week, year }: { week: number; year: number }) => {
		const startOfWeek = moment().isoWeek(week).set("year", year).startOf("isoWeek").format("YYYY-MM-DD");
		const endOfWeek = moment().isoWeek(week).set("year", year).endOf("isoWeek").format("YYYY-MM-DD");

		const calenderBoardID = store.getState().user.calendar_board && store.getState().user.calendar_board.id;
		const params = {
			assigned_user_id: store.getState().user.id,
			not_board_id: calenderBoardID,
			not_board_ids: store.getState().user.excluded_boards,
			return_count: true,

			dates_from: startOfWeek,
			dates_to: endOfWeek,
		};

		return API.get("/api/board_rows/search.json", {
			signal: controller.current.signal,
			params,
		})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				BoardHelper.fetchResources("linked_rows", result.data.linked_row_ids);
				BoardHelper.fetchResources("contacts", result.data.contact_ids);
				BoardHelper.fetchResources("tickets", result.data.ticket_ids);
				store.dispatch({ type: "SET_BOARDS", boards: result.data.boards });
				store.dispatch({ type: "SET_BOARD_GROUPS", groups: result.data.groups });

				// store.dispatch({ type: "SET_BOARD_ROWS", rows: result.data.rows });
				store.dispatch({
					type: "SET_BOARD_ROWS",
					rows: result.data.rows.concat(Object.values(store.getState().board_rows)),
				});

				return { ...result.data, week, startOfWeek, endOfWeek };
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				Toast.error(error);
			});
	}, []);

	const fetch = useCallback(
		async ({ week, year, silent = false }) => {
			if (!silent) setLoading(true);
			setWeek(week);
			setYear(year);

			controller.current.abort();
			controller.current = new AbortController();

			fetchWeek({ week, year })
				.then((currentWeek: any) => {
					if (currentWeek) setCurrentWeek(currentWeek);
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						return;
					}

					Toast.error(error);
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[fetchWeek]
	);

	const refresh = useCallback(
		(refreshOther = true) => {
			fetch({ week, year, silent: true });
			fetchTotalLateTasksCount();
			if (refreshOther) {
				window.dispatchEvent(new CustomEvent("refreshGoals"));
			}
		},
		[fetch, week, year, fetchTotalLateTasksCount]
	);

	useEffect(() => {
		fetchTotalLateTasksCount();
	}, [fetchTotalLateTasksCount]);

	useEffect(() => {
		fetch({ week: moment().isoWeek(), year: moment().year() });

		return () => {
			controller.current.abort();
		};
	}, [fetch]);

	useEventListener("refreshTasks" as any, refresh);

	const next = useCallback(() => {
		fetch(getNextWeekYear(week, year));
	}, [fetch, week, year]);

	const prev = useCallback(() => {
		fetch(getPreviousWeekYear(week, year));
	}, [fetch, week, year]);

	const handleClose = useCallback(() => {
		setRow(null);
		setInitialTabIndex(null);
		setOpen(false);
	}, []);

	const handleOpen = useCallback((row: BoardRowType | null = null, initialTabIndex = 0) => {
		setRow(row);
		setInitialTabIndex(initialTabIndex);
		setOpen(true);
	}, []);

	const updateRow = useCallback(async (row: BoardRowType | null) => {
		setRow(row?.removed ? null : row);

		if (row) {
			setCurrentWeek((currentWeek) => {
				const index = currentWeek?.rows?.findIndex((r: DeadlineBoardRowType) => r.id === row.id);
				if (index === -1) return currentWeek;

				const newRows = [...currentWeek.rows];
				if (row.removed) {
					newRows.splice(index, 1);
				} else {
					newRows.splice(index, 1, row);
				}

				return { ...currentWeek, rows: newRows };
			});
		}
	}, []);

	const onUpdateValue = useCallback(
		async (row: BoardRowType, column: BoardColumnType, data: any) => {
			const result = await BoardHelper.onUpdateValue(row, column, data);
			if (result?.row) updateRow(result.row);
		},
		[updateRow]
	);

	const currentWeekData = groupDeadlineRowsByDateAndStatus(formatDeadlineRows({ rows: [...currentWeek.rows], boards: currentWeek.boards }));

	const contextValues = useMemo<WeeklyTasksContextType>(
		() => ({
			currentWeek: currentWeekData,
			refresh,
			loading,
			next,
			prev,
			week,
			year,
			selection,
			setSelection,
			history,
			onClose: handleClose,
			onOpen: handleOpen,
			calendar,
			totalLateTasksCount,
		}),
		[
			currentWeekData,
			loading,
			next,
			prev,
			week,
			year,
			selection,
			setSelection,
			refresh,
			history,
			handleClose,
			handleOpen,
			calendar,
			totalLateTasksCount,
		]
	);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const board = (row && (store.getState().boards[row?.board_id] || row.board)) || store.getState().boards[store.getState().user.todo_board_id];
	const groups = Object.values(store.getState().board_groups).filter((group: any) => group.board_id == board?.id);

	const todoboardsIds = [store.getState().user.todo_board_id, store.getState().account.todo_board_id];
	const rowModalOpen = open && row?.id && !todoboardsIds.includes(row?.board_id);
	const taskSheetOpen = open && (!row?.board_id || todoboardsIds.includes(row?.board_id));

	return (
		<WeeklyTasksContext value={contextValues}>
			{children}

			<RowModal
				open={rowModalOpen}
				row={row}
				board={board}
				groups={groups}
				onUpdateValue={onUpdateValue}
				defaultValues={[]}
				getContact={() => {}}
				onClose={() => {
					handleClose();
					refresh();
				}}
				onUpdateRow={(row: BoardRowType) => {
					updateRow(row);
				}}
				// refreshCalendar={this.props.refreshCalendar}
				refreshTodo={refresh}
				initialTabIndex={initialTabIndex}
			/>

			<TaskSheet
				open={!!taskSheetOpen}
				row={(row || null) as BoardRowType | any}
				// board={board}
				onClose={() => {
					handleClose();
					refresh();
				}}
				onComplete={(row: BoardRowType) => {
					updateRow(row);
					handleClose();
					refresh();
				}}
			/>
		</WeeklyTasksContext>
	);
};
export default WeeklyTasksProvider;
