import React, { useEffect, useState } from "react";
import { Collapsible as PolarisCollapsible } from "@shopify/polaris";

type CollapsibleProps = {
	children: React.ReactNode;
	open?: boolean;
	activator?: React.ReactElement | ((open: boolean) => React.ReactElement);
	id?: string;
};

const Collapsible = ({ children, open: PolarisOpen, activator, ...rest }: CollapsibleProps) => {
	const [open, setOpen] = useState(PolarisOpen || false);

	useEffect(() => {
		setOpen(PolarisOpen || false);
	}, [PolarisOpen]);

	const toggleOpen = () => {
		setOpen(!open);
	};

	const activatorElement =
		activator &&
		React.cloneElement(typeof activator === "function" ? activator(open) : activator, {
			onClick: toggleOpen,
			open,
		} as React.HTMLAttributes<HTMLElement>);

	return (
		<>
			{activatorElement}
			<PolarisCollapsible id={rest.id || ""} open={open} {...rest}>
				{children}
			</PolarisCollapsible>
		</>
	);
};
export default Collapsible;
