import { Checkbox, FormLayout } from "@shopify/polaris";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Button from "src/js/components/Button";
import ActionButton from "src/js/components/page/ActionButton";
import TargetContext from "../Context/TargetContext";
import TextField from "src/js/components/TextField";
import { Trans } from "react-i18next";
import { numberFormatter } from "src/js/Utilities";
import debounce from "lodash/debounce";

const useTargetValuesBulkActions = () => {
	const { setForm, form, t } = useContext(TargetContext);

	const formatValueToWrite = useCallback((number: any): any => {
		return parseFloat((number || 0).toString().replace(/,/g, ".").replace(/\s/g, ""));
	}, []);

	const formatValueToRead = useCallback(
		(number: any, decimals = form.number_of_decimals): string => {
			return numberFormatter({
				value: formatValueToWrite(String(number || 0)),
				minimumFractionDigits: decimals || 0,
				maximumFractionDigits: decimals || 2,
				removeCurrencySymbol: true,
			});
		},
		[formatValueToWrite, form.number_of_decimals]
	);

	const [selectedTargetConnectionIds, setSelectedTargetConnectionIds] = useState<number[]>([]);
	const [selectedPeriodIndexs, setSelectedPeriodIndexs] = useState<number[]>([]);
	const [value, setValue] = useState("");
	const [operation, setOperation] = useState<"divide_columns_and_users" | "divide_columns" | "divide_user_columns" | null>(null);

	const formatValue = useMemo(
		() =>
			debounce((value: string) => {
				setValue(formatValueToRead(value, 0));
			}, 500),
		[formatValueToRead]
	);

	const onChangeValue = useCallback(
		(value: string) => {
			setValue(value);
			formatValue(value);
		},
		[formatValue]
	);

	const onToggleSelectedTargetConnection = (id) => (checked) => {
		setSelectedTargetConnectionIds((prev) => {
			if (checked) {
				return [...prev, id];
			}
			return prev.filter((i) => i !== id);
		});
	};

	const onToggleSelectedPeriod = (id) => (checked) => {
		setSelectedPeriodIndexs((prev) => {
			if (checked) {
				return [...prev, id];
			}
			return prev.filter((i) => i !== id);
		});
	};

	const toggleAllTargetConnections = useCallback(
		(checked) => {
			if (checked) {
				setSelectedTargetConnectionIds(form.target_connections!.map((tc) => tc.id));
			} else {
				setSelectedTargetConnectionIds([]);
			}
		},
		[form.target_connections]
	);

	const toggleAllPeriods = (checked) => {
		if (checked) {
			setSelectedPeriodIndexs(form.periods!.map((p) => p.index) || []);
		} else {
			setSelectedPeriodIndexs([]);
		}
	};

	const getCalculatedValue = useCallback(
		(v: any = value, oper = operation) => {
			switch (oper) {
				case "divide_columns_and_users":
					return +v / selectedPeriodIndexs.length / selectedTargetConnectionIds.length;
				case "divide_columns":
					return +v / selectedPeriodIndexs.length;
				case "divide_user_columns":
					return +v / selectedTargetConnectionIds.length;
				default:
					return +v;
			}
		},
		[value, selectedPeriodIndexs, selectedTargetConnectionIds, operation]
	);

	const setValueOnEachPersonAndEachPeriod = useCallback(() => {
		const newForm = { ...form } as TargetType;
		const targetConnections = form.target_connections?.filter((tc) => selectedTargetConnectionIds.includes(tc.id));
		const v = formatValueToWrite(value);

		const calculatedValue = formatValueToRead(getCalculatedValue(v).toFixed(form.number_of_decimals || 0));

		targetConnections!.forEach((tc) => {
			form?.periods?.forEach((col, index) => {
				const taragetConnectionValue = tc.target_connection_values[index];
				if (typeof col.index !== "number" || !selectedPeriodIndexs.includes(col.index)) {
					return;
				}

				tc.target_connection_values[index] = {
					...taragetConnectionValue,
					value: calculatedValue,
				};
			});
		});

		setForm(newForm);
		setValue("");
	}, [selectedPeriodIndexs, setForm, form, selectedTargetConnectionIds, value, formatValueToWrite, getCalculatedValue, formatValueToRead]);

	const bulkActions = useMemo(() => {
		const allCheckStatus =
			selectedTargetConnectionIds?.length === form.target_connections?.length || (selectedTargetConnectionIds?.length ? "indeterminate" : false);

		const ar = [
			<Button key="selected" onClick={() => toggleAllTargetConnections(!!(allCheckStatus === "indeterminate" || !allCheckStatus))}>
				<div style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center" }}>
					<Checkbox label="" labelHidden checked={allCheckStatus} />
					{selectedTargetConnectionIds?.length
						? t("common.selected", "{{count}} markerad", { count: selectedTargetConnectionIds.length })
						: "Markera alla"}
				</div>
			</Button>,
		];

		if (selectedTargetConnectionIds?.length || selectedPeriodIndexs?.length) {
			ar.push(
				<ActionButton
					item={{
						disabled: !selectedTargetConnectionIds?.length || !selectedPeriodIndexs?.length,
						content: t("targets.values.bulkactions.setvalues", "Sätt värden"),
						plain: false,
						onAction: setValueOnEachPersonAndEachPeriod,
						confirmation: {
							title: t("targets.values.bulkactions.setvalues", "Sätt värden"),
							onClose: () => setValue(""),
							content: (
								<FormLayout>
									<Trans
										i18nKey="targets.values.selected"
										defaults="Sätt värden för {{count}} användare och för perioder {{periods}}"
										components={{
											b: <span style={{ fontWeight: 700 }} />,
										}}
										values={{
											count: selectedTargetConnectionIds?.length,
											periods: form.periods
												?.filter((p) => selectedPeriodIndexs.includes(p.index))
												.map((p) => p.label)
												.join(", "),
										}}
									/>

									<Checkbox
										label={"Sätt värdet på alla kolumner och användare"}
										helpText={
											<div>
												<p>
													Värde({value}) = {formatValueToRead(getCalculatedValue(+formatValueToWrite(value), null))}
												</p>
											</div>
										}
										checked={operation === null}
										onChange={(checked) => setOperation(checked ? null : null)}
									/>
									<Checkbox
										label={
											<span>
												Dela värdet på alla kolumner <b>och</b> användare
											</span>
										}
										helpText={
											<div>
												<p>
													Värde({value}) / antal kolumner({selectedPeriodIndexs?.length}) / antal användare({selectedTargetConnectionIds?.length}) ={" "}
													{formatValueToRead(getCalculatedValue(+formatValueToWrite(value), "divide_columns_and_users"))}
												</p>
											</div>
										}
										checked={operation === "divide_columns_and_users"}
										onChange={(checked) => setOperation(checked ? "divide_columns_and_users" : null)}
									/>
									<Checkbox
										label={
											<span>
												Dela värdet på alla kolumner <b>per</b> användare
											</span>
										}
										helpText={
											<div>
												<p>
													Värde({value}) / antal kolumner({selectedPeriodIndexs.length}) ={" "}
													{formatValueToRead(getCalculatedValue(+formatValueToWrite(value), "divide_columns"))}
												</p>
											</div>
										}
										checked={operation === "divide_columns"}
										onChange={(checked) => setOperation(checked ? "divide_columns" : null)}
									/>
									<Checkbox
										label={
											<span>
												Dela värdet över en <b>period</b> för alla användare
											</span>
										}
										helpText={
											<div>
												<p>
													Värde({value}) / antal personer({selectedTargetConnectionIds.length}) ={" "}
													{formatValueToRead(getCalculatedValue(+formatValueToWrite(value), "divide_user_columns"))}
												</p>
											</div>
										}
										checked={operation === "divide_user_columns"}
										onChange={(checked) => setOperation(checked ? "divide_user_columns" : null)}
									/>

									<TextField label={t("targets.values.value", "Värde")} value={value} onChange={onChangeValue} />
								</FormLayout>
							),
							confirm: t("targets.values.bulkactions.setvalues", "Sätt värden"),
							// type: "popup",
						},
					}}
				/>
			);
		}

		return ar;
	}, [
		selectedTargetConnectionIds?.length,
		t,
		toggleAllTargetConnections,
		form.target_connections?.length,
		setValueOnEachPersonAndEachPeriod,
		value,
		form.periods,
		selectedPeriodIndexs,
		operation,
		formatValueToRead,
		formatValueToWrite,
		onChangeValue,
		getCalculatedValue,
	]);

	return {
		selectedTargetConnectionIds,
		selectedPeriodIndexs,
		onToggleSelectedTargetConnection,
		onToggleSelectedPeriod,
		toggleAllTargetConnections,
		toggleAllPeriods,

		bulkActions,
	};
};
export default useTargetValuesBulkActions;
