import styled from "styled-components";

export const Wrapper = styled.div`
	/* display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center; */
`;

// export const InnerWrapper = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	gap: 1.5rem;
// 	justify-content: center;
// 	align-items: center;

// 	h1,
// 	h2,
// 	h3,
// 	h4,
// 	h5,
// 	p,
// 	span {
// 		width: max-content;
// 	}
// `;

export const IframeWrapper = styled.div`
	height: calc(100vh - 56px);
	background: none;
	border: none;
	width: 100%;
	position: absolute;
	top: 0px;
	/* width: 100vw; */
	/* margin-top: -200px; */
`;

export const Iframe = styled.iframe`
	background: none;
	border: none;
	position: absolute;
	/* margin-top: -75px; */
	height: 100%;
	/* height: calc(100% + 75px); */
	width: 100%;
	/* width: 100vw; */
	/* margin-top: -200px; */
`;

export const InnerWrapper = styled.div`
	position: relative;
	/* overflow: hidden; */

	.Polaris-Page {
		/* padding: 0 1.5rem 0; */
		padding: 0;

		> .Polaris-Box {
			padding-left: var(--p-space-6);
			z-index: 2;
		}
	}
`;
