import React from "react";
import { useProspectContext } from "../../../../Context/ProspectContext";
import styled from "styled-components";
import CustomCard from "src/js/components/CustomCard";
import Details from "src/js/components/Details";

const ProspectContactCard = () => {
	const { company, t } = useProspectContext();

	return (
		<CustomCard title={t("prospects.subview.ProspectContactCard.title", "Om företaget")} subdued collapsible sectioned>
			<Wrapper>
				<Name>{company?.name}</Name>
				<Description>{company?.description}</Description>
				<Details
					gap={{
						horizontal: "3rem",
						vertical: "0.5rem",
					}}
					items={[
						{
							label: t("prospects.fields.org_number.label.", "Organisationsnummer"),
							value: company?.org_number,
						},
						{
							label: t("prospects.fields.established.label", "Registrerad"),
							value: company?.established,
						},
						{
							label: t("prospects.fields.latest_financial.employees.label", "Antal anställda"),
							value: company?.latest_financial?.employees ?? "-",
						},
						...(company?.phone_numbers?.map(({ formatted_phone_number: phoneNumber }, index) => ({
							label: t("prospects.fields.phone_number.label", "Telefonnummer {{index}}", { index: index + 1 }),
							value: phoneNumber,
							type: "phone",
						})) || []),
						...(company?.links?.map(({ url, label }) => ({
							label,
							value: url,
							href: url,
						})) || []),
						{
							label: t("prospects.fields.mobile_phone_numbers_count.label", "Antal mobiltelefonnummer"),
							value: company?.mobile_phone_numbers_count ?? "-",
						},
						{
							label: t("prospects.fields.operators.label", "Telefonoperatörer"),
							value: company?.operators?.map((operator) => operator?.title)?.join(", ") ?? "-",
						},
						{
							label: t("prospects.fields.workplaces_count.label", "Antal arbetsplatser"),
							value: company?.workplaces?.length ?? "-",
						},
						{
							label: t("prospects.fields.vehicles_count.label", "Antal fordon"),
							value: company?.vehicles_count ?? "-",
						},
					]}
				/>
			</Wrapper>
		</CustomCard>
	);
};
export default ProspectContactCard;

const Wrapper = styled.div``;
const Name = styled.h3`
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 1rem;
`;
const Description = styled.p`
	margin-bottom: 1rem;
	min-width: 300px;
	width: 75%;

	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
`;
