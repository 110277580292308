import React from "react";
import { InnerWrapper, Iframe, IframeWrapper } from "../styles";
import Page from "src/js/components/page";

const ProspectLicense = ({ licenseProvider }) => {
	return (
		<InnerWrapper>
			<Page>
				<IframeWrapper>
					{/* maybe even move the url to the database and make this a general view. So if the licenseprovider have an Iframeurl, we use his component. */}
					<Iframe title={licenseProvider?.title} src="https://www.sellfinity.com/prospektering-ai-teaser/" width="100%" height="100%" />
				</IframeWrapper>
			</Page>
		</InnerWrapper>
	);
};
export default ProspectLicense;
