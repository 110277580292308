import { RangeSlider as PolarisRangeSlider } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";

const RangeSlider = ({ value, onChange, ...rest }) => {
	const [tempValue, setTempValue] = useState(value);

	useEffect(() => {
		setTempValue(value);
	}, [value]);

	const handleChange = useCallback((newValue) => setTempValue(newValue), []);
	const handleOnBlur = useCallback(() => onChange(tempValue), [tempValue, onChange]);

	return (
		<div onMouseUp={handleOnBlur}>
			<PolarisRangeSlider label="" labelHidden value={value} onChange={handleChange} {...rest} onBlur={handleOnBlur} />
		</div>
	);
};

export default RangeSlider;
