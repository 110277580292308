import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import Select from "src/js/components/select/Select";
import { useProspectContext } from "../../Context/ProspectContext";
import SelectSearchField from "src/js/components/SelectSearchField";

const ImportProspectModal = ({ onClose, isOpen, prospectId }) => {
	const { handleImportToContact, handleImportToContactAndAsBoardrowInSalesBoard, handleImportToCallList } = useProspectContext();
	const [comment, setComment] = useState("");
	const [board, setBoard] = useState<any>();
	const { t } = useTranslation(["prospects", "common"]);
	const importMethodsOptions = useMemo(
		() => [
			{
				label: t("prospects.actions.import.modal.importContact", "Importera kontakt"),
				value: "handleImportToContact",
			},
			{
				label: t("prospects.actions.import.modal.importContactAndCreateDeal", "Importera kontakt och skapa affär"),
				value: "handleImportToContactAndAsBoardrowInSalesBoard",
			},
			// {
			// 	label: t("prospects.actions.import.modal.importContactToCallList", "Importera kontakt till ringlista"),
			// 	value: "handleImportToCallList",
			// },
		],
		[t]
	);

	const [importMethod, setImportMethod] = useState(importMethodsOptions[0].value);

	useEffect(() => {
		if (!isOpen) {
			setComment("");
			setBoard(null);
		}
	}, [isOpen]);

	const handleOnClose = () => {
		onClose();
	};

	const handleOnConfirm = async () => {
		switch (importMethod) {
			case "handleImportToContact":
				return await handleImportToContact(prospectId, { comment });
			case "handleImportToContactAndAsBoardrowInSalesBoard":
				return await handleImportToContactAndAsBoardrowInSalesBoard(prospectId, { comment });
			case "handleImportToCallList":
				return await handleImportToCallList(prospectId, { comment });
			default:
				break;
		}

		// await handleLikeActionDirectly(prospectId);
	};

	const disabled = importMethod === "handleImportToCallList" && !board;

	return (
		<Modal
			open={isOpen}
			title={t("prospects.actions.import.modal.title", "Importera prospekt")}
			onClose={handleOnClose}
			primaryAction={{
				content: t("prospects.actions.import.modal.confirm", "Importera") as string,
				onAction: handleOnConfirm,
				disabled,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: handleOnClose,
				},
			]}
			sectioned
		>
			<FormLayout>
				<Select
					value={importMethod}
					onChange={setImportMethod}
					label={t("prospects.actions.import.modal.selectCompany", "Välj företag")}
					options={importMethodsOptions}
				/>

				<TextField
					label="Kommentar"
					value={comment}
					onChange={setComment}
					placeholder={t("prospects.actions.import.modal.commentPlaceholder", "Kommentar") as string}
					helpText={t("prospects.actions.import.modal.commentHelpText", "En kommentar som sparas på kundkortet & prospekten") as string}
					multiline={2}
				/>

				{importMethod === "handleImportToCallList" && (
					<SelectSearchField
						label={t("prospects.actions.import.modal.selectBoard", "Välj board") as string}
						placeholder={t("prospects.actions.import.modal.selectBoardPlaceholder", "Välj board") as string}
						onSelect={(board) => {
							setBoard(board);
						}}
						value={board?.id}
						resourceName={{ singular: "Board", plural: "Boards" }}
						resource="boards"
						resourceHandle="boards"
						resourceURL="/api/boards/search.json"
						labelHandle="title"
						onClear={() => setBoard(null)}
						renderValue={(value, data) => {
							return data?.find((item) => item.id == value)?.title || (value === board?.id ? board?.title : value);
						}}
					/>
				)}

				{/* <Checkbox
					label={t("prospects.actions.import.modal.createDeal", "Skapa affär")}
					checked={!!createdDeal}
					onChange={(newChecked) => {
						setCreatedDeal(newChecked);
					}}
				/> */}
			</FormLayout>
		</Modal>
	);
};
export default ImportProspectModal;
