import { Icon } from "@shopify/polaris";
import { ProfileMajor, ThumbsDownMinor, ThumbsUpMinor } from "@shopify/polaris-icons";
import Colors from "src/js/Colors";
import { StatsBoxItem } from "src/js/components/statsBoxes/types";

const getStatsBoxes = (stats, isLoadingStats, t): StatsBoxItem[] => {
	return [
		{
			title: t("prospects.stats.total_count", "Antal prospekt"),
			text: `${stats?.total_count || "-"}st hämtade av ${stats?.axicon_companies_not_in_prospect_list_count || "-"}st`,
			icon: <Icon source={ProfileMajor} />,
			loading: isLoadingStats,
		},
		{
			title: t("prospects.stats.qualified_count", "Antal kvalificerade"),
			text: `${stats?.qualified_count || "-"}st`,
			icon: <Icon source={ThumbsUpMinor} />,
			loading: isLoadingStats,
			color: Colors.green,
		},
		{
			title: t("prospects.stats.denied_count", "Antal ignorerade"),
			text: `${stats?.denied_count || "-"}st`,
			icon: <Icon source={ThumbsDownMinor} />,
			loading: isLoadingStats,
			color: Colors.red,
		},
		{
			title: t("prospects.stats.unhandled_count", "Antal ej bearbetade"),
			text: `${stats?.unhandled_count || "-"}st`,
			icon: <Icon source={ProfileMajor} />,
			loading: isLoadingStats,
			color: Colors.blue,
		},
	];
};

export default getStatsBoxes;
