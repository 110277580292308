import React, { useEffect, useState } from "react";
import Sheet from "src/js/components/sheet";
import { useProspectContext } from "../Context/ProspectContext";
import TextField from "src/js/components/TextField";
import { FormLayout } from "@shopify/polaris";
import styled from "styled-components";
import SelectSearchField from "src/js/components/SelectSearchField";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import { store } from "src/js/store";

const EditSheet = () => {
	const { editSheetIsOpen, prospectList, toggleEditSheet, handleEditProspectList, t } = useProspectContext();
	const [form, setForm] = useState(prospectList);

	useEffect(() => {
		setForm(prospectList);
	}, [prospectList]);

	const handleClose = () => {
		toggleEditSheet(false);
	};

	const handleSave = async () => {
		await handleEditProspectList(form);
		handleClose();
	};

	const getChangeHandler = (field) => (value) => {
		setForm((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	return (
		<Sheet
			open={editSheetIsOpen}
			title={`Redigera "${prospectList?.title}"`}
			onClose={handleClose}
			primaryAction={{
				content: "Spara",
				onAction: handleSave,
			}}
		>
			<Wrapper>
				<FormLayout>
					<TextField label="Titel" value={form?.title} onChange={getChangeHandler("title")} />

					<SelectSearchField
						label="Användare"
						resourceName={{ singular: "användare", plural: "användare" }}
						resource="users"
						resourceHandle="users"
						onSelect={getChangeHandler("assigned_to_user")}
						value={form?.assigned_to_user?.id}
						labelHandle="name"
						renderMedia={(item) => <ProfileAvatar size={25} user={item} />}
						renderValue={(value, data) => {
							return data?.find((item) => item.id === value)?.name || store.getState().users?.find((item) => item.id === value)?.name || value;
						}}
						helpText={t("prospect.fields.user.help_text", "Välj användare som kommer att ha tillgång till denna lista.")}
					/>
				</FormLayout>
			</Wrapper>
		</Sheet>
	);
};
export default EditSheet;

const Wrapper = styled.div`
	padding: 1rem;
`;
