// const getRowSum = (row: FortnoxOfferRowType | FortnoxOrderRowType | FortnoxInvoiceRowType) => {
const getRowSum = (row) => {
	if (!row)
		return {
			sum: 0,
			sumExclVat: 0,
			vat: 0,
			discount: 0,
		};

	const formattedRow = row;

	const quantity = formattedRow?.quantity || 0;
	const discount = formattedRow?.discount || 0;
	const priceInCentsWithoutDiscount = formattedRow?.price_in_cents || 0;
	const discountInCents = formattedRow?.discount_type === "PERCENTAGE" ? priceInCentsWithoutDiscount * (discount / 100) : discount * 100;

	const sumWithDiscount = (priceInCentsWithoutDiscount - discountInCents) * quantity;

	const vat = (formattedRow?.vat_in_percentage || 0) / 100; // percentage 0-100
	const vatInCents = sumWithDiscount * vat;
	const sumInclVat = sumWithDiscount + vatInCents;

	return {
		sum: sumInclVat,
		sumExclVat: sumWithDiscount,
		vat: vatInCents,
		discount: discountInCents,
	};
};
export default getRowSum;
