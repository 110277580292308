import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Tag, FormLayout, Checkbox, Select, LegacyStack, Tooltip, Icon } from "@shopify/polaris";
import { EditMajor, InfoMinor } from "@shopify/polaris-icons";
import { Trans, useTranslation } from "react-i18next";
import API from "src/js/API";
import Toast, { getErrorMessage } from "src/js/components/Toast";

import { store } from "src/js/store";
import SearchField from "src/js/components/search_field.js";
import ContactSelector from "src/js/components/ContactSelector.js";
import ProfileAvatar from "src/js/components/ProfileAvatar.js";
import UploadModal from "src/js/components/UploadModal.js";
// import ContactFortnoxContent from "src/js/components/ContactFortnoxContent.js";
import Banner from "src/js/components/banner";
import Tabs, { TabsProps } from "src/js/components/Tabs";
import Modal from "src/js/components/modal";
import TextField from "src/js/components/TextField";
import useDebounce from "src/js/hooks/useDebounce";
import useQuery from "src/js/hooks/useQuery";
import useContact from "src/js/hooks/useContact";
import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import Spinner from "src/js/components/Spinner";
import Button from "src/js/components/Button";
import { checkUserRoles } from "src/js/Utilities";
import ContactFortnoxContent from "src/js/components/ContactFortnoxContent";
import { PrimaryAction } from "src/js/components/modal/Modal";
import { HorizonalDots, WarningIcon } from "src/js/icons";
import { Link } from "react-router-dom";
import Colors from "src/js/Colors";

export type ContactFormType = ContactType & {
	metafields: any[];
	parent_id?: number | string;
};

type ContactModalProps = {
	isCompany?: boolean;
	open: boolean;
	contact: ContactType;
	onClose: () => void;
	onUpdate?: (contact: ContactFormType) => void;
	onCreate?: (contact: ContactFormType) => void;
	onRemove?: (contact: ContactFormType) => void;
	removeable?: boolean;
	company?: ContactType;
	inline?: boolean;
	onCreateNoSave?: (contact: Partial<ContactType>) => void;
	primaryAction?: Omit<PrimaryAction, "onAction"> & {
		onAction: (form?: any) => void;
	};
};

const ContactModal = forwardRef(
	(
		{
			isCompany,
			open,
			contact: propsContact,
			onClose,
			onUpdate,
			onCreate,
			onRemove,
			removeable = true,
			company,
			inline = false,
			onCreateNoSave,
			primaryAction: propsPrimaryAction,
		}: ContactModalProps,
		ref
	) => {
		const { contact, updateContact, removeContact, customer, isLoadingCustomer, refreshCustomer } = useContact(propsContact?.id, propsContact, {
			onUpdate,
			onCreate,
			onClose,
			onRemove,
		});
		const { t } = useTranslation(["contact", "common"]);
		const defaultForm = useMemo(
			() => ({
				name: "",
				email: "",
				phone: "",
				mobile_phone: "",
				metafields: [],
				country: "SE",
				note: "",
				tags: [],
				is_company: isCompany,
			}),
			[isCompany]
		);

		const [form, setForm] = useState<Partial<ContactFormType>>(defaultForm);
		const [selectedTabIndex, setSelectedTabIndex] = useState(0);
		const [modalStates, setModalStates] = useState({
			openUploadImage: false,
		});
		const [error, setError] = useState<any>(null);

		const storeContactMeafields = useSelector((state: any) => state.contact_metafields);
		const storeAccount = useSelector((state: any) => state.account);

		const metafields = useMemo(() => {
			return storeContactMeafields.filter((field) => {
				if (form.is_company || form.is_company) {
					if (field.for == "company") return true;
				} else {
					if (field.for == "person") return true;
				}
				return false;
			});
		}, [form.is_company, storeContactMeafields]);

		useEffect(() => {
			if (open) {
				const newForm = { ...defaultForm, ...(contact || {}) };

				if (contact.is_bolag) {
					const pcs = contact.id.split("#");
					newForm.id = pcs[0];
				}

				// 				this.setState({ open: props.open, form: contact, selectedTabIndex: 0 }, () => {
				// 	if (props.open && form && form.integrations) {
				// 		const fortnoxIntegration = storeAccount.integrations.find((i) => {
				// 			const integration = form.integrations.find((int) => i.id == int.integration_id);

				// 			if (!integration) return false;
				// 			return i.type === "fortnox" || i.type === "fortnox_invoice";
				// 		});

				// 		if (fortnoxIntegration) this.fetchIntegrationDetails(fortnoxIntegration, "fortnox");
				// 	}
				// });

				setForm(newForm);
			} else {
				setForm(defaultForm);
				setSelectedTabIndex(0);
			}
		}, [open, contact, defaultForm]);

		const fetchContacts = async (params) => {
			try {
				const result = await API.get("/api/contacts.json", { params });

				return result.data.contacts?.filter((c) => c.id !== contact.id);
			} catch (e) {
				Toast.error(e);
			}
		};

		const debouncedOrgnr = useDebounce(form?.orgnr, 1000);
		const { data: duplicatesContactsByOrgnr, isFetching: isLoadingDuplciatesByOrgnr } = useQuery({
			queryKey: ["contacts", "orgnr", debouncedOrgnr],
			queryFn: async () => {
				return await fetchContacts({ company_number: debouncedOrgnr });
			},
			keepPreviousData: true,
			staleTime: 1000,
			enabled: !!debouncedOrgnr,
		});

		const debouncedEmail = useDebounce(form?.email, 1000);
		const { data: duplicatesContactsByEmail, isFetching: isLoadingDuplciatesByEmail } = useQuery({
			queryKey: ["contacts", "email", debouncedEmail],
			queryFn: async () => {
				return await fetchContacts({ email: debouncedEmail });
			},
			keepPreviousData: true,
			staleTime: 1000,
			enabled: !!debouncedEmail,
		});

		const updateForm = useCallback(
			(field) => (value) => {
				if (field === "parent" && !value?.is_company && value.parent) {
					value = value.parent;
				}

				setForm((form) => {
					const newForm = { ...form };

					newForm[field] = value;

					if (field === "first_name" || field === "last_name") {
						newForm.name = `${newForm.first_name || ""} ${newForm.last_name || ""}`.trim();
					}

					return newForm;
				});
			},
			[]
		);

		const updateFortnoxForm = useCallback((fortnoxForm) => {
			setForm((form: any) => {
				return {
					...form,
					fortnox_integration: {
						...(form.fortnox_integration || {}),
						fields: fortnoxForm,
					},
				};
			});
		}, []);

		const saveContact = async (formToSave = form) => {
			setError(null);
			formToSave.is_visitor = false;

			if (company) {
				formToSave.parent_id = company.id;
			}

			if (onCreateNoSave) {
				onCreateNoSave(formToSave);
				if (onClose) onClose();
				return;
			}

			try {
				const result = await updateContact(formToSave, formToSave.id, true);
				refreshCustomer();
				if (onClose) onClose();

				return result;
			} catch (error) {
				setError(getErrorMessage(error));
			}
		};

		const addTag = (tag) => {
			setForm(() => ({
				...form,
				tags: uniqBy([...(form?.tags || []), tag], "title"),
			}));
		};

		const getRemoveTagHandler = (tag) => () => {
			setForm(() => ({
				...form,
				tags: form?.tags?.filter((t: any) => t.title.toLowerCase() != tag.title.toLowerCase()),
			}));
		};

		const getMetafieldValue = useCallback(
			(metafield) => {
				return form?.metafields?.find((field) => field.metafield_id == metafield.id)?.value || (metafield.type == "boolean" ? false : "");
			},
			[form]
		);

		const isFieldDisabled = (metafield) => {
			const field = metafields.some((field) => field.title === "Kundnummer" && getMetafieldValue(field) && form.id);
			if (!field) return false;

			if (form.is_company && storeAccount.id == 15 && form.id && (!metafield || metafield.title == "Kundnummer")) {
				return true;
			}

			return false;
		};

		const setMetafieldValue = (metafield) => (value) => {
			setForm((form) => {
				const newForm = { metafields: [], ...form };
				const metafieldIndex = newForm.metafields.findIndex((field) => field.metafield_id == metafield.id);
				if (metafieldIndex >= 0) {
					newForm.metafields[metafieldIndex].value = value;
				} else {
					newForm.metafields.push({
						value,
						handle: metafield.handle,
						metafield_id: metafield.id,
					});
				}

				return newForm;
			});
		};

		const requiredMetaFieldsAndMissingValue = useMemo(
			() => metafields.filter((field) => field.required && !getMetafieldValue(field)).map((field) => field.id),
			[metafields, getMetafieldValue]
		);

		const getMetafieldErrors = (metafield) => {
			const requiredMetaFieldsAndMissingValueIds = requiredMetaFieldsAndMissingValue.map((field) => field.id);

			if (requiredMetaFieldsAndMissingValueIds.includes(metafield.id)) {
				return t("contact.metafields.errors.missing", "Obligatorisk");
			}

			return "";
		};

		const toggleModal =
			(modalName) =>
			(open = !modalStates[modalName]) => {
				setModalStates(() => ({
					...modalStates,
					[modalName]: open,
				}));
			};

		const secondaryActions = [
			{
				content: t("common.actions.close", "Stäng"),
				onAction: onClose,
			},
			{
				content: t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: removeContact,
				confirmation: {
					title: t("contact.remove.confirmation.title", "Ta bort kontakt"),
					content: (
						<>
							<p>{t("contact.remove.confirmation.description", "Är du säker på att du vill ta bort denna kontakt?")}</p>
							<Trans
								i18nKey="contact.remove.confirmation.description2"
								defaults="Kontakt: <b>{{name}}</b>"
								components={{ b: <b /> }}
								values={{ name: form.name }}
							/>
						</>
					),
					confirm: t("common.actions.remove", "Ta bort"),
					cancel: t("common.actions.cancel", "Avbryt"),
				},
				enabled: form.id && !form.is_bolag && removeable,
				disabled: !!form.removed,
				roles: ["ROLE_CONTACT_WRITE", "ROLE_CONTACT_WRITE_ALL"],
			},
			{
				icon: HorizonalDots,
				renderGroupEvenWithJustOneAction: true,
				plain: false,
				actions: [
					{
						content: t("contact.actions.fortnox.load.label", "Fyll i uppgifter ifrån Fortnox"),

						onAction: async () => {
							const res = await refreshCustomer();
							const fortnoxCustomer = res.data || customer;

							setForm((form) => ({
								...form,
								name: fortnoxCustomer.Name,
								orgnr: fortnoxCustomer.OrganisationNumber,
								email: fortnoxCustomer.Email,
								phone: fortnoxCustomer.Phone1,
								address1: fortnoxCustomer.Address1,
								city: fortnoxCustomer.City,
								zip: fortnoxCustomer.ZipCode,
							}));
						},

						enabled: !!customer,
						// roles: ["...some fortnox customer role.."],
					},
				],
			},
		];

		const tabs = [
			{
				content: t("contact.tabs.basic_info", "Grunduppgifter"),
				render: (
					<FormLayout>
						{/* (((form.is_company) && (!form.bolag) ) ? <ResourcePicker
              caption={"Koppla till bolag"}
              fullWidth={false}
              item={null}
              resource="companies.json"
              resource_handle="companies"
              id_handle="id"
              label_handle="fullname"
              resourceName={{
                singular:'bolag',
                plural:'bolag',
              }}
              onChange={this.onChangeBolag.bind(this)}
              helpText="Koppla ihop med bolaget för att hämta information om omsättning och annat"
            />:null) */}

						{/* {!(company || form.is_company || this.props.import) && ( */}

						{form.is_company && (
							<TextField
								label={t("contact.fields.name.label", "Namn")}
								disabled={isFieldDisabled(metafields)}
								onChange={updateForm("name")}
								value={form && form.name}
							/>
						)}
						{!form.is_company && (
							<LegacyStack alignment="center" distribution="equalSpacing">
								<ProfileAvatar
									onClick={toggleModal("openUploadImage")}
									user={form}
									tooltip={t("contact.fields.profile.tooltip", "Ändra profil bild")}
									size={40}
								/>
								<Tooltip content={t("contact.fields.profile.tooltip", "Ändra profil bild")}>
									<Button onClick={toggleModal("openUploadImage")} icon={EditMajor}>
										{t("common.actions.change", "Ändra")}
									</Button>
								</Tooltip>
							</LegacyStack>
						)}

						{!(company || form.is_company) && (
							<ContactSelector
								label={t("contact.fields.parent.label", "Företag")}
								contact={form}
								onChange={updateForm("parent")}
								onCreateContact={updateForm("parent")}
								hidePerson
								fixed
							/>
						)}

						{!form.is_company && (
							<TextField label={t("contact.fields.first_name.label", "Förnamn")} onChange={updateForm("first_name")} value={form.first_name} />
						)}

						{!form.is_company && (
							<TextField label={t("contact.fields.last_name.label", "Efternamn")} onChange={updateForm("last_name")} value={form.last_name} />
						)}
						{!form.is_company && (
							<TextField label={t("contact.fields.title.label", "Befattning/Roll")} onChange={updateForm("title")} value={form.title} />
						)}
						{form.is_company && (
							<TextField
								label={t("contact.fields.orgnr.label", "Org nr")}
								suffix={isLoadingDuplciatesByOrgnr && <Spinner size="small" />}
								disabled={isFieldDisabled(metafields)}
								onChange={updateForm("orgnr")}
								value={form.orgnr}
								error={
									!isLoadingDuplciatesByOrgnr &&
									!!duplicatesContactsByOrgnr?.length && (
										<div>
											{duplicatesContactsByOrgnr?.length > 1 ? (
												<p>{t("contact.errors.exist", "{{count}} företag finns redan: ", { count: duplicatesContactsByOrgnr?.length })}: </p>
											) : (
												<p>{t("contact.errors.exist", "Företag finns redan: ")} </p>
											)}

											{duplicatesContactsByOrgnr.map((contact) => (
												<p key={contact.id}>
													<Link key={contact.id} to={`/admin/contacts/${contact.id}`}>
														{contact.name}
													</Link>
												</p>
											))}
										</div>
									)
								}
							/>
						)}

						<TextField
							label={t("contact.fields.email.label", "E-post")}
							onChange={updateForm("email")}
							value={form.email}
							suffix={isLoadingDuplciatesByEmail && <Spinner size="small" />}
							error={
								!isLoadingDuplciatesByEmail &&
								!!duplicatesContactsByEmail?.length && (
									<div>
										{duplicatesContactsByEmail?.length > 1 ? (
											<p>{t("contact.errors.contact_exists", "{{count}} kontakter finns redan: ", { count: duplicatesContactsByEmail?.length })}: </p>
										) : (
											<p>{t("contact.errors.contact_exists", "Kontakten finns redan: ")} </p>
										)}

										{duplicatesContactsByEmail.map((contact) => (
											<p key={contact.id}>
												<Link key={contact.id} to={`/admin/contacts/${contact.id}`}>
													{contact.name}
												</Link>
											</p>
										))}
									</div>
								)
							}
						/>
						<TextField
							label={t("contact.fields.phone.label", "Telefonnummer")}
							disabled={isFieldDisabled(metafields)}
							onChange={updateForm("phone")}
							value={form.phone}
						/>
						{!form.is_company && (
							<TextField
								label={t("contact.fields.mobile_phone.label", "Mobilnummer")}
								disabled={isFieldDisabled(metafields)}
								onChange={updateForm("mobile_phone")}
								value={form.mobile_phone}
							/>
						)}

						<TextField
							label={t("contact.fields.customer_number.label", "Kundnummer {{extrInfo}}", {
								extrInfo: form?.fortnox_customer_id ? `(Fortnox)` : "",
							})}
							disabled={!!contact?.fortnox_customer_id}
							onChange={updateForm("customer_number")}
							value={form.customer_number}
						/>
					</FormLayout>
				),
			},
			{
				content: t("contact.tabs.laddress_info", "Adressuppgifter"),
				// enabled: form.is_company,
				render: (
					<FormLayout>
						{/* <Text variant="headingSm" as="h3">
							{t("contact.titles.visitor_address", "Besöksadress")}
						</Text> */}
						{/* {form.is_company && ( */}
						<Select
							label={t("contact.fields.country.label", "Land")}
							options={store.getState().countries}
							onChange={updateForm("country")}
							value={form.country}
						/>
						{/* // )} */}
						<TextField
							label={t("contact.fields.address1.label", "Gatuadress")}
							onChange={updateForm("address1")}
							disabled={isFieldDisabled(metafields)}
							value={form.address1}
						/>
						<TextField
							label={t("contact.fields.address2.label", "CO/adress och annat")}
							onChange={updateForm("address2")}
							disabled={isFieldDisabled(metafields)}
							value={form.address2}
						/>
						<LegacyStack distribution="fillEvenly">
							<TextField
								label={t("contact.fields.zip.label", "Postnummer")}
								onChange={updateForm("zip")}
								disabled={isFieldDisabled(metafields)}
								value={form.zip}
							/>
							<TextField
								label={t("contact.fields.city.label", "Ort")}
								onChange={updateForm("city")}
								disabled={isFieldDisabled(metafields)}
								value={form.city}
							/>
						</LegacyStack>
						<Checkbox
							label={t("contact.fields.use_postals.label", "Har särskild utdelningsadress")}
							checked={!!form.use_postal}
							onChange={updateForm("use_postal")}
						/>
						{form.use_postal && (
							<TextField
								label={t("contact.fields.postal_address1.label", "Adress för post")}
								onChange={updateForm("postal_address1")}
								disabled={isFieldDisabled(metafields)}
								value={form.postal_address1}
							/>
						)}
						{form.use_postal && (
							<TextField
								label={t("contact.fields.postal_address2.label", "CO/adress och annat")}
								onChange={updateForm("postal_address2")}
								disabled={isFieldDisabled(metafields)}
								value={form.postal_address2}
							/>
						)}
						{form.use_postal && (
							<LegacyStack distribution="fillEvenly">
								<TextField
									label={t("contact.fields.postal_zip.label", "Postnummer")}
									onChange={updateForm("postal_zip")}
									disabled={isFieldDisabled(metafields)}
									value={form.postal_zip}
								/>
								<TextField
									label={t("contact.fields.postal_city.label", "Ort")}
									onChange={updateForm("postal_city")}
									disabled={isFieldDisabled(metafields)}
									value={form.postal_city}
								/>
							</LegacyStack>
						)}
					</FormLayout>
				),
			},
			{
				content: t("contact.tabs.other_info", "Övriga uppgifter"),
				badge: !!requiredMetaFieldsAndMissingValue?.length && (
					<span className="contact_tab__error_icon">
						<Tooltip content={t("contact.text.missing_required_fields", "Fyll i alla obligatoriska fällt")}>
							<Icon source={InfoMinor} />
						</Tooltip>
					</span>
				),
				render: (
					<FormLayout>
						<TextField label={t("contact.fields.reference.label", "Eget id")} value={form.reference} onChange={updateForm("reference")} multiline />
						<TextField label={t("contact.fields.note.label", "Anteckning")} value={form.note} onChange={updateForm("note")} multiline />
						<SearchField
							label={t("contact.fields.tags.label", "Taggar")}
							resource="contacts/tags"
							onSelect={addTag}
							tags={store.getState() && store.getState().user && checkUserRoles(["ROLE_CREATE_TAG"])}
							resource_handle="tags"
							id_handle="title"
							params={{
								type: form.is_company === undefined ? null : form.is_company ? "company" : "person",
								group_by: "title",
								sort: "TITLE_ASC",
							}}
						/>
						<div style={{ paddingTop: 10 }}>
							<LegacyStack>
								{(form.tags || []).map((tag: any, index) => (
									<Tag key={index} onRemove={getRemoveTagHandler(tag)}>
										{tag.title}
									</Tag>
								))}
							</LegacyStack>
						</div>
						{metafields &&
							metafields.map((metafield) => {
								const props = {
									value: getMetafieldValue(metafield),
									checked: !!getMetafieldValue(metafield),
									label: `${metafield.title}${metafield.required ? " *" : ""}`,
									error: getMetafieldErrors(metafield.id),
									onChange: setMetafieldValue(metafield),
								};

								if (metafield.type == "input") {
									return <TextField key={metafield.id} {...props} disabled={isFieldDisabled(metafield)} multiline />;
								} else if (metafield.type == "boolean") {
									return <Checkbox key={metafield.id} {...props} />;
								} else if (metafield.type == "options") {
									const options = [{ value: "", label: t("contact.fields.empty.label", "Inget val") }].concat(metafield.options || []);

									return <Select key={metafield.id} {...props} options={options} />;
								}
								return null;
							})}
					</FormLayout>
				),
			},
			{
				content: "Fortnox",
				render: (
					<ContactFortnoxContent
						customer={customer || {}}
						onChange={updateFortnoxForm}
						integration={storeAccount.fortnox_integration}
						form={form.fortnox_integration?.fields || {}}
						loading={isLoadingCustomer}
					/>
				),
				enabled: !!contact?.fortnox_integration,
			},
		];

		const finallTabs = tabs
			.filter((i) => !("enabled" in i) || i.enabled)
			.map((i, index) => Object.assign(i, { id: i.content, accessibilityLabel: i.content, panelID: `${i.content}-${index}` }));

		const activeTab: any = finallTabs[selectedTabIndex];
		const content = (
			<div>
				<Tabs tabs={finallTabs as TabsProps["tabs"]} selected={selectedTabIndex} onSelect={setSelectedTabIndex} />
				{form && form.is_visitor && (
					<Banner status="info" title={t("contact.visitor.title", "Ej importerad")} style={{ margin: "1.2500rem 1.2500rem 0 1.2500rem" }} dismissible>
						<p>{t("contact.visitor.text1", "Denna kontakt är ej importerad i crm:et.")}</p>
						<p>{t("contact.visitor.text3", "Spara för att importera kontakten.")}</p>
					</Banner>
				)}
				<Modal.Section>{activeTab && (typeof activeTab.render === "function" ? activeTab.render() : activeTab.render)}</Modal.Section>
			</div>
		);

		useImperativeHandle(ref, () => ({
			saveContact,
		}));

		return (
			<React.Fragment>
				{inline ? (
					content
				) : (
					<Modal
						open={open}
						onClose={onClose}
						title={
							<>
								{form.id
									? form.name
									: form.is_company
									? t("contact.titles.new_company", "Nytt företag")
									: t("contact.titles.new_person", "Ny kontaktperson")}
								{error && (
									<div style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "1rem" }}>
										<WarningIcon color={Colors.red} />
										<p style={{ color: Colors.red, fontSize: 14 }}>{error}</p>
									</div>
								)}
							</>
						}
						secondaryActions={secondaryActions}
						primaryAction={
							propsPrimaryAction
								? {
										...propsPrimaryAction,
										onAction: propsPrimaryAction.onAction ? () => (propsPrimaryAction.onAction as any)(form) : undefined,
								  }
								: {
										content: t("common.actions.save", "Spara") as string,
										disabled: !!requiredMetaFieldsAndMissingValue?.length,
										onAction: saveContact,
								  }
						}
					>
						{content}
					</Modal>
				)}

				{!form.is_company && (
					<UploadModal
						open={modalStates.openUploadImage}
						onClose={() => {
							toggleModal("openUploadImage")(false);
						}}
						onSelect={(imgs) => {
							if (imgs[0] && imgs[0].url) {
								setForm((form) => ({ ...form, avatar: imgs[0].url }));
								toggleModal("openUploadImage")(false);
							}
						}}
						allowMultiple={false}
						disabledToast
					/>
				)}
			</React.Fragment>
		);
	}
);
// <AttributeForm metafields={form.metafields} onUpdate={this.updateMetafields.bind(this)} />
export default ContactModal;
