import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { createContext, use, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ImportProspectModal from "../Views/ProspectCompany/ImportProspectModal";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import EditSheet from "../Views/EditSheet";

const ProspectContext = createContext({} as any);

export const useProspectContext = () => {
	return use(ProspectContext);
};

export const ProspectProvider = ({ children }) => {
	const { t } = useTranslation(["prospects", "common"]);
	const { id, view, prospectId: paramProspectId }: any = useParams();
	const previousView = useRef(view);
	const prospectId = useMemo(() => (paramProspectId === "empty" ? null : paramProspectId), [paramProspectId]);
	const lastUsedProspectId = useRef(prospectId);
	const history = useHistory();
	const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);
	const [temporalProspectId, setTemporalProspectId] = React.useState(null);
	const [editSheetIsOpen, setEditSheetIsOpen] = React.useState(false);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (prospectId) {
			lastUsedProspectId.current = prospectId;
		}
	}, [prospectId]);

	const toggleEditSheet = useCallback(
		(open = !editSheetIsOpen) => {
			setEditSheetIsOpen(open);
		},
		[editSheetIsOpen]
	);

	const fetchNextProspect = useCallback(async () => {
		try {
			const res = await API.get(`/api/prospect_lists/${id}/initial_prospect.json`);
			return res.data.prospect;
		} catch (e) {
			if (paramProspectId !== "empty") Toast.error(e);
		}
	}, [id, paramProspectId]);

	useEffect(() => {
		(async () => {
			if (!prospectId && view === "prospect") {
				if (lastUsedProspectId.current && paramProspectId !== "empty") {
					history.replace(`/admin/prospect_lists/${id}/prospect/${lastUsedProspectId.current}`);
				} else {
					try {
						const prospect: any = await fetchNextProspect();
						const url = `/admin/prospect_lists/${id}/prospect/${prospect.id}`;
						history.replace(url);
					} catch (error) {
						const url = `/admin/prospect_lists/${id}/prospect/empty`;
						history.replace(url);
					}
				}
			}
		})();
	}, [fetchNextProspect, history, id, prospectId, view, paramProspectId]);

	const queryStatsKey = useMemo(() => ["prospect_list", "stats", id], [id]);
	const {
		data: stats,
		isFetching: isLoadingStats,
		refetch: refreshStats,
	}: UseQueryResult<any> = useQuery({
		queryKey: queryStatsKey,
		queryFn: async () => {
			const res = await API.get(`/api/prospect_lists/${id}/stats.json`);
			return res.data;
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	useEffect(() => {
		if (["card_list", "column_list"].includes(view) && !["card_list", "column_list"].includes(previousView.current)) {
			refreshStats();
		}
		previousView.current = view;
	}, [view, refreshStats]);

	const queryKey = ["prospect", prospectId];
	const { data: prospect, isFetching: isLoadingProspect }: UseQueryResult<ProspectType> = useQuery({
		queryKey,
		queryFn: async () => {
			const res = await API.get(`/api/prospects/${prospectId}.json`);
			return res.data.prospect;
		},
		enabled: !!prospectId,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const querySummaryKey = useMemo(() => ["prospect", prospectId, "summary"], [prospectId]);
	const { data: prospectSummary, isFetching: isLoadingProspectSummary }: UseQueryResult<ProspectSummaryType> = useQuery({
		queryKey: querySummaryKey,
		queryFn: async () => {
			const res = await API.get(`/api/prospects/${prospectId}/summary.json`);
			return res.data.prospect_summary;
		},
		enabled: !!prospectId,
		refetchOnWindowFocus: false,
	});

	const refetchProspectSummary = useCallback(async () => {
		const res = await API.get(`/api/prospects/${prospectId}/summary.json`, { params: { refresh: true } });
		const data = res.data.prospect_summary;

		if (data) queryClient.setQueryData(querySummaryKey, data);
	}, [prospectId, querySummaryKey, queryClient]);

	const queryListKey = useMemo(() => ["prospect_list", prospectId], [prospectId]);
	const { data: prospectList, isFetching: isLoadingProspectList }: UseQueryResult<any> = useQuery({
		queryKey: queryListKey,
		queryFn: async () => {
			const res = await API.get(`/api/prospect_lists/${id}.json`);
			return res.data.prospect_list;
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const queryMatchesLeftKey = useMemo(() => ["prospect_list"], []);
	const { data: matchesLeftThisMonth, isFetching: isLoadingMatchesLeftThisMonth }: UseQueryResult<any> = useQuery({
		queryKey: queryMatchesLeftKey,
		queryFn: async () => {
			const res = await API.get(`/api/company_registry/matches_left_this_month.json`);
			return res.data.matches_left_this_month;
		},
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	// addd mutation for prospectList
	const handleEditProspectListMutation = useMutation(
		async (data) => {
			const res = await API.put(`/api/prospect_lists/${id}.json`, data);
			Toast.success(t("prospects.actions.edit_prospect_list.success", "Prospektlistan har uppdaterats"));
			return res?.data.prospect_list;
		},
		{
			onSuccess: (data) => {
				if (queryListKey?.length && id) queryClient.setQueryData(queryListKey, data);
			},
		}
	);

	const handleEditProspectList = useCallback(
		async (data) => {
			try {
				await handleEditProspectListMutation.mutateAsync(data);
			} catch (error) {
				Toast.error(error);
			}
		},
		[handleEditProspectListMutation]
	);

	const handleRemoveProspectList = useCallback(async () => {
		try {
			await API.delete(`/api/prospect_lists/${id}.json`);
			Toast.success(t("prospects.actions.remove_prospect_list.success", "Prospektlistan har tagits bort"));

			queryClient.invalidateQueries(queryListKey);

			history.replace("/admin/prospect_lists");
		} catch (error) {
			Toast.error(error);
		}
	}, [queryListKey, id, t, queryClient, history]);

	const handleLoadMoreProspects = useCallback(async () => {
		const loadingToastId = Toast.loading(t("prospects.actions.load_more_prospects.loading", "Laddar nya prospekter..."));
		try {
			const res = await API.get(`/api/prospect_lists/${id}/load_new_prospects.json`);

			Toast.update(loadingToastId, {
				render: t("prospects.actions.load_more_prospects.success", "{{count}} nya prospekter har lagts till", {
					count: res.data.new_count - res.data.old_count,
				}),
				type: "success",
			});

			const refreshIndexTableEvent = new CustomEvent(`indexTable_refresh_prospects`);
			window.dispatchEvent(refreshIndexTableEvent);

			refreshStats();

			if (!prospectId && view === "prospect") {
				const prospect: any = await fetchNextProspect();
				if (prospect) {
					const url = `/admin/prospect_lists/${id}/prospect/${prospect.id}`;
					history.replace(url);
				}
			}
		} catch (error) {
			if (loadingToastId) {
				Toast.update(loadingToastId, {
					render: error,
					type: "error",
				});
			} else {
				Toast.error(error);
			}
		}
	}, [id, t, fetchNextProspect, history, prospectId, view, refreshStats]);

	const prospectSettings = {
		importCompantyOnLike: false,
	};

	const onAnyAction = useCallback(
		(data) => {
			setTemporalProspectId(null);
			setImportModalIsOpen(false);

			if (data.prospect) {
				queryClient.setQueryData(["prospect", data.prospect.id], data.prospect);
			}
			if (data.next_prospect) {
				queryClient.setQueryData(["prospect", data.next_prospect.id], data.next_prospect);
			}

			if (view === "prospect") {
				const url = `/admin/prospect_lists/${id}/prospect/${data?.next_prospect?.id || "empty"}`;
				history.replace(url);
			}
		},
		[history, id, queryClient, view]
	);

	const handleLikeAction = useCallback(
		async (prosId: number = prospectId, data = null) => {
			const res = await API.put(`/api/prospects/${prosId}/qualify.json`);
			Toast.success(t("prospects.actions.like.success", "Prospektet kvalificerad"));

			onAnyAction(res.data);

			return res;
		},
		[prospectId, t, onAnyAction]
	);

	const preHandleLikeAction = useCallback(
		async (id = prospectId, importCompantyOnLike = prospectSettings.importCompantyOnLike) => {
			setTemporalProspectId(id);

			if (importCompantyOnLike) {
				setImportModalIsOpen(true);
				return;
			}

			return await handleLikeAction(id);
		},
		[handleLikeAction, prospectSettings.importCompantyOnLike, prospectId]
	);

	const handleDislikeAction = useCallback(
		async (prosId = prospectId) => {
			const res = await API.put(`/api/prospects/${prosId}/deny.json`);

			Toast.success(t("prospects.actions.dislike.success", "Prospektet Ignorerad"));

			onAnyAction(res.data);

			return res;
		},
		[prospectId, t, onAnyAction]
	);

	const handleImportToContact = useCallback(
		async (prosId = prospectId, data) => {
			const res = await API.post(`/api/prospects/${prosId}/import/contact.json`, data);

			Toast.success(t("prospects.actions.handleImportToContact.success", "Prospektet har importerats till kontakter"));

			onAnyAction(res.data);

			return res;
		},
		[prospectId, t, onAnyAction]
	);

	const handleImportToContactAndAsBoardrowInSalesBoard = useCallback(
		async (prosId = prospectId, data) => {
			const res = await API.post(`/api/prospects/${prosId}/import/sales_board.json`, data);

			Toast.success(t("prospects.actions.handleImportToContact.success", "Prospektet har importerats till kontakter och affär skapad"));

			onAnyAction(res.data);

			return res;
		},
		[prospectId, t, onAnyAction]
	);

	const handleImportToCallList = useCallback(
		async (prosId = prospectId, boardId, data) => {
			const res = await API.post(`/api/prospects/${prosId}/import/board/boardId.json`, data);

			Toast.success(t("prospects.actions.handleImportToContact.success", "Prospektet har importerats till boarden"));

			onAnyAction(res.data);

			return res;
		},
		[prospectId, t, onAnyAction]
	);

	const value = useMemo(() => {
		return {
			id,
			prospectId,
			view,
			prospect,
			company: prospect?.axicon_company,
			handleLoadMoreProspects,
			t,
			handleLikeAction: preHandleLikeAction,
			handleLikeActionDirectly: handleLikeAction,
			handleDislikeAction,
			isLoadingProspect: isLoadingProspect || !prospectId,
			lastUsedProspectId: lastUsedProspectId.current,
			handleImportToContact,
			handleImportToContactAndAsBoardrowInSalesBoard,
			handleImportToCallList,
			prospectList,
			isLoadingProspectList,
			toggleEditSheet,
			editSheetIsOpen,
			handleEditProspectList,
			handleRemoveProspectList,
			prospectSummary,
			isLoadingProspectSummary,
			matchesLeftThisMonth,
			isLoadingMatchesLeftThisMonth,
			refetchProspectSummary,
			stats,
			isLoadingStats,
		};
	}, [
		id,
		view,
		prospect,
		handleLoadMoreProspects,
		t,
		preHandleLikeAction,
		handleDislikeAction,
		prospectId,
		isLoadingProspect,
		handleLikeAction,
		handleImportToContact,
		handleImportToContactAndAsBoardrowInSalesBoard,
		handleImportToCallList,
		prospectList,
		isLoadingProspectList,
		toggleEditSheet,
		editSheetIsOpen,
		handleEditProspectList,
		handleRemoveProspectList,
		prospectSummary,
		isLoadingProspectSummary,
		matchesLeftThisMonth,
		isLoadingMatchesLeftThisMonth,
		refetchProspectSummary,
		stats,
		isLoadingStats,
	]);

	return (
		<ProspectContext value={value}>
			{children}

			<ImportProspectModal
				isOpen={importModalIsOpen}
				onClose={() => {
					setImportModalIsOpen(false);
					setTemporalProspectId(null);
				}}
				prospectId={prospectId || temporalProspectId}
			/>

			<EditSheet />
		</ProspectContext>
	);
};

export default ProspectContext;
