/* eslint-disable no-restricted-syntax */
/* eslint-disable no-constant-condition */
import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { store } from "./js/store";
import MissingPermission from "./js/views/MissingPermission";
import { useSelector } from "react-redux";
import ErrorBoundary from "./js/components/ErrorBoundary";
import defaultFallbackRender from "./js/components/ErrorBoundary/defaultFallbackRender";

const RouteWithTitle = ({ route, component: Component, roles, location, path, ...rest }) => {
	const { name } = route;
	const user = useSelector((state: any) => state.user);

	useEffect(() => {
		const titles: string[] = [];
		if (store.getState().account?.company) titles.push(store.getState().account.company);
		if (name) titles.push(name);

		titles.push("Sellfinity");

		document.title = titles.join(" - ");
	}, [location.pathname, name]);

	const userRoles = user.all_permissions || user.roles;
	const userRolesSet = new Set(userRoles);
	//Checks if the user lacks all of the required roles. Aka, if the user has none of the required roles.
	// User only needs one of the roles to pass the check.
	if (roles?.length && roles.every((r) => !userRolesSet.has(r))) {
		return (
			<Route
				path={path}
				{...rest}
				render={(props) => (
					<ErrorBoundary fallbackRender={(props) => defaultFallbackRender({ ...props, style: { height: "calc(100% - 20px)", margin: 10 } })}>
						<MissingPermission {...props} />
					</ErrorBoundary>
				)}
			/>
		);
	}

	return (
		<Route
			path={path}
			{...rest}
			render={(props) => (
				<ErrorBoundary fallbackRender={(props) => defaultFallbackRender({ ...props, style: { height: "calc(100% - 20px)", margin: 10 } })}>
					<Component {...props} />{" "}
				</ErrorBoundary>
			)}
		/>
	);
};

export default withRouter(RouteWithTitle as any);
