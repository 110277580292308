/**
 * Formats an order object to remove unnecessary fields and reduce the request size.
 * @param {Object} order - The original order object.
 * @returns {Object} - The formatted order object.
 */
const formatOrderForPostRequest = (order) => {
	if (!order) return {};

	// Extract required fields from customer fields
	const customerFields = {
		...order.customer_fields,
		customer: order.customer_fields?.customer
			? {
					id: order.customer_fields?.customer?.id,
			  }
			: undefined,
	};

	// Process each order row to retain only necessary fields
	const orderRows = (order.order_rows ?? []).map((orderRow) => ({
		...orderRow,
		order_row_article: orderRow?.order_row_article
			? {
					...orderRow.order_row_article,
					article: {
						id: orderRow?.order_row_article?.article?.id,
					},
			  }
			: undefined,

		custom_fields: (orderRow?.custom_fields ?? []).map((field) => ({
			...field,
			custom_field: {
				id: field?.custom_field?.id,
			},
		})),
	}));

	// Return the formatted order
	return {
		...order,
		customer_fields: customerFields,
		order_rows: orderRows,

		// Remove unnecessary fields
		grouped_order_rows: undefined,
		order_rows_by_category: undefined,
		created_by_user: undefined,
		order_statistics: undefined,
	};
};

export default formatOrderForPostRequest;
