import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import Spinner from "../Spinner";
import { uniqBy } from "lodash";
import moment from "moment";

const ChatBotContext = React.createContext({} as any);

export const ChatBotContextProvider = ({ chatId: propsId, onData, messageContentKey, children, introMessage }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { t } = useTranslation(["chatbot", "common"]);
	const [id, setId] = useState<string | null>(propsId);
	const user = useSelector((state: any) => state.user);
	const canFetchMessages = useRef(!!propsId);

	const [temporaryMessages, setTemporaryMessages] = useState<any[]>(
		// introMessage
		// 	? [
		// 			{
		// 				[messageContentKey]: introMessage,
		// 			},
		// 	  ]
		// 	:
		[]
	);

	useEffect(() => {
		canFetchMessages.current = !!propsId;
		setId(propsId);
	}, [propsId]);

	const queryClient = useQueryClient();

	const chatQueryKey = ["chat", id].filter(Boolean);
	const { data: chat, isFetching: isLoadingChat } = useQuery({
		queryKey: chatQueryKey,
		queryFn: async () => {
			const res = await API.get(`/api/ai_chat/${id}.json`);
			return res.data.ai_chat;
		},
		enabled: !!id,
	});

	const createChat = useCallback(async () => {
		const res = await API.post(`/api/ai_chat.json`);
		const chat = res.data.ai_chat;

		setId(chat.id);

		queryClient.setQueryData(["chat", chat.id], chat);
		return chat;
	}, [queryClient]);

	const chatMessagesQueryKey = ["chatMessages", chat?.id];

	const handleCreateMessage = useCallback(
		async (message: string, chatId: number = chat?.id) => {
			const res = await API.post(`/api/ai_chat/${chatId}/message.json`, {
				message,
			});
			const messages = res.data.messages;
			if (onData?.autoApplyOnNewMessage) {
				const assistantMessage = messages.at(-1);
				const data = assistantMessage[onData?.key];
				onData.handler(data);
			}

			return messages;
		},
		[chat?.id, onData]
	);

	const createMessageMutation = useMutation(
		async (
			{ message, chatId }: { message: string; chatId: number } = {
				message: "",
				chatId: chat?.id,
			}
		) => {
			return await handleCreateMessage(message, chatId);
		},
		{
			onSuccess: (newMessages = []) => {
				setTemporaryMessages([]);

				if (chatMessagesQueryKey?.length && id) {
					queryClient.setQueryData(chatMessagesQueryKey, [
						...messages.filter((m) => m.id),
						...(newMessages || []).map((m: any) => ({ ...m, animate: true })),
					]);
				}

				canFetchMessages.current = true;
			},
		}
	);

	const createMessage = useCallback(
		async (message, chatId = chat?.id) => {
			const tempMessages = [
				{ [messageContentKey]: message, message, role: "user", user, created_at: moment().format("YYYY-MM-DD HH:mm") },
				{
					[messageContentKey]: (
						<>
							<Spinner size="small" />
						</>
					),
					role: "assistant",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			];

			setTemporaryMessages(tempMessages);
			if (!chatId) {
				chatId = (await createChat())?.id;
			}

			return await createMessageMutation.mutateAsync({ message, chatId });
		},
		[createMessageMutation, chat?.id, createChat, messageContentKey, user]
	);

	const isCreatingMessage = createMessageMutation.isLoading;

	const { data: messagesData, isFetching: isLoadingMessages } = useQuery({
		queryKey: chatMessagesQueryKey,
		queryFn: async () => {
			const res = await API.get(`/api/ai_chat/${id}/messages.json`);
			return res.data.messages;
		},
		initialData: (id && queryClient.getQueryData(["chatMessages", id])) || [],
		enabled: !!(id && chat?.id && canFetchMessages.current),
	});

	const messages = useMemo(() => {
		if (id && isLoadingMessages && !messagesData?.length) {
			return [
				{
					[messageContentKey]: (
						<>
							<Spinner size="small" />
						</>
					),
					role: "user",
					user,
				},
				{
					[messageContentKey]: (
						<>
							<Spinner size="small" />
						</>
					),
					role: "assistant",
				},
			];
		}

		const { messagesWithNoId, messagesWithId } = [...messagesData, ...temporaryMessages].reduce(
			(acc, message) => {
				if (message.id) {
					acc.messagesWithId.push(message);
				} else {
					acc.messagesWithNoId.push(message);
				}
				return acc;
			},
			{ messagesWithNoId: [], messagesWithId: [] }
		);

		return [...uniqBy(messagesWithId, "id"), ...messagesWithNoId];
	}, [temporaryMessages, messagesData, isLoadingMessages, messageContentKey, id, user]);

	const value: any = useMemo(
		() => ({
			chat,
			createChat,
			createMessage,
			messages,
			isCreatingMessage,
			isLoadingChat,
			isLoadingMessages,
		}),
		[chat, createChat, createMessage, messages, isCreatingMessage, isLoadingChat, isLoadingMessages]
	);

	return useMemo(() => <ChatBotContext value={value}>{children}</ChatBotContext>, [value, children]);
};

export default ChatBotContext;
