import React, { useState } from "react";
import { ChatMessageCreatorWrapper } from "./styles";
import TextField from "../TextField";
import Button from "../Button";
import { SendMajor } from "@shopify/polaris-icons";

type ChatMessageCreatorProps = {
	onMessagesSend: (message: string) => void;
	messageContentKey: string;
	isCreatingMessage: boolean;
	children?: React.ReactNode;
};

const ChatMessageCreator = ({ onMessagesSend, messageContentKey, isCreatingMessage, children = null }: ChatMessageCreatorProps) => {
	const [input, setInput] = useState<string>("");

	const handleSend = async () => {
		if (!input) return;
		setInput("");
		await onMessagesSend(input);
	};

	return (
		<ChatMessageCreatorWrapper>
			<TextField
				onSubmit={handleSend}
				multiline={3}
				value={input}
				onChange={(value) => setInput(value)}
				suffix={
					<Button disabled={!input || isCreatingMessage} onClick={handleSend} primary icon={SendMajor}>
						Skicka
					</Button>
				}
			/>
			{children}
		</ChatMessageCreatorWrapper>
	);
};
export default ChatMessageCreator;
