import React from "react";
import Colors, { colorToRgba } from "src/js/Colors";
import CustomCard from "src/js/components/CustomCard";
import styled from "styled-components";
import { useProspectContext } from "../../../../Context/ProspectContext";
import { LoadingBox } from "src/js/components/Skeletons/styles";
import { Link } from "react-router-dom";

const AIDescription = () => {
	const { prospectSummary, isLoadingProspectSummary, t, refetchProspectSummary } = useProspectContext();
	return (
		<Wrapper>
			<CustomCard
				sectioned
				title="Beskrivningar"
				subdued
				collapsible
				actions={[{ content: t("prospects.fields.prospect_summary.refresh", "Uppdatera") as string, onAction: refetchProspectSummary }]}
			>
				<b>{t("prospects.fields.prospect_summary.customer_fit.label", "Prospektmatchning")}</b>

				{!isLoadingProspectSummary && (
					<Description>
						{prospectSummary?.customer_fit || (
							<Link to="/admin/account">{t("prospect.acocunt.description.empty", "Saknar konto beskrivning, redigera här")}</Link>
						)}
					</Description>
				)}

				{isLoadingProspectSummary && !prospectSummary?.customer_fit && (
					<LoadingBox height={25} width={"100%"} loading style={{ background: "var(--tertiary)" }} />
				)}

				<br />

				<b>{t("prospects.fields.prospect_summary.prospect_description.label", "Beskrivning")}</b>
				{prospectSummary?.prospect_description && <Description>{prospectSummary?.prospect_description ?? "asdasd"}</Description>}
				{isLoadingProspectSummary && !prospectSummary?.prospect_description && (
					<LoadingBox height={25} width={"100%"} loading style={{ background: "var(--tertiary)" }} />
				)}
			</CustomCard>
		</Wrapper>
	);
};
export default AIDescription;

const Wrapper = styled.div`
	.Polaris-LegacyCard {
		background-color: ${colorToRgba(Colors.green, 0.25)};
		border: 1px solid ${Colors.green};
	}
`;

const Description = styled.p`
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	overflow-wrap: break-word;
`;
