/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { LegacyStack, Collapsible, Spinner, Icon, ActionList, ChoiceList, Banner, Tooltip } from "@shopify/polaris";
import {
	FolderMajor,
	FolderPlusMajor,
	FolderMinusMajor,
	GrammarMajor,
	UploadMajor,
	AnalyticsMajor,
	ChevronUpMinor,
	CircleCancelMinor,
	MobileVerticalDotsMajor,
	SecureMajor,
	CustomersMajor,
	EditMajor,
	SortMinor,
	ImportMinor,
} from "@shopify/polaris-icons";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../API";
import WorkspaceModal from "./WorkspaceModal";
import BoardTemplateModal from "./BoardTemplateModal.js";
import DashboardModal from "./DashboardModal.js";
import UploadModal from "./UploadModal.js";
import RemoveModal from "./remove_modal.js";
import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
import ImportModal from "../views/Workspaces/components/ImportModal.js";
// import ImportCompanyBoardModal from "../views/Workspaces/components/ImportCompanyBoardModal.js";
import RenameWorkspaceModal from "../views/Workspaces/components/RenameWorkspaceModal";

import Toast from "src/js/components/Toast";
import { store } from "../store";
import BoardMembers from "../views/Workspaces/components/BoardMembers.js";
import Popover from "./Popover.js";
import MainMenuSectionSearch from "./MainMenuSectionSearch.js";
import MainSectionFavorites from "./MainSectionFavorites.js";
import BoardWithGroups from "./BoardWithGroups.js";
import WorkspaceNavigationBoard from "./WorkspaceNavigationBoard.js";
import WorkspaceNavigationMoveModal from "./WorkspaceNavigationMoveModal";
import Modal from "src/js/components/modal";
import useWorkspaces from "../hooks/useWorkspaces";

class WorkspaceNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			expanded: !this.props.workspace || props.depth < 0,
			type: (props.workspace || props.dashboard || props.board || {}).type,
		};
	}

	getChildren(workspace) {
		const arr = [];
		if (workspace.children) {
			for (let i = 0; i < workspace.children.length; i++) {
				if (this.matchSearch(workspace.children[i], false) || this.props.matchParent) {
					arr.push(workspace.children[i]);
				}
			}
		}
		if (workspace.dashboards) {
			for (let i = 0; i < workspace.dashboards.length; i++) {
				if (this.matchSearch(workspace.dashboards[i], false) || this.props.matchParent) {
					arr.push(workspace.dashboards[i]);
				}
			}
		}
		if (workspace.uploads) {
			for (let i = 0; i < workspace.uploads.length; i++) {
				if (this.matchSearch(workspace.uploads[i], false) || this.props.matchParent) {
					arr.push(workspace.uploads[i]);
				}
			}
		}
		if (workspace.boards) {
			for (let i = 0; i < workspace.boards.length; i++) {
				if (this.matchSearch(workspace.boards[i], false) || this.props.matchParent) {
					if (
						(workspace.boards[i] && workspace.boards[i].id) != (store.getState().user.todo_board && store.getState().user.todo_board.id) &&
						(workspace.boards[i] && workspace.boards[i].id) != (store.getState().user.calendar_board && store.getState().user.calendar_board.id)
					) {
						arr.push(workspace.boards[i]);
					}
				}
			}
		}

		return arr.sort((a, b) => {
			if (a.position < b.position) {
				return -1;
			}
			if (a.position > b.position) {
				return 1;
			}
			return 0;
		});
	}

	// gotoLink(url) {
	// 	this.props.history.push(url);
	// 	this.props.onCloseMenu();
	// }

	getType(child) {
		return child.handle.replace("_" + child.id, "");
	}

	removeWorkspace() {
		this.props.removeWorkspace(this.props.workspace);
	}

	matchSearch(object, skipchildren) {
		const searchQuery = this.state.search || this.props.search;
		if (!searchQuery) {
			return true;
		}

		const filterRegex = new RegExp(searchQuery, "i");

		if (this.props.workspace && this.props.depth > 0 && this.props.workspace.title.match(filterRegex)) {
			return true;
		}

		if (object.title.match(filterRegex)) {
			return true;
		}

		if (!skipchildren && this.getType(object) == "workspace") {
			const children = this.getChildren(object);
			for (let i = 0; i < children.length; i++) {
				if (this.matchSearch(children[i], false)) {
					return true;
				}
			}
		}
		return false;
	}

	renderChild(indexOffset, child, index) {
		return (
			<Draggable key={child.handle} draggableId={child.handle} index={index + indexOffset}>
				{(provided, snapshot) => {
					const style = Object.assign({}, provided.draggableProps.style);
					if (snapshot.isDragging) {
						style.height = 37;
					}
					return (
						<div ref={provided.innerRef} {...provided.draggableProps} style={style}>
							{this.getChildContent(child, provided.dragHandleProps)}
						</div>
					);
				}}
			</Draggable>
		);
	}

	getChildContent(child, dragHandleProps) {
		if (this.getType(child) == "board" && this.props.workspace.title == "Servicedesk" && child.id >= 1576) {
			return (
				<BoardWithGroups
					expanded={!(this.getChildren(this.props.workspace).length > 1)}
					location={this.props.location}
					dragHandleProps={dragHandleProps}
					workspace={this.props.workspace}
					key={child.handle}
					board={child}
					history={this.props.history}
				/>
			);
		} else if (this.getType(child) == "workspace") {
			return (
				<WorkspaceNavigation
					t={this.props.t}
					depth={(this.props.depth == -1 ? 0 : this.props.depth) + 1}
					location={this.props.location}
					dragHandleProps={dragHandleProps}
					onCreate={this.props.onCreate}
					matchParent={this.props.matchParent}
					search={this.props.search}
					key={child.handle}
					workspace={child}
					history={this.props.history}
					draggingType={this.state.draggingType || this.props.draggingType}
					removeWorkspace={this.props.removeWorkspace}
					updateWorkspace={this.props.updateWorkspace}
					uploadToWorkspace={this.props.uploadToWorkspace}
					addWorkspace={this.props.addWorkspace}
					fetchWorkspaces={this.props.fetchWorkspaces}
				/>
			);
		} else {
			let action = null;
			let icon = GrammarMajor;
			let url = null;
			if (this.getType(child) == "board") {
				icon = getIconByString(child.icon);
				url = "/admin/workspaces/" + this.props.workspace.id + "/boards/" + child.id;
			} else if (this.getType(child) == "dashboard") {
				icon = AnalyticsMajor;
				url = "/admin/dashboard/" + child.id;
				// url = "/admin/workspaces/" + this.props.workspace.id + "/dashboards/" + child.id;
			} else if (this.getType(child) == "upload") {
				icon = UploadMajor;
				url = child.upload.url;
				action = () => {
					window.open(url);
				};
			}

			return (
				<WorkspaceNavigationBoard
					icon={icon}
					url={url}
					child={child}
					dragHandleProps={dragHandleProps}
					action={action}
					location={this.props.location}
					history={this.props.history}
				/>
			);
		}
	}

	onUpdateWorkspace(workspace) {
		store.dispatch({ type: "UPDATE_WORKSPACE", workspace });
		if (this.props && this.props.onCreate) this.props.onCreate(workspace);
	}

	saveType() {
		this.props.updateWorkspace({ id: this.props.workspace.id, type: this.state.type });
	}

	getChildrenBoards(children) {
		if (children && children.children && children.children.length) return this.getChildrenBoards(children.children);
		return children;
	}

	render() {
		let children = null;
		let header = null;
		let forceExpand = false;

		if (!this.props.workspace) {
			if (this.state.loading) {
				return (
					<div className="mainmenu-item__secondary__item">
						<div>
							<Spinner size="small" />
							{this.props.t("workspace.navigation.loading", "Laddar..")}
						</div>
					</div>
				);
			} else {
				const search = this.state.search || this.props.search || "";
				const workspaces = this.props.workspaces;

				const workspaceChildren =
					workspaces &&
					workspaces
						.filter((w) => w && w.children)
						.map((w) => this.getChildrenBoards(w.children) || [])
						.flat()
						.filter((i) => i);
				const workspaceBoardsAndDashboards =
					(workspaceChildren &&
						workspaceChildren
							.map((w) => w && [...(w.boards || []), ...(w.dashboards || [])])
							.flat()
							.filter((i) => i)) ||
					[];

				const firstlvlBoardsAndDashboards =
					workspaces &&
					workspaces
						.map((w) => (w && [...(w.boards || []), ...(w.dashboards || [])]) || [])
						.flat()
						.filter((i) => i);
				const boardsAndDashboards = workspaceBoardsAndDashboards.concat(firstlvlBoardsAndDashboards);
				const favorites =
					boardsAndDashboards &&
					boardsAndDashboards.filter((bb) => {
						const wb = bb || {};
						return (
							(store.getState().favorite_boards.find((b) => b.id === wb.id) && wb.title && wb.title.toLowerCase().includes(search.toLowerCase())) ||
							(store.getState().favorite_dashboards.find((b) => b.id === wb.id) && wb.title && wb.title.toLowerCase().includes(search.toLowerCase()))
						);
					});
				header = (
					<div>
						<MainMenuSectionSearch value={search} onChange={(e) => this.setState({ search: e })} actions={this.props.actions} />
						{favorites && !!favorites.length && (
							<MainSectionFavorites history={this.props.history} location={this.props.location} items={favorites} />
						)}

						{workspaces &&
							workspaces.map((workspace, index) => {
								let match = this.matchSearch(workspace, false);
								if (store.getState().account.id != 15 && workspace.title == "Försäljning") {
									// Henrik 2023-01-27
									match = false;
								}
								if (match) {
									return (
										<WorkspaceNavigation
											t={this.props.t}
											history={this.props.history}
											depth={0}
											id={`${workspace.handle}-${index}`}
											location={this.props.location}
											matchParent={this.matchSearch(workspace, true)}
											search={search}
											key={workspace.handle}
											workspace={workspace}
											draggingType={this.state.draggingType || this.props.draggingType}
											removeWorkspace={this.props.removeWorkspace}
											updateWorkspace={this.props.updateWorkspace}
											uploadToWorkspace={this.props.uploadToWorkspace}
											addWorkspace={this.props.addWorkspace}
											fetchWorkspaces={this.props.fetchWorkspaces}
										/>
									);
								}
								return null;
							})}
					</div>
				);
			}
		} else {
			if (this.props.search && this.matchSearch(this.props.workspace, false)) {
				forceExpand = true;
			}
			const actions = [
				{
					content: this.props.t("workspace.navigation.actions.board.new", "Ny board"),
					icon: GrammarMajor,
					onAction: () => {
						this.setState({ showTemplateModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.navigation.actions.import_board", "Importera till ny board"),
					icon: ImportMinor,
					onAction: () => {
						this.setState({ showImportModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.actions.dashboard.new", "Skapa ny dashboard"),
					icon: AnalyticsMajor,
					onAction: () => {
						this.setState({ showDashboardModal: true, showAddContext: false });
					},
					enabled: [2, 3, 419].includes(store.getState().user.id),
				},
				{
					content: this.props.t("workspace.navigation.actions.files.upload", "Ladda upp filer"),
					icon: UploadMajor,
					onAction: () => {
						this.setState({ showUploadModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.navigation.actions.folder.rename", "Döp om mapp"),
					icon: EditMajor,
					onAction: () => {
						this.setState({ showRenameModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.navigation.actions.members.handle", "Hantera medlemmar"),
					icon: CustomersMajor,
					onAction: () => {
						this.setState({ showMembersModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.navigation.actions.access.change", "Ändra åtkomst"),
					icon: SecureMajor,
					onAction: () => {
						this.setState({ showTypeModal: true, showAddContext: false });
					},
				},
				{
					content: this.props.t("workspace.navigation.actions.move", "Flytta"),
					icon: SortMinor,
					onAction: () => {
						this.setState({ moveModalIsOpen: true, showAddContext: false });
					},
				},

				{
					content: this.props.t("workspace.navigation.actions.remove", "Ta bort"),
					destructive: true,
					icon: CircleCancelMinor,
					onAction: () => {
						this.setState({ showRemoveModal: true, showAddContext: false });
					},
				},
			].filter((item) => item && (!("enabled" in item) || item.enabled));

			if (this.props.depth < 1) {
				actions.unshift({
					content: this.props.t("workspace.navigation.actions.folder.new", "Ny mapp"),
					icon: FolderMajor,
					onAction: () => {
						this.setState({ showWorkspaceModal: true, showAddContext: false });
					},
				});
			} else {
				header = (
					<li className={`Polaris-Navigation__ListItem${actions?.length ? " have_actions" : ""}`} {...this.props.dragHandleProps}>
						<div
							type="button"
							onClick={() => this.setState({ expanded: !this.state.expanded })}
							className={"mainmenu-item__secondary__item" + (this.state.expanded || forceExpand ? " Polaris-Navigation__Item--selected" : "")}
						>
							<div>
								<div className="Polaris-Navigation__Icon">
									<Icon source={this.state.expanded || forceExpand ? FolderMinusMajor : FolderPlusMajor} />
								</div>
								<span className="mainmenu-item__secondary__item__text">
									<LegacyStack>
										<LegacyStack.Item fill>{this.props.workspace.title}</LegacyStack.Item>
									</LegacyStack>
								</span>
							</div>
							<span className="Polaris-Navigation__Action__Wrapper" onClick={(event) => event.stopPropagation()}>
								<Popover
									active={this.state.showAddContext}
									activator={
										<button className="Polaris-Navigation__Action" onClick={() => this.setState({ showAddContext: true })}>
											<Icon source={MobileVerticalDotsMajor} />
										</button>
									}
									onClose={() => this.setState({ showAddContext: false })}
								>
									<ActionList items={actions} />
								</Popover>
							</span>
						</div>
					</li>
				);
			}
			children = this.getChildren(this.props.workspace);
		}

		let childrenEl = null;
		if (children) {
			if (this.props.depth > 0) {
				const draggingType = this.state.draggingType || this.props.draggingType;
				childrenEl = (
					<Droppable droppableId={"workspace_" + this.props.workspace.id} isDropDisabled={!!(draggingType && draggingType !== "board")}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								style={{
									display: this.state.expanded || forceExpand ? "block" : "none",
									paddingLeft: 10,
								}}
							>
								{!snapshot.isDraggingOver && children.length < 1 ? (
									<li className="Polaris-Navigation__ListItem">
										<div className="mainmenu-item__secondary__item">
											<span className="mainmenu-item__secondary__item__text" style={{ paddingLeft: "0.6250rem" }}>
												{this.props.t("workspace.navigation.no_content", "Inget innehåll...")}
											</span>
										</div>
									</li>
								) : null}
								{children.map(this.renderChild.bind(this, 0))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				);
			} else {
				const firstChildren = [];
				const secondaryChildren = [];

				for (let i = 0; i < children.length; i++) {
					if (i < 4) {
						firstChildren.push(children[i]);
					} else {
						secondaryChildren.push(children[i]);
					}
				}
				childrenEl = (
					<Droppable droppableId={"workspace_" + this.props.workspace.id}>
						{(provided, snapshot) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{!snapshot.isDraggingOver && children.length < 1 ? (
									<li className="Polaris-Navigation__ListItem">
										<span className="mainmenu-item__secondary__item__text" style={{ paddingLeft: "0.6250rem" }}>
											{this.props.t("workspace.navigation.no_content", "Inget innehåll...")}
										</span>
									</li>
								) : null}
								{firstChildren.map(this.renderChild.bind(this, 0))}
								{secondaryChildren.length > 0 ? (
									<Collapsible open={this.props.search || this.state.expanded || forceExpand}>
										{secondaryChildren.map(this.renderChild.bind(this, firstChildren.length))}
									</Collapsible>
								) : null}
								{provided.placeholder}
								{secondaryChildren.length > 0 && !(this.props.search || forceExpand) && this.props.depth >= 0 ? (
									<li className="Polaris-Navigation__RollupSection">
										<div className="Polaris-Navigation__ListItem">
											<button
												type="button"
												onClick={() => {
													this.setState({ expanded: !this.state.expanded });
												}}
												className="Polaris-Navigation__Item Polaris-Navigation__RollupToggle"
												aria-label="visa"
											>
												<span className="Polaris-Navigation__Icon">
													<span className="Polaris-Icon">
														<svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
															<path
																d="M6 10a2 2 0 1 1-4.001-.001A2 2 0 0 1 6 10zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 12 10zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 18 10z"
																fillRule="evenodd"
															/>
														</svg>
													</span>
												</span>
											</button>
										</div>
									</li>
								) : null}
							</div>
						)}
					</Droppable>
				);
			}
		}

		const modals = (
			<React.Fragment>
				<WorkspaceNavigationMoveModal
					open={this.state.moveModalIsOpen}
					onClose={() => {
						this.setState({ moveModalIsOpen: false });
					}}
					child={this.props.workspace}
				/>

				{this.props.workspace ? (
					<RenameWorkspaceModal
						open={this.state.showRenameModal}
						workspace={this.props.workspace}
						onComplete={() => {
							this.setState({ showRenameModal: false });
						}}
						onClose={() => {
							this.setState({ showRenameModal: false });
						}}
					/>
				) : null}
				<UploadModal
					open={this.state.showUploadModal}
					onSelect={(uploads) => {
						API.post("/api/workspaces/" + this.props.workspace.id + "/uploads.json", { uploads }, { params: {} })
							.then((result) => {
								if (result.data.error) {
									Toast.error(result.data.error);
									this.setState({ saving: false });
									return;
								}

								this.props.fetchWorkspaces?.();
							})
							.catch((error) => {
								this.setState({ saving: false });
								Toast.error(error);
							});

						this.setState({ showUploadModal: false });
					}}
					onClose={() => {
						this.setState({ showUploadModal: false });
					}}
				/>
				<ImportModal
					open={this.state.showImportModal}
					type="board"
					workspace={this.props.workspace}
					onComplete={() => {
						this.props.fetchWorkspaces?.();
					}}
					onClose={() => {
						this.setState({ showImportModal: false });
					}}
				/>
				{/* <ImportCompanyBoardModal
					history={this.props.history}
					open={this.state.showImportCompanyBoardModal}
					workspace={this.props.workspace}
					onComplete={() => {
						this.setState({ showImportCompanyBoardModal: false });
					}}
					onClose={() => {
						this.setState({ showImportCompanyBoardModal: false });
					}}
				/> */}
				<WorkspaceModal
					open={this.state.showWorkspaceModal}
					parent={this.props.workspace}
					onCreate={(workspace) => {
						if (workspace.type === "shareable") {
							this.setState({
								showMembersModal: true,
								workspaceForBoardMembers: store.getState().workspaces.find((w) => w.id === workspace.id),
							});
						}

						this.setState({ showWorkspaceModal: false });
					}}
					onClose={() => {
						this.setState({ showWorkspaceModal: false });
					}}
				/>
				<BoardTemplateModal
					open={this.state.showTemplateModal}
					workspace={this.props.workspace}
					onCreate={(board, type) => {
						if (board.type === "shareable" || type === "shareable") {
							this.props.history.push("/admin/workspaces/" + board.workspace_id + "/boards/" + board.id, {
								openBoardMembers: true,
							});
						} else {
							this.props.history.push("/admin/workspaces/" + board.workspace_id + "/boards/" + board.id);
						}
						this.props.fetchWorkspaces?.();
						this.setState({ showTemplateModal: false });
					}}
					onClose={() => {
						this.setState({ showTemplateModal: false });
					}}
				/>
				<DashboardModal
					open={this.state.showDashboardModal}
					workspace={this.props.workspace}
					onCreate={
						(/* entity */) => {
							this.props.fetchWorkspaces?.();
							this.setState({ showDashboardModal: false });
						}
					}
					onClose={() => {
						this.setState({ showDashboardModal: false });
					}}
				/>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: null });
					}}
					loading={this.state.removing}
					onConfirm={this.removeWorkspace.bind(this)}
					numItems={1}
					objectName={this.props.workspace ? this.props.workspace.title : ""}
					resourceName={{
						singular: this.props.t("workspace.folders.singular", "mapp"),
						plural: this.props.t("workspace.folders.plural", "mappar"),
					}}
				/>
				{this.props.workspace && this.state.showMembersModal && (
					<BoardMembers
						hideTrigger
						show
						onClose={() =>
							this.setState({
								showMembersModal: false,
								workspaceForBoardMembers: false,
							})
						}
						workspace={this.state.workspaceForBoardMembers || this.props.workspace}
						type="workspace"
						onUpdateWorkspace={this.onUpdateWorkspace.bind(this)}
						hideNewExternal
					/>
				)}
				{this.props.workspace && (
					<Modal
						open={this.state.showTypeModal}
						onClose={() => {
							this.setState({ showTypeModal: false });
						}}
						title={this.props.t("workspace.modals.access_update.title", "Ändra behörighet")}
						primaryAction={{
							content: this.props.t("common.actions.change", "Ändra"),
							loading: this.state.saving,
							disabled: this.state.type == (this.props.workspace && this.props.workspace.type),
							onAction: this.saveType.bind(this),
						}}
						secondaryActions={[
							{
								content: this.props.t("common.actions.close", "Stäng"),
								onAction: () => {
									this.setState({ showTypeModal: false });
								},
							},
						]}
					>
						<Modal.Section>
							<ChoiceList
								choices={[
									{
										label: this.props.t("workspace.board.type.options.public.label", "Publik"),
										value: "public",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("workspace.board.type.options.public.title", "Publik board")}>
													{this.props.t("workspace.board.type.options.public.text", "Alla som har ett konto kommer åt boarden")}
												</Banner>
											) : null,
									},

									{
										label: this.props.t("workspace.board.type.options.public_read.label", "Publik (endast läs)"),
										value: "public_read",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("workspace.board.type.options.public_read.title", "Publik board (endast läs)")}>
													{this.props.t(
														"workspace.board.type.options.public_read.text",
														"Alla kan se boarden, men endast kopplade medlemmar har skrivrättigheter"
													)}
												</Banner>
											) : null,
									},
									{
										label: this.props.t("workspace.board.type.options.shareable.label", "Delad"),
										value: "shareable",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("workspace.board.type.options.shareable.title", "Delad board")}>
													{this.props.t(
														"workspace.board.type.options.shareable.text",
														"Det går att dela boarden med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna"
													)}
												</Banner>
											) : null,
									},
									{
										label: this.props.t("workspace.board.type.options.private.label", "Privat"),
										value: "private",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("workspace.board.type.options.private.title", "Privat board")}>
													{this.props.t("workspace.board.type.options.private.text", "Enbart ägaren (du) kan se boarden")}
												</Banner>
											) : null,
									},
								]}
								selected={[this.state.type]}
								onChange={(v) => {
									this.setState({ type: v[0] });
								}}
							/>
						</Modal.Section>
					</Modal>
				)}
			</React.Fragment>
		);

		if (this.props.depth == 0) {
			// return (
			// 	<MainMenuSection
			// 		id={this.props.id}
			// 		onHover={() => {
			// 			if (this.props.onHover) this.props.onHover(this.props.id);
			// 		}}
			// 		title={this.props.workspace.title}
			// 		history={this.props.history}
			// 		actions={[
			// 			{
			// 				content: "Ny mapp",
			// 				icon: FolderMajor,
			// 				onAction: () => {
			// 					this.setState({ showWorkspaceModal: true, showAddContext: false });
			// 				},
			// 			},
			// 			{
			// 				content: "Ny board",
			// 				icon: GrammarMajor,
			// 				onAction: () => {
			// 					this.setState({ showTemplateModal: true, showAddContext: false });
			// 				},
			// 			},
			// 		]}
			// 		searchComponent={
			// 			<MainMenuSectionSearch
			// 				onChange={(event) => {
			// 					this.setState({ search: event.target.value });
			// 				}}
			// 				value={this.state.search}
			// 			/>
			// 		}
			// 	>
			// 		{childrenEl}
			// 		{modals}
			// 	</MainMenuSection>
			// );
			return (
				<ul className="Polaris-Navigation__Section Polaris-Navigation__Section">
					<div className="workspace-section-header">
						{this.props.workspace && this.props.workspace.title && this.props.workspace.title.length > 20 ? (
							<Tooltip content={this.props.workspace.title}>
								<div
									className="workspace-section-title"
									style={{ cursor: "pointer" }}
									onClick={() => this.setState({ collapsed: !this.state.collapsed })}
								>
									{this.props.workspace.title}
								</div>
							</Tooltip>
						) : (
							<div
								className="workspace-section-title"
								style={{ cursor: "pointer" }}
								onClick={() => this.setState({ collapsed: !this.state.collapsed })}
							>
								{this.props.workspace.title}
							</div>
						)}

						{this.state.collapsed ? (
							<button
								className="Polaris-Navigation__Action"
								onClick={(e) => {
									e.stopPropagation();
									this.setState({ collapsed: !this.state.collapsed });
								}}
							>
								<Icon source={ChevronUpMinor} />
							</button>
						) : null}
						{this.props.workspace.title != "Servicedesk" && !this.state.collapsed ? (
							<Popover
								active={this.state.showAddContext}
								activator={
									<button
										className="Polaris-Navigation__Action"
										onClick={(e) => {
											e.stopPropagation();
											this.setState((c) => ({
												showAddContext: !c.showAddContext,
											}));
										}}
									>
										{this.state.loading ? <Spinner size="small" /> : <Icon source={MobileVerticalDotsMajor} />}
									</button>
								}
								onClose={() => this.setState({ showAddContext: false })}
							>
								<ActionList
									items={[
										{
											content: this.props.t("workspace.navigation.actions.folder.new", "Ny mapp"),
											icon: FolderMajor,
											onAction: () => {
												this.setState({
													showWorkspaceModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.navigation.actions.board.new", "Ny board"),
											icon: GrammarMajor,
											onAction: () => {
												this.setState({
													showTemplateModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.navigation.actions.import_board", "Importera till ny board"),
											icon: ImportMinor,
											onAction: () => {
												this.setState({
													showImportModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.navigation.actions.folder.rename", "Döp om mapp"),
											icon: EditMajor,
											onAction: () => {
												this.setState({
													showRenameModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.navigation.actions.members.handle", "Hantera medlemmar"),
											icon: CustomersMajor,
											onAction: () => {
												this.setState({
													showMembersModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.navigation.actions.access.change", "Ändra åtkomst"),
											icon: SecureMajor,
											onAction: () => {
												this.setState({
													showTypeModal: true,
													showAddContext: false,
												});
											},
										},
										{
											content: this.props.t("workspace.actions.dashboard.new", "Skapa ny dashboard"),
											icon: AnalyticsMajor,
											onAction: () => {
												this.setState({
													showDashboardModal: true,
													showAddContext: false,
												});
											},
											enabled: [2, 3, 419].includes(store.getState().user.id),
										},
										(!children || !children.length) && {
											content: this.props.t("workspace.navigation.actions.remove", "Ta bort"),
											icon: CircleCancelMinor,
											destructive: true,
											onAction: () => {
												this.setState({
													showRemoveModal: true,
													showAddContext: false,
												});
											},
										},
									].filter((item) => item && (!("enabled" in item) || item.enabled))}
								/>
							</Popover>
						) : null}
					</div>

					{this.state.loading && this.props.workspaces.length < 1 ? (
						<li className="Polaris-Navigation__ListItem">
							<div className="Polaris-Navigation__Item">
								<span className="Polaris-Navigation__Text">{this.props.t("workspace.navigation.loading", "Laddar...")}</span>
							</div>
						</li>
					) : this.state.showSearch ? (
						<MainMenuSectionSearch
							value={this.state.search}
							onChange={(v) => this.setState({ search: v })}
							autoFocus
							placeholder={this.props.t("workspace.navigation.search.placeholder", "Sök…")}
							className="Polaris-TopBar-SearchField__Input"
						/>
					) : null}
					<Collapsible open={!this.state.collapsed}>{childrenEl}</Collapsible>
					{modals}
				</ul>
			);
		}

		if ((!this.props.workspace || this.props.depth < 0) && !this.state.loading) {
			return (
				<DragDropContext
					onBeforeCapture={(draggableId, mode) => {}}
					onBeforeDragStart={(e) => {
						this.setState({ draggingType: e.draggableId.split("_")[0] });
					}}
					onDragStart={(e) => {}}
					onDragUpdate={(result, provided) => {}}
					onDragEnd={(result, provided) => {
						if (result.destination) {
							const sourceWorkspaceId = result.source.droppableId.substring(10);
							// let sourceIndex = result.source.index;
							let beforeHandle = null;
							const destinationWorkspaceId = result.destination.droppableId.substring(10);
							const destinationIndex = result.destination.index;
							const workspaces = store.getState().workspaces;
							for (let i = 0; i < workspaces.length; i++) {
								for (let s = 0; s < workspaces[i].children.length; s++) {
									workspaces.push(workspaces[i].children[s]);
								}
							}

							let object = null;
							for (let i = 0; i < workspaces.length; i++) {
								const workspace = workspaces[i];
								if (workspace.id + "" == sourceWorkspaceId) {
									// Remove child from parent
									if (result.draggableId.substring(0, 10) == "workspace_") {
										const newChildren = [];
										for (let s = 0; s < workspaces[i].children.length; s++) {
											if (workspaces[i].children[s].id + "" != result.draggableId.substring(10)) {
												newChildren.push(workspaces[i].children[s]);
											} else {
												object = workspaces[i].children[s];
											}
										}

										if (destinationWorkspaceId != sourceWorkspaceId) {
											workspaces[i].children = newChildren;
										}
									}
									if (result.draggableId.substring(0, 10) == "dashboard_") {
										const newDashboards = [];
										for (let s = 0; s < workspaces[i].dashboards.length; s++) {
											if (workspaces[i].dashboards[s].id + "" != result.draggableId.substring(10)) {
												newDashboards.push(workspaces[i].dashboards[s]);
											} else {
												object = workspaces[i].dashboards[s];
											}
										}

										if (destinationWorkspaceId != sourceWorkspaceId) {
											workspaces[i].dashboards = newDashboards;
										}
									}
									if (result.draggableId.substring(0, 6) == "board_") {
										const newBoards = [];
										for (let s = 0; s < workspaces[i].boards.length; s++) {
											if (workspaces[i].boards[s].id + "" != result.draggableId.substring(6)) {
												newBoards.push(workspaces[i].boards[s]);
											} else {
												object = workspaces[i].boards[s];
											}
										}

										if (destinationWorkspaceId != sourceWorkspaceId) {
											workspaces[i].boards = newBoards;
										}
									}
									if (result.draggableId.substring(0, 7) == "upload_") {
										const newUploads = [];
										for (let s = 0; s < workspaces[i].uploads.length; s++) {
											if (workspaces[i].uploads[s].id + "" != result.draggableId.substring(7)) {
												newUploads.push(workspaces[i].uploads[s]);
											} else {
												object = workspaces[i].uploads[s];
											}
										}

										if (destinationWorkspaceId != sourceWorkspaceId) {
											workspaces[i].uploads = newUploads;
										}
									}
								}
							}

							for (let i = 0; i < workspaces.length; i++) {
								const workspace = workspaces[i];

								if (workspace.id + "" == destinationWorkspaceId) {
									const children = this.getChildren(workspace);
									workspaces[i].boards = [];
									workspaces[i].dashboards = [];
									workspaces[i].children = [];
									workspaces[i].uploads = [];
									let foundIndex = false;
									let pos = -1;
									for (let s = 0; s < children.length; s++) {
										if (s == destinationIndex) {
											beforeHandle = children[s].handle;
											pos++;
											object.position = pos;
											if (object.handle.substring(0, 10) == "workspace_") {
												workspaces[i].children.push(object);
											}
											if (object.handle.substring(0, 10) == "dashboard_") {
												workspaces[i].dashboards.push(object);
											}
											if (object.handle.substring(0, 6) == "board_") {
												workspaces[i].boards.push(object);
											}
											if (object.handle.substring(0, 7) == "upload_") {
												workspaces[i].uploads.push(object);
											}
											foundIndex = true;
										}
										if (children[s].handle != result.draggableId) {
											pos++;
											children[s].position = pos;
											if (children[s].handle.substring(0, 10) == "workspace_") {
												workspaces[i].children.push(children[s]);
											}
											if (children[s].handle.substring(0, 10) == "dashboard_") {
												workspaces[i].dashboards.push(children[s]);
											}
											if (children[s].handle.substring(0, 6) == "board_") {
												workspaces[i].boards.push(children[s]);
											}
											if (children[s].handle.substring(0, 7) == "upload_") {
												workspaces[i].uploads.push(children[s]);
											}
										}
									}
									if (!foundIndex) {
										pos++;
										object.position = pos;
										if (object.handle.substring(0, 10) == "workspace_") {
											workspaces[i].children.push(object);
										}
										if (object.handle.substring(0, 10) == "dashboard_") {
											workspaces[i].dashboards.push(object);
										}
										if (object.handle.substring(0, 6) == "board_") {
											workspaces[i].boards.push(object);
										}
										if (object.handle.substring(0, 7) == "upload_") {
											workspaces[i].uploads.push(object);
										}
									}
								}
							}
							store.dispatch({
								type: "SET_WORKSPACES",
								workspaces: [...workspaces],
							});
							this.setState({ saving: true });
							API.put(
								"/api/workspaces/move.json",
								{
									draggableId: result.draggableId,
									workspace_id: destinationWorkspaceId,
									before_handle: beforeHandle != result.draggableId ? beforeHandle : null,
								},
								{ params: {} }
							)
								.then((result) => {
									if (result.data.error) {
										Toast.error(result.data.error);
										this.setState({ saving: false });
									}
								})
								.catch((error) => {
									this.setState({ saving: false });
									Toast.error(error);
								});
						}
					}}
				>
					{header}
					{childrenEl !== null ? childrenEl : null}
					{modals}
				</DragDropContext>
			);
		}
		return (
			<React.Fragment>
				{header}
				{childrenEl !== null ? childrenEl : null}
				{modals}
			</React.Fragment>
		);
	}
}

const WorkspaceNavigationWrapper = (props) => {
	const { workspaces, removeWorkspace, updateWorkspace, uploadToWorkspace, addWorkspace, fetchWorkspaces } = useWorkspaces();
	const hiddenWorkspaceIds = [store.getState().account.sales_workspace_id, store.getState().account.support_workspace_id].filter(Boolean);

	return (
		<WorkspaceNavigation
			{...props}
			workspaces={workspaces.filter((workspace) => !hiddenWorkspaceIds.includes(workspace.id))}
			removeWorkspace={removeWorkspace}
			updateWorkspace={updateWorkspace}
			uploadToWorkspace={uploadToWorkspace}
			addWorkspace={addWorkspace}
			fetchWorkspaces={fetchWorkspaces}
		/>
	);
};

export default connect(
	(state) => ({
		workspaces: state.workspaces,
		favorite_boards: state.favorite_boards,
	}),
	{}
)(withTranslation(["workspace", "common"], { withRef: true })(WorkspaceNavigationWrapper));
