/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import { Icon, TextField as PolarisTextField, useEventListener } from "@shopify/polaris";
import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { CalendarIcon } from "src/js/icons";
import moment from "moment";
import Popover from "../Popover";
import { TextFieldProps } from "src/js/types/TextFieldPropsType";

const TextField = ({
	autoComplete = "",
	label = "",
	placeholder = `${label}..` as any,
	suffix,
	value,
	autoFocus,
	selected,
	required,
	requiredIndicator = required,
	style,
	error,
	...props
}: TextFieldProps) => {
	const ref = useRef<HTMLInputElement>(null);
	useEventListener("keydown", (e) => {
		const textField = ref.current?.querySelector("input") || ref.current?.querySelector("textarea");
		if (e.key === "Enter" && textField === document.activeElement && props.onSubmit && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();

			props.onSubmit?.(value);
		}
	});

	useEffect(() => {
		setTimeout(() => {
			if (autoFocus && ref.current) {
				const input = ref.current?.querySelector("input") || ref.current?.querySelector("textarea");
				input?.focus();
			}
		}, 0);
	}, [autoFocus]);

	const handleFocus = () => {
		const input = ref.current?.querySelector("input") || ref.current?.querySelector("textarea");
		if (input && selected) input?.select();

		props.onFocus?.();
	};

	return (
		<Wrapper type={props.type} ref={ref} required={required} haveValue={!!value} style={style}>
			<PolarisTextField
				autoComplete={autoComplete || ""}
				placeholder={!props?.disabled ? placeholder : ""}
				label={(label || "") as string}
				value={(value ? String(value) : value) as any}
				suffix={
					props.type && props.onChange && ["date", "datetime-local"].includes(props.type) ? (
						<>
							{suffix}
							<CalendarPopup value={value} onChange={props.onChange} />
						</>
					) : (
						suffix
					)
				}
				requiredIndicator={requiredIndicator}
				error={error || ""}
				{...props}
				onFocus={handleFocus}
			/>
		</Wrapper>
	);
};
export default TextField;

const Wrapper = styled.div<{ type?: string; required?: boolean; haveValue?: boolean }>`
	${({ type }) =>
		type === "number" &&
		css`
			.Polaris-TextField__Spinner {
				display: none;
			}
		`}

	${({ type }) =>
		(type === "date" || type === "datetime-local") &&
		css`
			input::-webkit-inner-spin-button,
			input::-webkit-calendar-picker-indicator {
				display: none;
				-webkit-appearance: none;
				visibility: hidden;
			}

			.Polaris-Icon {
				height: 16px;
				width: 16px;
				svg {
					height: 16px;
					width: 16px;
				}
			}
		`}

	${(props) => {
		if (props.required && !props.haveValue) {
			return css`
				.Polaris-TextField__Backdrop {
					border: 1px solid red !important;
				}
			`;
		}
	}}
`;

const CalendarActivator = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
`;

const CalendarPopup = ({ onChange, value }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const renderDayContents = (day, date) => {
		return (
			<div className="day-inner">
				<span>{day}</span>
			</div>
		);
	};

	return (
		<Popover
			active={open}
			activator={
				<CalendarActivator onClick={handleOpen}>
					<Icon source={CalendarIcon} />
				</CalendarActivator>
			}
			onClose={handleClose}
		>
			<CalendarWrapper className="new_datepicker__calendar">
				<DatePicker
					inline
					showMonthDropdown
					calendarStartDay={1}
					renderDayContents={renderDayContents}
					locale="sv"
					dayClassName={() => "calendar-day"}
					dateFormat="yyyy-MM-DD"
					showWeekNumbers={false}
					// showMonthDropdown: true,
					// calendarStartDay: 1,
					// // highlightDates={highlightedEvents}
					// // onMonthChange={this.handleDateChange.bind(this)}
					// renderDayContents: this.renderDayContents.bind(this),
					// locale: "sv",
					// // filterDate={(date) => moment(moment(date).format("YYYY-MM-DD")).isSame(moment(moment(this.state.selectedDate).format("YYYY-MM-DD")), "month")}
					// dayClassName: () => "calendar-day",
					// dateFormat: "yyyy-MM-DD",
					// showWeekNumbers: false,
					// disabled: this.props.disabled,
					// disabledKeyboardNavigation: true,
					// showTimeSelect: this.props.showTimeSelect,
					// showTimeSelectOnly: this.props.showTimeSelectOnly,
					// timeIntervals: this.props.timeIntervals,
					// timeCaption: null,
					// injectTimes: this.props.showTimeSelectOnly && isValid(new Date(this.props.start)) ? [new Date(this.props.start)] : [],

					// minDate={new Date(this.props.minDate)}
					// minTime={new Date(this.props.minTime)}
					// maxTime={this.props.minTime ? new Date(this.props.maxTime) : new Date().setUTCHours(23, 59, 59, 999)}
					selected={value && isValid(new Date(value)) ? new Date(value) : null}
					// onSelect={this.onSelect.bind(this, "start")}
					onChange={(value, event: any) => {
						onChange(moment(value).format("YYYY-MM-DD"), event?.target?.id);
						handleClose();
					}}
				/>
			</CalendarWrapper>
		</Popover>
	);
};

const CalendarWrapper = styled.div`
	.react-datepicker__day.calendar-day,
	.react-datepicker__day-name {
		width: 50px;
	}
`;
