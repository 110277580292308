import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import Toast from "src/js/components/Toast";

type SetFormFunction = (form: Partial<PricelistType> | null | ((prevForm: Partial<PricelistType>) => Partial<PricelistType>)) => void;

type PricelistContextValue = {
	form: Partial<PricelistType> | null;
	pricelist: PricelistType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	handleSave: (pricelist: PricelistType) => any;
} & WithTranslation;

const PricelistContext = React.createContext({} as PricelistContextValue);

export const PricelistContextProvider = withTranslation(["pricelist", "common"])(({ id: propsId, match, history, children, t, defaultData = {} }) => {
	const [form, setForm] = useState<Partial<PricelistType> | null>(defaultData);
	const id = propsId || match.params.id;
	const [isSaving, setIsSaving] = useState(false);

	const queryClient = useQueryClient();

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/pricelists/${id}.json`);

			return res.data.pricelist;
		} catch (error) {
			Toast.error(error);
			return defaultData || null;
		}
	}, [id, defaultData]);

	const queryKey = [id && `pricelist-${id}`];
	const { data: pricelist = null, isFetching }: UseQueryResult<PricelistType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || defaultData || null,
		enabled: !!id,
	});

	useEffect(() => {
		if (pricelist) setForm(pricelist);
	}, [pricelist]);

	const saveFunction = useCallback(
		async (pricelist: PricelistType) => {
			const endpoint = !id ? `/api/pricelists.json` : `/api/pricelists/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...pricelist }) : API.post(endpoint, { ...pricelist }));

			const successMessage = id
				? t("pricelists.responses.pricelist_saved", "Prislista uppdaterad")
				: t("pricelists.responses.pricelist_created", "Prislista skapad");

			Toast.success(successMessage);

			history.replace(`/admin/pricelists/${res.data.pricelist.id}`, {
				data: res.data.pricelist,
			});

			return res.data.pricelist;
		},
		[id, t, history]
	);

	const update = async ({ pricelist }) => {
		const response = await saveFunction(pricelist);
		return response?.data;
	};

	const mutation = useMutation(update, {
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});

	const handleSave = useCallback(
		async (pricelist: PricelistType) => {
			try {
				if (!pricelist) {
					throw new Error("No pricelist");
				}

				setIsSaving(true);
				return await mutation.mutateAsync({ pricelist });
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			} finally {
				setIsSaving(false);
			}
		},
		[mutation]
	);

	const value: any = useMemo(
		() => ({
			form,
			setForm,
			isFetching,
			t,
			id,
			handleSave,
			pricelist,
			isSaving,
		}),
		[isFetching, t, id, form, handleSave, setForm, pricelist, isSaving]
	);

	return useMemo(() => <PricelistContext value={value}>{children}</PricelistContext>, [value, children]);
});
export default PricelistContext;
